import { Action, createReducer, on } from '@ngrx/store';
import {
  init,
  patchInfosPdf,
  updateShowPdf,
  updateTitle,
  updateDocumentContent,
  updateXmlData
} from './infos-pdf.actions';
import { initialState, InfosPdfState } from './infos-pdf.state';

const operationReducer = createReducer(
  initialState,
  on(patchInfosPdf, (state, action: ReturnType<typeof patchInfosPdf>) => ({
    ...state,
    ...action.payload
  })),
  on(updateShowPdf, (state, { showPdf }: ReturnType<typeof updateShowPdf>) => ({
    ...state,
    showPdf
  })),
  on(updateTitle, (state, { title }: ReturnType<typeof updateTitle>) => ({
    ...state,
    title
  })),
  on(updateXmlData, (state, { xmlData }: ReturnType<typeof updateXmlData>) => ({
    ...state,
    xmlData
  })),
  on(
    updateDocumentContent,
    (state, { documentContent }: ReturnType<typeof updateDocumentContent>) => ({
      ...state,
      documentContent
    })
  ),
  on(init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: InfosPdfState | undefined, action: Action) {
  return operationReducer(state, action);
}
