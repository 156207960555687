import { Action, createReducer, on } from '@ngrx/store';
import {
  init,
  updateError,
  updateHeader,
  updatePaiement,
  updateShortcut,
  updateTitle,
  updateWebFunctions,
  updateIsExpandedMenu,
  updateIndicateurPaiementSur
} from './header.actions';
import { initialState, HeaderState } from './header.state';

const headerReducer = createReducer(
  initialState,
  on(updateHeader, (state, action: ReturnType<typeof updateHeader>) => ({
    ...state,
    ...action.payload
  })),
  on(updateTitle, (state, { title }: ReturnType<typeof updateTitle>) => ({
    ...state,
    title
  })),
  on(updateShortcut, (state, action: ReturnType<typeof updateShortcut>) => ({
    ...state,
    shortcut: { ...state.shortcut, ...action.payload }
  })),
  on(updateWebFunctions, (state, action: ReturnType<typeof updateWebFunctions>) => ({
    ...state,
    webFunctions: [...action.webFunctions]
  })),
  on(updateError, (state, { errorSource }: ReturnType<typeof updateError>) => ({
    ...state,
    errorSource
  })),
  on(updatePaiement, (state, { activePaiement }: ReturnType<typeof updatePaiement>) => ({
    ...state,
    activePaiement
  })),
  on(updateIndicateurPaiementSur, (state, { updatedIndicateurPaiementSur }) => ({
    ...state,
    shortcut: state.shortcut
      ? { ...state.shortcut, IndicateurPaiementSur: updatedIndicateurPaiementSur }
      : null
  })),
  on(
    updateIsExpandedMenu,
    (state, { isExpandedMenu }: ReturnType<typeof updateIsExpandedMenu>) => ({
      ...state,
      isExpandedMenu
    })
  ),
  on(init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: HeaderState | undefined, action: Action) {
  return headerReducer(state, action);
}
