import { Action, createReducer, on } from '@ngrx/store';
import { init, updateFactureAutre } from './creation-autre-facture-manuelle.actions';
import { initialState, FactureAutreState } from './creation-autre-facture-manuelle.state';

const autreFactureReducer = createReducer(
  initialState,
  on(updateFactureAutre, (state, action: ReturnType<typeof updateFactureAutre>) => ({
    ...state,
    ...action.payload
  })),
  on(init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: FactureAutreState | undefined, action: Action) {
  return autreFactureReducer(state, action);
}
