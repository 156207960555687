import { createAction, props } from '@ngrx/store';
import { InfosFinancieres } from 'src/app/core/models/infos-financieres.model';

export const patchInfosFinancieres = createAction(
  '[infos-financieres] Patch infos-financieres info',
  props<{ payload: InfosFinancieres }>()
);

export const updateNombreNonValeur = createAction(
  '[infos-financieres] Update nombreNonValeur',
  props<{ nombreNonValeur: number }>()
);
export const updateNombreFacturesManuelles = createAction(
  '[infos-financieres] Update nombreFacturesManuelles',
  props<{ nombreFacturesManuelles: number }>()
);

export const init = createAction('[Init]');
