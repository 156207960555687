import { Etat } from "../../models/etat.model";

export type EtatState = Etat;

export const initialState: EtatState = {
  isMiseEnService: false,
  isResiliable: false,
  isEchangeable: false,
  isAnnulable: false
};
