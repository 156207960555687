import { Ressource } from '../../class/Ressource';

export class RessourceLightDto {
  private Matricule: string;
  private Nom: string;
  private Unite: string;
  private Profil: string;
  private Procedure: string;
  private FonctionWeb: string;
  private AdresseMail: string;
  private IndicateurMobiliteReleve: boolean;
  private IndicateurMobiliteIntervention: boolean;

  /**
   * Constructeur d'instance du DTO RessourceLight
   * @param ressource Permet de convertir l'entité Ressource
   */
  constructor(ressource?: Ressource) {
    this.Matricule = ressource?.getMatricule() ?? '';
    this.Nom = ressource?.getNom() ?? '';
    this.Unite = ressource?.getUnite() ? ressource?.getUnite()?.getNumero() ?? '' : '';
    this.Profil =
      ressource?.getProfils() && ressource?.getProfils().length > 0
        ? ressource?.getProfils()[0].getCode() ?? ''
        : '';
    this.Procedure = '';
    this.FonctionWeb =
      ressource
        ?.getFonctionsWeb()
        ?.map(webFunction => webFunction.Libelle)
        .join(', ') ?? '';
    this.AdresseMail = ressource?.getAdresseMailPassWord() ?? '';
    this.IndicateurMobiliteReleve = ressource?.getIndicateurMobiliteReleve() ?? false;
    this.IndicateurMobiliteIntervention = ressource?.getIndicateurMobiliteIntervention() ?? false;
  }

  //#region Accesseurs
  public getMatricule(): string {
    return this.Matricule;
  }

  public setMatricule(Matricule: string): void {
    this.Matricule = Matricule;
  }

  public getNom(): string {
    return this.Nom;
  }

  public setNom(Nom: string): void {
    this.Nom = Nom;
  }

  public getUnite(): string {
    return this.Unite;
  }

  public setUnite(Unite: string): void {
    this.Unite = Unite;
  }

  public getProfil(): string {
    return this.Profil;
  }

  public setProfil(Profil: string): void {
    this.Profil = Profil;
  }

  public getProcedure(): string {
    return this.Procedure;
  }

  public setProcedure(Procedure: string): void {
    this.Procedure = Procedure;
  }

  public getFonctionWeb(): string {
    return this.FonctionWeb;
  }

  public setFonctionWeb(FonctionWeb: string): void {
    this.FonctionWeb = FonctionWeb;
  }

  public getAdresseMail(): string {
    return this.AdresseMail;
  }

  public setAdresseMail(AdresseMail: string): void {
    this.AdresseMail = AdresseMail;
  }

  public isIndicateurMobiliteReleve(): boolean {
    return this.IndicateurMobiliteReleve;
  }

  public setIndicateurMobiliteReleve(IndicateurMobiliteReleve: boolean): void {
    this.IndicateurMobiliteReleve = IndicateurMobiliteReleve;
  }

  public isIndicateurMobiliteIntervention(): boolean {
    return this.IndicateurMobiliteIntervention;
  }

  public setIndicateurMobiliteIntervention(IndicateurMobiliteIntervention: boolean): void {
    this.IndicateurMobiliteIntervention = IndicateurMobiliteIntervention;
  }
  //#endregion
}
