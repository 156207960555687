import { Action, createReducer, on } from '@ngrx/store';
import {
  init,
  patchAbonnement,
  updateListInterventions,
  updateListCourriers,
  updateNumeroAppareil,
  updateEtat
} from './abonnement.actions';
import { initialState, AbonnementState } from './abonnement.state';

const abonnementReducer = createReducer(
  initialState,
  on(patchAbonnement, (state, action: ReturnType<typeof patchAbonnement>) => ({
    ...state,
    ...action.payload
  })),
  on(init, state => ({
    ...state,
    ...initialState
  })),
  on(
    updateNumeroAppareil,
    (state, { numeroAppareil }: ReturnType<typeof updateNumeroAppareil>) => ({
      ...state,
      numeroAppareil
    })
  ),
  on(updateEtat, (state, { etatAbonnement }: ReturnType<typeof updateEtat>) => ({
    ...state,
    etatAbonnement
  })),
  on(
    updateListInterventions,
    (state, { listInterventionsUpdate }: ReturnType<typeof updateListInterventions>) => ({
      ...state,
      listInterventionsUpdate
    })
  ),
  on(
    updateListCourriers,
    (state, { listCourriersUpdate }: ReturnType<typeof updateListCourriers>) => ({
      ...state,
      listCourriersUpdate
    })
  )
);

export function reducer(state: AbonnementState | undefined, action: Action) {
  return abonnementReducer(state, action);
}
