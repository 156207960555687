import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AbonnementParTypeServiceService } from 'src/app/api/services/abonnementParTypeService.service';
import { TypesServicesService } from 'src/app/api/services/types-services.service';

@Injectable({
  providedIn: 'root'
})
export class GetServicesResolver implements Resolve<boolean> {
  constructor(
    private subscribedServices: AbonnementParTypeServiceService,
    private availableServices: TypesServicesService
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.subscribedServices.getAllByAbonnement(route.params['numAbonnement']).subscribe();
    this.availableServices.getAvailableServices(route.params['numAbonnement']).subscribe();
    return of(true);
  }
}

