<div class="flex">
  <app-frame [title]="resource.demandeBranchement.titre" class="flex-1 w-full mx-5 space-x-5 my-4"
    ><div class="content-frame">
      <app-wizard
        [wizardSteps]="wizardSteps"
        [codeINSEE]="codeINSEE"
        [idTiersAbo]="idTiersAbo"
        [idTiersDest]="idTiersDest"
        [tiers]="tiersAbo"
        (submit)="submitEvent($event)"></app-wizard>
    </div>
  </app-frame>
</div>
