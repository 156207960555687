import { Action, createReducer, on } from '@ngrx/store';
import {
  init,
  patchEtat,
  updateIsMiseEnService,
  updateIsResiliable,
  updateIsEchangeable,
  updateIsAnnulable
} from './etat.actions';
import { initialState, EtatState } from './etat.state';

const etatReducer = createReducer(
  initialState,
  on(patchEtat, (state, action: ReturnType<typeof patchEtat>) => ({
    ...state,
    ...action.payload
  })),
  on(init, state => ({
    ...state,
    ...initialState
  })),
  on(
    updateIsMiseEnService,
    (state, { isMiseEnService }: ReturnType<typeof updateIsMiseEnService>) => ({
      ...state,
      isMiseEnService
    })
  ),
  on(
    updateIsResiliable,
    (state, { isResiliable }: ReturnType<typeof updateIsResiliable>) => ({
      ...state,
      isResiliable
    })
  ),
  on(
    updateIsEchangeable,
    (state, { isEchangeable }: ReturnType<typeof updateIsEchangeable>) => ({
      ...state,
      isEchangeable
    })
  ),
  on(
    updateIsAnnulable,
    (state, { isAnnulable }: ReturnType<typeof updateIsAnnulable>) => ({
      ...state,
      isAnnulable
    })
  )
);

export function reducer(state: EtatState | undefined, action: Action) {
  return etatReducer(state, action);
}
