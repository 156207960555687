import { Header } from 'src/app/core/models/header.model';

export type HeaderState = Header;

export const initialState: HeaderState = {
  title: null,
  shortcut: null,
  activePaiement: false,
  errorSource: null,
  webFunctions: [],
  isExpandedMenu: false
};
