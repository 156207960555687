import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContextState } from './context.state';

export const selectContext = createFeatureSelector<ContextState>('context');

export const selectContextAuthToken = createSelector(
  selectContext,
  (state: ContextState) => state.authToken
);

export const selectContextUrlRedirect = createSelector(
  selectContext,
  (state: ContextState) => state.urlRedirect
);

export const selectContextRessource = createSelector(
  selectContext,
  (state: ContextState) => state.ressource
);
