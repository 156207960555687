import { createAction, props } from '@ngrx/store';
import { Header, shortcut } from '../../models/header.model';
import { WebFonctions } from 'src/app/api/models/interface/WebFunction';

export const updateHeader = createAction(
  '[Header] Update Header info',
  props<{ payload: Header }>()
);

export const updateTitle = createAction('[Header] Update Title', props<{ title: string }>());

export const updateShortcut = createAction(
  '[Header] Update Shortcut',
  props<{ payload: shortcut }>()
);
export const updatePaiement = createAction(
  '[Header] Update Paiement',
  props<{ activePaiement: boolean }>()
);

export const updateIsExpandedMenu = createAction(
  '[Header] Update isExpandedMenu',
  props<{ isExpandedMenu: boolean }>()
);

export const updateIndicateurPaiementSur = createAction(
  '[Header] Update IndicateurPaiementSur',
  props<{ updatedIndicateurPaiementSur: boolean }>()
);

export const updateWebFunctions = createAction(
  '[Header] Update Web Functions',
  props<{ webFunctions: WebFonctions }>()
);

export const init = createAction('[Init]');

export const updateError = createAction('[Header] Update error', props<{ errorSource: string }>());
