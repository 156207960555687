import { createAction, props } from '@ngrx/store';
import { InfosAvoir } from 'src/app/api/models/interface/dossierCompte';
import { Operation } from 'src/app/core/models/operation.model';

export const patchOperation = createAction(
  '[Operation] Patch Operation info',
  props<{ payload: Operation }>()
);

export const updateAvoirPartiel = createAction(
  '[Operation] Update Avoir Partiel',
  props<{ showAvoirPartiel: boolean }>()
);

export const updateAvoirTotal = createAction(
  '[Operation] Update Avoir Total',
  props<{ showAvoirTotal: boolean }>()
);

export const updateNumeroFacture = createAction(
  '[Operation] Update Numero Facture',
  props<{ numeroFacture: number }>()
);

export const updateInfosAvoir = createAction(
  '[Operation] Update Infos Avoir',
  props<{ infosAvoir: InfosAvoir }>()
);

export const init = createAction('[Init]');
