import { MessageService } from 'primeng/api';
import { AppResource } from 'src/app/app.resource';
import { Resource } from 'src/app/resources/resource';

export class MessageServiceUtils {
  private ressource: Resource;
  private appRessources: AppResource;
  constructor(private messageService: MessageService, private ressources: AppResource) {
    this.appRessources = ressources;
    this.ressource = ressources['resource'];
  }

  resolveReturnCode(code: any): string {
    return this.appRessources.resolveReturnCode(code);
  }

  error(message?: string) {
    if (message == this.ressource.Constants.ReturnCode.AUTH_TokenNotValid.Libelle) {
      message = this.ressource.toast.errorSeReconnecter;
    }

    this.messageService.add({
      severity: 'error',
      summary: this.ressource.toast.errorTitle,
      detail: message ?? this.ressource.toast.errorAPIDescription
    });
  }

  warning(message: string) {
    let severity = 'warn';

    if (message == this.ressource.Constants.ReturnCode.AUTH_TokenNotValid.Libelle) {
      message = this.ressource.toast.errorSeReconnecter;
    }

    if (
      message == this.ressource.toast.errorDescription ||
      message == this.ressource.Constants.ReturnCode.GENERIC_CriticalError.Libelle
    ) {
      severity = 'error';
    }

    this.messageService.add({
      severity: severity,
      summary:
        severity == 'warn' ? this.ressource.toast.warningTitle : this.ressource.toast.errorTitle,
      detail: message ?? this.ressource.toast.errorDescription
    });
  }

  warningDetails(title?: string, message?: string) {
    this.messageService.add({
      severity: message ? 'warn' : 'error',
      summary: title ?? this.ressource.toast.errorTitle,
      detail: message ?? this.ressource.toast.errorDescription
    });
  }

  success(message?: string) {
    this.messageService.add({
      severity: 'success',
      summary: this.ressource.toast.successTitle,
      detail: message ?? this.ressource.toast.successDescription
    });
  }

  info(message?: string) {
    this.messageService.add({
      severity: 'info',
      summary: this.ressource.toast.infoTitle,
      detail: message ?? ''
    });
  }
}
