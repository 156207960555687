//#region Import
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'primeng/tooltip';
import { FrameComponent } from '../frame/frame.component';
//#endregion

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  faCamera,
  faDownload,
  faExternalLink,
  faPrint,
  faRotate
} from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { InterventionService } from 'src/app/api/services/intervention.service';
import { AppResource } from 'src/app/app.resource';
import { State } from 'src/app/core/state/core.state';
import { bytesToBase64 } from '../../utils/pdfUtils';
import { dataToImageBlob } from '../../utils/photoUtils';
import { BaseComponent } from '../base/base.component';

export interface Photo {
  id: string;
  data: string;
}
@Component({
  selector: 'app-photos-viewer',
  templateUrl: './photos-viewer.component.html',
  styleUrls: ['./photos-viewer.component.scss'],
  imports: [CommonModule, FrameComponent, FontAwesomeModule, TooltipModule],
  standalone: true
})
export class PhotosViewerComponent extends BaseComponent implements OnInit {
  @Input() isVisible!: boolean;
  // tableau des id photo
  @Input() photosId: Array<string> = [];
  @Output() modifyVisiblity = new EventEmitter<boolean>();
  nbrClick: number = 0;
  currentUrl!: string;
  tabPhotos: Photo[] = [];
  // Icones
  faCamera = faCamera;
  faPrint = faPrint;
  faRotate = faRotate;
  faDownload = faDownload;
  faExternalLink = faExternalLink;
  currentPhoto!: Photo;
  
  constructor(
    store: Store<State>,
    resources: AppResource,
    messageService: MessageService,
    private domSanitizer: DomSanitizer,
    private interventionService: InterventionService
  ) {
    super(store, resources, messageService);
  }

  override async ngOnInit() {
    await this.createTabPhotos();
  }

  async createTabPhotos() {
    const tab: Photo[] = [];
    this.photosId.forEach(async (id: string) => {
      const photo = await lastValueFrom(this.interventionService.getPhoto(id));
      if (photo.Code === 100) {
        const ph = {
          id: id,
          data: bytesToBase64(photo.Result)
        };
        // Sélectionner 1ère photo
        if (!this.currentPhoto) {
          this.currentPhoto = ph;
        }
        tab.push(ph);
      } else {
        this.messageServiceUtils.warning(
          photo.Description ? photo.Description : this.resource.toast.errorDescription
        );
      }
    });
    this.tabPhotos = tab;
  }
  rotatePhoto() {
    if (this.nbrClick === 4) {
      this.nbrClick = 0;
    }
    this.nbrClick += 1;
  }
  getClassRotation() {
    switch (this.nbrClick) {
      case 1:
        return 'rotate90';
      case 2:
        return 'rotate180';
      case 3:
        return 'rotate270';
      case 4:
        return 'rotate360';
      default:
        return null;
    }
  }
  getCurrentPhoto() {
    if (this.currentPhoto) {
      const binary = this.currentPhoto.data;
      this.currentUrl = dataToImageBlob(binary);
      return this.domSanitizer.bypassSecurityTrustResourceUrl(this.currentUrl);
    } else {
      return null;
    }
  }
  getUrlPhoto(data: string) {
    const urlPhoto = dataToImageBlob(data);
    return this.domSanitizer.bypassSecurityTrustResourceUrl(urlPhoto);
  }
  setCurrentPhoto(photo: Photo) {
    this.currentPhoto = photo;
  }
  printPhoto() {
    window.open(this.currentUrl)?.print();
  }
  openNewTab() {
    window.open(this.currentUrl, '_blank');
  }
  downloadPhoto() {
    const name = 'photo.jpg';
    // Balise lien temporaire
    let anchor = document.createElement('a');
    document.body.appendChild(anchor);
    anchor.setAttribute('href', this.currentUrl);
    anchor.setAttribute('download', name);
    anchor.style.display = 'none';
    anchor.click();
    document.body.removeChild(anchor);
    // SetTimeout afin que le revoke ne soit pas porté sur le même tour de boucle du moteur javacript
    const currentUrl = this.currentUrl;
    setTimeout(function () {
      window.URL.revokeObjectURL(currentUrl);
    }, 1000);
  }
  closeDiv() {
    this.modifyVisiblity.emit(false);
  }
}
