<div class="w-full p-5 pb-0">
  <div class="bg-white border border-neutral-200 flex justify-between py-2 pr-2">
    <div
      class="flex w-1/2"
      *ngVar="resource.GlobalTexts.Abonnement.Service.SubsribedList as subscribedTranslations">
      <div class="flex justify-center items-center py-2 px-4">
        {{ subscribedTranslations.name }} :
      </div>
      <div class="flex flex-row items-center justify-center" *ngIf="subscribedServices.isLoading">
        <img [src]="resource.various.loadingGif" alt="loading.gif" [width]="30" />
      </div>
      <div
        *ngIf="!subscribedServices.isLoading"
        class="grow flex gap-2"
        [ngClass]="{
          'bg-primary-500 border-primary-900 opacity-50 rounded-md': servicesService.draggedService
        }"
        pDroppable
        (onDrop)="
          servicesService.draggedService
            ? servicesService.activate(servicesService.draggedService)
            : null
        ">
        <ng-container *ngFor="let service of subscribedServices.subscribedServices">
          <waterp-service
            class="w-[90px]"
            [subscribed]="service"
            [disabled]="readonly"></waterp-service>
        </ng-container>
      </div>
    </div>
    <p-divider styleClass="h-[40px]" layout="vertical"></p-divider>
    <div
      class="flex w-1/2"
      *ngVar="resource.GlobalTexts.Abonnement.Service.AvailableList as availableTranslations">
      <div class="flex justify-center items-center py-2 pr-4">
        {{ availableTranslations.name }} :
      </div>
      <div class="flex flex-row items-center justify-center" *ngIf="availableServices.isLoading">
        <img [src]="resource.various.loadingGif" alt="loading.gif" [width]="30" />
      </div>
      <div *ngIf="!availableServices.isLoading" class="grow flex gap-2">
        <ng-container *ngFor="let service of availableServices.availableServices">
          <waterp-service
            class="w-[90px]"
            [ngClass]="{
              'cursor-grabbing': !readonly && servicesService.draggedService,
              'cursor-grab': !readonly && !servicesService.draggedService
            }"
            pDraggable
            [pDraggableDisabled]="readonly"
            (onDragStart)="servicesService.draggedService = service"
            (onDragEnd)="servicesService.draggedService = undefined"
            [available]="service"
            [disabled]="readonly"></waterp-service>
        </ng-container>
      </div>
    </div>
  </div>
</div>
