import { createAction, props } from '@ngrx/store';
import { DossierCompte } from 'src/app/core/models/dossier-compte.model';

export const patchDossierCompte = createAction(
  '[Dossier compte] Patch Dossier compte info',
  props<{ payload: DossierCompte }>()
);

export const updateIsMensualisable = createAction(
  '[Dossier compte] Update isMensualisable',
  props<{ isMensualisable: boolean }>()
);

export const updateIsFPA = createAction(
  '[Dossier compte] Update isFPA',
  props<{ isFPA: boolean }>()
);

export const updateIsRemboursable = createAction(
  '[Dossier compte] Update isRemboursable',
  props<{ isRemboursable: boolean }>()
);

export const updateIsFactureManuelle = createAction(
  '[Dossier compte] Update isFactureManuelle',
  props<{ isFactureManuelle: boolean }>()
);

export const updateIsAutreFactureManuelle = createAction(
  '[Dossier compte] Update isAutreFactureManuelle',
  props<{ isAutreFactureManuelle: boolean }>()
);

export const updateHasEcheancierFPA = createAction(
  '[Dossier compte] Update hasEcheancierFPA',
  props<{ hasEcheancierFPA: boolean }>()
);

export const updateHasEcheancierFPACB = createAction(
  '[Dossier compte] Update hasEcheancierFPACB',
  props<{ hasEcheancierFPACB: boolean }>()
);

export const init = createAction('[Init]');
