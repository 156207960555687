export class Profil {
  private CodeProfil: string;
  private LibelleProfil: string;

  constructor(distantProfil?: Profil) {
    this.CodeProfil = distantProfil?.CodeProfil ?? '';
    this.LibelleProfil = distantProfil?.LibelleProfil ?? '';
  }

  public getCode(): string {
    return this.CodeProfil;
  }

  public setCode(CodeProfil: string): void {
    this.CodeProfil = CodeProfil;
  }

  public getLibelle(): string {
    return this.LibelleProfil;
  }

  public setLibelle(LibelleProfil: string): void {
    this.LibelleProfil = LibelleProfil;
  }
}
