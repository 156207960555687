// #region imports
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
// #endregion

export class ApiResponseBodyUtils {
  static ExtractArrayResponseBody<T>(response: GeneriqueResponse): T[] {
    return response.Result as T[];
  }

  static ExtractResponseBody<T>(response: GeneriqueResponse): T {
    return response.Result as T;
  }
}
