import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faSpinner,
  faSquareCheck,
  faSquareXmark,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { debounceTime, lastValueFrom, take } from 'rxjs';
import { EnumCodeModePaiement } from 'src/app/api/models/enum/CodeModePaiement.enum';
import { DropDownList } from 'src/app/api/models/interface/KeyValue';
import { InfosBicDomiciliationRumResponse } from 'src/app/api/models/response/referenceBancaire/InfosBicDomiciliationRumResponse';
import { AbonnementService } from 'src/app/api/services/abonnement.service';
import { ParametrageGeneriqueService } from 'src/app/api/services/parametrageGenerique.service';
import { ReferenceBancaireService } from 'src/app/api/services/referenceBancaire.service';
import { AppResource } from 'src/app/app.resource';
import { ReferenceBancaire } from 'src/app/core/models/wizard.model';
import { State } from 'src/app/core/state/core.state';
import * as fromWizard from 'src/app/core/state/wizard';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { Data } from 'src/app/shared/models/reference-data.model';
import { ApiResponseBodyUtils } from 'src/app/shared/utils/apiResponseBodyUtils';
import { formatDate } from 'src/app/shared/utils/dateUnixUtils';
import { getDataFromLocaleStorage } from 'src/app/shared/utils/localStorageUtils';

@Component({
  selector: 'app-reference-bancaire',
  templateUrl: './reference-bancaire.component.html',
  styleUrls: ['./reference-bancaire.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    CalendarModule,
    RadioButtonModule,
    InputMaskModule,
    FontAwesomeModule,
    InputTextModule
  ],
  standalone: true
})
export class ReferenceBancaireComponent extends BaseComponent implements OnInit {
  @Output() tipForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() validForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() previousForm: EventEmitter<boolean> = new EventEmitter<boolean>();

  submitted: boolean = false;

  isMensualisation: boolean = true;
  isMensualisationCB: boolean = false;
  isPrelevement: boolean = false;
  isPrelevementCB: boolean = false;
  isTip: boolean = false;
  isTipSansRIB: boolean = false;

  modePaiements: Data[] = [];
  jourPrelevements: DropDownList[] = [];

  faTrash = faTrash;
  faSpinner = faSpinner;
  faSquareCheck = faSquareCheck;
  faSquareXmark = faSquareXmark;

  infosBicDomiciliationRumResponse!: InfosBicDomiciliationRumResponse;
  isLoading: boolean = false;
  ibanIsValid: boolean = false;
  research: boolean = false;
  bicIsValid: boolean = false;

  //#region FormGroup
  referenceBancaireForm!: FormGroup;
  //#endregion

  //#region GetFormControls
  get modePaiement() {
    return this.referenceBancaireForm.get('modePaiement');
  }

  get dateFinValiditeCB() {
    return this.referenceBancaireForm.get('dateFinValiditeCB');
  }

  get jourPrelevement() {
    return this.referenceBancaireForm.get('jourPrelevement');
  }

  get acquisitionConsentement() {
    return this.referenceBancaireForm.get('acquisitionConsentement');
  }

  get titulaire() {
    return this.referenceBancaireForm.get('titulaire');
  }

  get iban() {
    return this.referenceBancaireForm.get('iban');
  }

  get domiciliation() {
    return this.referenceBancaireForm.get('domiciliation');
  }

  get bic() {
    return this.referenceBancaireForm.get('bic');
  }

  get numeroRUM() {
    return this.referenceBancaireForm.get('numeroRUM');
  }

  get dateRUM() {
    return this.referenceBancaireForm.get('dateRUM');
  }
  //#endregion

  constructor(
    store: Store<State>,
    resources: AppResource,
    messageService: MessageService,
    private parametrageGeneriqueService: ParametrageGeneriqueService,
    private referenceBancaireService: ReferenceBancaireService,
    private abonnementService: AbonnementService
  ) {
    super(store, resources, messageService);
    //Initialisation des formulaires
    this.initForm();
  }

  override async ngOnInit() {
    super.ngOnInit();

    for (let index = 0; index < 30; index++) {
      this.jourPrelevements.push({ Key: (index + 1).toString(), Value: (index + 1).toString() });
    }

    //Initialisation du refDataManager
    await this.initDataManager();

    let referenceBancaireData = await lastValueFrom(
      this.store.select(fromWizard.selectReferenceBancaireData).pipe(take(1))
    );

    this.modePaiement?.valueChanges.subscribe(selectedValue => {
      this.isMensualisation = false;
      this.isMensualisationCB = false;
      this.isPrelevement = false;
      this.isPrelevementCB = false;
      this.isTip = false;
      this.isTipSansRIB = false;

      if (this.iban?.value) {
        this.iban?.setValue(this.iban?.value);
      }

      switch (selectedValue.Key) {
        case EnumCodeModePaiement.Mensualisation:
          this.clearValidators();
          this.jourPrelevement?.setValidators(Validators.required);
          this.acquisitionConsentement?.setValidators(Validators.required);
          this.titulaire?.setValidators([Validators.required, Validators.maxLength(24)]);
          this.iban?.setValidators([Validators.required, Validators.maxLength(34)]);
          this.domiciliation?.setValidators([Validators.required, Validators.maxLength(24)]);
          this.bic?.setValidators([Validators.required, Validators.maxLength(11)]);
          this.numeroRUM?.setValidators([Validators.required, Validators.maxLength(35)]);
          this.dateRUM?.setValidators(Validators.required);
          this.updateValidators();
          this.isMensualisation = true;
          this.tipForm.emit(false);
          this.acquisitionConsentement?.setValue(
            referenceBancaireData.acquisitionConsentement ?? 'T'
          );
          break;
        case EnumCodeModePaiement.MensualisationCB:
          this.clearValidators();
          this.dateFinValiditeCB?.setValidators(Validators.required);
          this.jourPrelevement?.setValidators(Validators.required);
          this.updateValidators();
          this.isMensualisationCB = true;
          this.tipForm.emit(true);
          this.iban?.setValue(null);
          this.bic?.setValue(null, { emitEvent: false });
          this.titulaire?.setValue(null, { emitEvent: false });
          this.domiciliation?.setValue(null, { emitEvent: false });
          this.acquisitionConsentement?.setValue(null, { emitEvent: false });
          this.numeroRUM?.setValue(null, { emitEvent: false });
          this.dateRUM?.setValue(null, { emitEvent: false });
          break;
        case EnumCodeModePaiement.Prelevement:
          this.clearValidators();
          this.acquisitionConsentement?.setValidators(Validators.required);
          this.titulaire?.setValidators([Validators.required, Validators.maxLength(24)]);
          this.iban?.setValidators([Validators.required, Validators.maxLength(34)]);
          this.domiciliation?.setValidators([Validators.required, Validators.maxLength(24)]);
          this.bic?.setValidators([Validators.required, Validators.maxLength(11)]);
          this.numeroRUM?.setValidators([Validators.required, Validators.maxLength(35)]);
          this.dateRUM?.setValidators(Validators.required);
          this.updateValidators();
          this.isPrelevement = true;
          this.tipForm.emit(false);
          this.acquisitionConsentement?.setValue(
            referenceBancaireData.acquisitionConsentement ?? 'T'
          );
          break;
        case EnumCodeModePaiement.PrelevementCB:
          this.clearValidators();
          this.dateFinValiditeCB?.setValidators(Validators.required);
          this.updateValidators();
          this.isPrelevementCB = true;
          this.tipForm.emit(true);
          this.iban?.setValue(null);
          this.bic?.setValue(null, { emitEvent: false });
          this.titulaire?.setValue(null, { emitEvent: false });
          this.domiciliation?.setValue(null, { emitEvent: false });
          this.acquisitionConsentement?.setValue(null, { emitEvent: false });
          this.numeroRUM?.setValue(null, { emitEvent: false });
          this.dateRUM?.setValue(null, { emitEvent: false });
          break;
        case EnumCodeModePaiement.Tip:
          this.clearValidators();
          this.acquisitionConsentement?.setValidators(Validators.required);
          this.titulaire?.setValidators([Validators.required, Validators.maxLength(24)]);
          this.iban?.setValidators([Validators.required, Validators.maxLength(34)]);
          this.domiciliation?.setValidators([Validators.required, Validators.maxLength(24)]);
          this.bic?.setValidators([Validators.required, Validators.maxLength(11)]);
          this.updateValidators();
          this.isTip = true;
          this.tipForm.emit(true);
          this.acquisitionConsentement?.setValue(
            referenceBancaireData.acquisitionConsentement ?? 'T'
          );
          this.numeroRUM?.setValue(null, { emitEvent: false });
          this.dateRUM?.setValue(null, { emitEvent: false });
          break;
        case EnumCodeModePaiement.TipSansRib:
          this.clearValidators();
          this.updateValidators();
          this.isTipSansRIB = true;
          this.tipForm.emit(true);
          this.iban?.setValue(null);
          this.bic?.setValue(null, { emitEvent: false });
          this.titulaire?.setValue(null, { emitEvent: false });
          this.domiciliation?.setValue(null, { emitEvent: false });
          this.acquisitionConsentement?.setValue(null, { emitEvent: false });
          this.numeroRUM?.setValue(null, { emitEvent: false });
          this.dateRUM?.setValue(null, { emitEvent: false });
          break;
      }
    });

    this.iban?.valueChanges.pipe(debounceTime(1000)).subscribe(async res => {
      this.ibanIsValid = false;
      this.bicIsValid = false;
      this.research = false;
      this.isLoading = res !== null && res !== '';

      if (this.modePaiement?.value.Key === EnumCodeModePaiement.TipSansRib) return;

      this.ibanIsValid = ApiResponseBodyUtils.ExtractResponseBody<boolean>(
        await lastValueFrom(this.referenceBancaireService.isIbanValid(res))
      );

      this.infosBicDomiciliationRumResponse =
        ApiResponseBodyUtils.ExtractResponseBody<InfosBicDomiciliationRumResponse>(
          await lastValueFrom(this.referenceBancaireService.getInfosBicDomiciliationRum(res))
        );

      if (this.infosBicDomiciliationRumResponse?.CodeBic)
        this.bicIsValid = ApiResponseBodyUtils.ExtractResponseBody<boolean>(
          await lastValueFrom(
            this.referenceBancaireService.isBicValid(
              this.infosBicDomiciliationRumResponse.CodeBic,
              res
            )
          )
        );

      this.domiciliation?.setValue(this.infosBicDomiciliationRumResponse?.Domiciliation);
      this.bic?.setValue(this.infosBicDomiciliationRumResponse?.CodeBic);
      if (
        [EnumCodeModePaiement.Mensualisation, EnumCodeModePaiement.Prelevement].includes(
          this.modePaiement?.value.Key
        )
      ) {
        this.numeroRUM?.setValue(this.infosBicDomiciliationRumResponse?.NumeroRum);
        this.dateRUM?.setValue(format(new Date(), 'dd/MM/yyyy'));
      }
      this.research = res !== null && res !== '';
      this.isLoading = false;
    });

    this.referenceBancaireForm.valueChanges.subscribe(res => {
      if (this.submitted) this.submitted = false;
    });

    this.initDataForm(referenceBancaireData);

    this.referenceBancaireForm.valueChanges.subscribe(() => {
      this.automaticSave();
    });
  }

  //#region Initialisation Data Manager
  async initDataManager() {
    this.modePaiements = await getDataFromLocaleStorage(
      this.resource.refDataManager.modePaiement,
      this.parametrageGeneriqueService
    );
    this.modePaiements = this.modePaiements
      .sort((a, b) => a.Value.localeCompare(b.Value))
      .map(modePaiement => {
        modePaiement.disabled = [
          EnumCodeModePaiement.MensualisationCB,
          EnumCodeModePaiement.PrelevementCB
        ].includes(modePaiement.Key as EnumCodeModePaiement);
        return modePaiement;
      });
  }
  //#endregion

  initForm() {
    this.referenceBancaireForm = new FormGroup(
      {
        modePaiement: new FormControl<DropDownList | null>(null, Validators.required),
        dateFinValiditeCB: new FormControl<Date | null>(null, Validators.required),
        jourPrelevement: new FormControl<DropDownList | null>(null, Validators.required),
        acquisitionConsentement: new FormControl<string | null>(null, Validators.required),
        titulaire: new FormControl<string | null>(null, [
          Validators.required,
          Validators.maxLength(24)
        ]),
        iban: new FormControl<string | null>(null, {
          validators: [Validators.required, Validators.maxLength(34)],
          nonNullable: true
        }),
        domiciliation: new FormControl<string | null>(null, [
          Validators.required,
          Validators.maxLength(24)
        ]),
        bic: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(11)]),
        numeroRUM: new FormControl<string | null>(null, [
          Validators.required,
          Validators.maxLength(35)
        ]),
        dateRUM: new FormControl<string | null>(null, Validators.required)
      },
      {
        updateOn: 'change'
      }
    );
  }

  initDataForm(referenceBancaire: ReferenceBancaire) {
    let modePaiement = referenceBancaire.modePaiement ?? 'M';
    this.modePaiement?.setValue(
      this.modePaiements.find((res: DropDownList) => {
        return res.Key === modePaiement;
      })
    );

    this.dateFinValiditeCB?.setValue(referenceBancaire?.dateValiditeCB);
    this.jourPrelevement?.setValue(
      this.jourPrelevements.find((res: DropDownList) => {
        return res.Value === referenceBancaire.jourPrelevement?.toString();
      })
    );

    this.acquisitionConsentement?.setValue(referenceBancaire.acquisitionConsentement ?? 'T');

    this.titulaire?.setValue(referenceBancaire.titulaire);

    if (referenceBancaire.iban) this.iban?.setValue(referenceBancaire.iban);
    this.domiciliation?.setValue(referenceBancaire.domiciliation);
    this.bic?.setValue(referenceBancaire.bic);
    this.numeroRUM?.setValue(referenceBancaire.numeroRUM);
    this.dateRUM?.setValue(formatDate(referenceBancaire.dateRUM ?? ''));
  }

  returnPreviousForm() {
    this.previousForm.emit(true);
  }

  submitForm() {
    if (this.referenceBancaireForm.valid) {
      this.validForm.emit(true);
    } else {
      this.submitted = true;
      this.referenceBancaireForm.markAllAsTouched();
    }
  }

  async cancelForm() {
    const referenceBancaireData = await lastValueFrom(
      this.store.select(fromWizard.selectReferenceBancaireData).pipe(take(1))
    );
    await this.initDataForm(referenceBancaireData);
  }

  cleanInfoBanque() {
    this.dateFinValiditeCB?.setValue(null, { emitEvent: false });
    this.titulaire?.setValue(null, { emitEvent: false });
    this.iban?.setValue(null, { emitEvent: false });
    this.bic?.setValue(null, { emitEvent: false });
    this.numeroRUM?.setValue(null, { emitEvent: false });
    this.dateRUM?.setValue(null, { emitEvent: false });
    this.domiciliation?.setValue(null, { emitEvent: false });
    this.research = false;
  }

  clearValidators() {
    this.modePaiement?.clearValidators();
    this.dateFinValiditeCB?.clearValidators();
    this.jourPrelevement?.clearValidators();
    this.acquisitionConsentement?.clearValidators();
    this.titulaire?.clearValidators();
    this.iban?.clearValidators();
    this.domiciliation?.clearValidators();
    this.bic?.clearValidators();
    this.numeroRUM?.clearValidators();
    this.dateRUM?.clearValidators();
  }

  updateValidators() {
    this.modePaiement?.updateValueAndValidity({ emitEvent: false });
    this.dateFinValiditeCB?.updateValueAndValidity({ emitEvent: false });
    this.jourPrelevement?.updateValueAndValidity({ emitEvent: false });
    this.acquisitionConsentement?.updateValueAndValidity({ emitEvent: false });
    this.titulaire?.updateValueAndValidity({ emitEvent: false });
    this.iban?.updateValueAndValidity({ emitEvent: false });
    this.domiciliation?.updateValueAndValidity({ emitEvent: false });
    this.bic?.updateValueAndValidity({ emitEvent: false });
    this.numeroRUM?.updateValueAndValidity({ emitEvent: false });
    this.dateRUM?.updateValueAndValidity({ emitEvent: false });
  }

  automaticSave() {
    this.store.dispatch(
      fromWizard.updateReferenceBancaire({
        payload: {
          modePaiement: this.modePaiement?.value?.Key,
          dateValiditeCB: this.dateFinValiditeCB?.value,
          jourPrelevement: this.jourPrelevement?.value?.Key,
          acquisitionConsentement: this.acquisitionConsentement?.value,
          titulaire: this.titulaire?.value,
          iban: this.iban?.value?.toUpperCase(),
          domiciliation: this.domiciliation?.value,
          bic: this.bic?.value?.toUpperCase(),
          numeroRUM: this.numeroRUM?.value,
          dateRUM: this.dateRUM?.value
        }
      })
    );
  }
}
