import { DemandeBranchementRequest } from './../../api/models/request/abonnement/demandeBranchementRequest';
import { CommuneCompteurRequest } from './../../api/models/request/accueil-csc/communeCompteurRequest';
import {
  EvenementRequest,
  HasFichiersFactureRequest
} from 'src/app/api/models/request/evenement/evenementRequest';
import { CommuneRueNomRequest } from 'src/app/api/models/request/accueil-csc/communeRueNomRequest';
import { AnnulerAbonnementRequest } from 'src/app/api/models/request/abonnement/annulerAbonnementRequest';
import { BranchementRequest } from 'src/app/api/models/request/compteur-installation/branchementRequest';
import {
  AdresseDtoRequest,
  CompteurRequest,
  InformationsAppareilPointDesserteRequest,
  RueDtoRequest
} from 'src/app/api/models/request/compteur-installation/compteurRequest';
import {
  LotDto,
  MettreAJourInformationsReleveRequest,
  PointDesserteDto,
  TourneeDto
} from 'src/app/api/models/request/releve/mettreAJourInformationsReleveRequest';
import { MettreAjourSegmentationRequest } from 'src/app/api/models/request/abonnement/mettreAjourSegmentationRequest';
import { CreationConsommationRequest } from 'src/app/api/models/request/consommation/creationConsommationRequest';
import { ListTraitesJuridiquesRequest } from 'src/app/api/models/request/interventions/listTraitesJuridiquesRequest';
import { MettreAjourCommentaireRequest } from 'src/app/api/models/request/abonnement/mettreAjourCommentaireRequest';
import { mettreAjourServicesDiversRequest } from 'src/app/api/models/request/abonnement/mettreAjourServicesDiversRequest';
import {
  AdresseDto,
  CiviliteDto,
  MettreAjourConcessionRequest
} from 'src/app/api/models/request/abonnement/mettreAjourConcessionRequest ';
import { MettreAjourAdministratifRequest } from 'src/app/api/models/request/abonnement/mettreAjourAdministratifRequest';
import { Chorus } from 'src/app/api/models/interface/Chorus';
import { EDI } from 'src/app/api/models/interface/EDI';
import {
  Civilite,
  MettreAjourProprietaireGerantRequest
} from 'src/app/api/models/request/abonnement/mettreAjourProprietaireGerantRequest';
import { MettreAJourInformationsFacturationRequest } from 'src/app/api/models/request/facturation/mettreAJourInformationsFacturationRequest';
import { MettreAjourClientFactureRequest } from 'src/app/api/models/request/synthese-client/mettreAjoursClientFactureRequest';
import { RessourceInterventionRequest } from 'src/app/api/models/request/interventions/ressourceInterventionRequest';
import { AjoutInterventionRequest } from 'src/app/api/models/request/interventions/ajoutInterventionRequest';
import { GenererRapportInterventionRequest } from 'src/app/api/models/request/interventions/genererRapportInterventionRequest';
import { MettreAjoursClientCommunicationRequest } from 'src/app/api/models/request/synthese-client/mettreAjoursClientCommunicationRequest';
import { MettreAjoursClientAdresseRequest } from 'src/app/api/models/request/synthese-client/mettreAjourClientAdresseRequest';
import { MettreAjourClientAdministratifRequest } from 'src/app/api/models/request/synthese-client/mettreAjourClientAdministratifRequest';
import { BranchementNonGereeForm } from 'src/app/api/models/interface/BranchementNonGeree';
import { GenerationSimulerRequest } from 'src/app/api/models/request/facilite-paiement/generationSimulerRequest';
import { CreationRemboursementRequest } from 'src/app/api/models/request/remboursement/CreationRemboursementRequest';
import { SimulerMensualitesRequest } from 'src/app/api/models/request/prelevement/SimulerMensualitesRequest';
import { MettreAjourRechercheGeneriqueRequest } from 'src/app/api/models/request/abonnement/mettreAjourRechercheGeneriqueRequest';
import { FilterSearch } from 'src/app/api/models/interface/FilterSearch';
import {
  CreerAvoirPartielVolumeRequest,
  CreerAvoirPartielMontantRequest,
  CreerAvoirTotalRequest,
  CalculAvoirPartielMontantRequest,
  CalculAvoirPartielVolumesRequest,
  InformationsCalculAvoirPartielVolume
} from 'src/app/api/models/request/dossier-compte/creerAvoirRequest';
import { CategorieTiers } from 'src/app/api/models/interface/CategorieTiers';
import { NatureIdTiers } from 'src/app/api/models/interface/NatureIdTiers';
import { TypeTiers } from 'src/app/api/models/interface/TypeTiers';
import { NatureJuridiqueTiers } from 'src/app/api/models/interface/NatureJuridiqueTiers';
import { MettreAjourClientInfoPesRequest } from 'src/app/api/models/request/synthese-client/mettreAjourClientInfoPesRequest';
import { ChangerMotDePasseRequest } from 'src/app/api/models/request/authentification/changerMotDePasseRequest';
import { EnvoyerEmailReinitialiserMotDePasseRequest } from 'src/app/api/models/request/authentification/envoyerEmailReinitialiserMotDePasseRequest';
import { ReinitialiserMotDePasseAvecTokenRequest } from 'src/app/api/models/request/authentification/reinitialiserMotDePasseAvecTokenRequest';
import { FonctionWebPostRequest } from 'src/app/api/models/request/autorisation-ressource/FonctionWebPostRequest';
import { FonctionWebRequest } from 'src/app/api/models/request/autorisation-ressource/FonctionWebRequest';
import {
  AvoirPartielCalculMontant,
  AvoirPartielCalculVolume
} from 'src/app/api/models/interface/dossierCompte';
import {
  AnnulerCourrierRequest,
  BaseDocumentaireRequest,
  CreerEtTransfererRequest,
  PreviewHtmlToPdfRequest,
  TraitementImmediatRequest
} from 'src/app/api/models/request/communications/CourrierRequest';

import { ChangerEtatAbonnementRequest } from 'src/app/api/models/request/abonnement/changerEtatAbonnementRequest';
import {
  AdresseDbDto,
  CanalAcquisitionSepa,
  CiviliteDbDto,
  InformationsAbonnementForProcessDto,
  InformationsClientForProcessAbonnementDto,
  InformationsPaiementDto,
  InformationsProprietaireDto,
  InformationsSegmentationDto,
  ReferenceBancaireDto,
  RueDbDto
} from 'src/app/api/models/interface/InformationsForProcessDto';
import {
  AdresseMDto,
  CiviliteMDto,
  InformationsAbonnementForProcessMDto,
  InformationsClientForProcessAbonnementMDto,
  InformationsFactureEntrantForProcessAbonnementMDto,
  InformationsPaiementMDto,
  InformationsProprietaireMDto,
  InformationsSegmentationMDto,
  ReferenceBancaireMDto,
  RueMDto
} from 'src/app/api/models/interface/MutationProcess';
import {
  CloturerInterventionRequest,
  Creneau
} from 'src/app/api/models/request/interventions/cloturerInterventionRequest';
import { DateStringNull, NullOrUndefined } from 'src/app/api/models/type-alias/type-alias';
import { MutationRequest } from 'src/app/api/models/request/abonnement/mutationRequest';
import { ModifierEtatRequest } from 'src/app/api/models/request/facilite-paiement/modifierEtatRequest';
import { MiseEnServiceRequest } from 'src/app/api/models/request/abonnement/mettreEnServiceRequest';
export class ApiRequestBodyUtils {
  static GenerateBranchementRequestBody(
    accessibilite: string | null,
    typeDemande: string | null,
    etatBranchement: string | null,
    modeFermeture: string | null,
    etatProtection: string | null,
    materiauAvant: string | null,
    materiauApres: string | null,
    modeProtection: string | null,
    diametreFacturation: number | null,
    numAbonnement: string,
    numEp: string | null,
    numEu: string | null,
    numPointDesserte: string
  ): BranchementRequest {
    return {
      CodeAccessibilite: accessibilite,
      CodeDemandeBranchement: typeDemande,
      CodeEtatBranchement: etatBranchement,
      CodeModeFermetureBranchement: modeFermeture,
      CodeTypeAbonnementARisque: etatProtection,
      CodeTypeMateriauAvant: materiauAvant,
      CodeTypeMateriauApres: materiauApres,
      CodeTypeProtection: modeProtection,
      DiametreBranchement: diametreFacturation,
      NumeroAbonnement: numAbonnement,
      NumeroDemandeTravoEau: numEp,
      NumeroDemandeTravoEauUsees: numEu,
      NumeroPointDesserte: numPointDesserte
    };
  }

  static GenerateBranchementNonGereeRequestBody(
    CodeCommuneForBranchement: number | null,
    IndicateurBranchementEau: boolean,
    IndicateurBranchementEauUsee: boolean,
    InformationsClientForProcess: {
      Adresse: string | null;
      BureauDistributeur: string | null;
      CodePays: string | null;
      CodePostal: string | null;
      ComplementAdresse: string | null;
      EtatVerificationAdresse: string | null;
      IdentifiantTiers: string | null;
      IndicateurFactureBraille: boolean;
      IndicateurPliNonDistribue: string | null;
      LibellePays: string | null;
      NumeroClient: string | null;
      Ville: string | null;
      CodeApe: string | null;
      codeCivilite: string | null;
      Email: string | null;
      Nom: string | null;
      NumeroSiret: string | null;
      NumeroTelephoneEtranger: string | null;
      Prenom: string | null;
      RaisonSociale: string | null;
    }
  ): BranchementNonGereeForm {
    return {
      CodeCommuneForBranchement,
      IndicateurBranchementEau,
      IndicateurBranchementEauUsee,
      InformationsClientForProcess: {
        Adresse: InformationsClientForProcess.Adresse,
        BureauDistributeur: InformationsClientForProcess.BureauDistributeur,
        CodePays: InformationsClientForProcess.CodePays,
        CodePostal: InformationsClientForProcess.CodePostal,
        ComplementAdresse: InformationsClientForProcess.ComplementAdresse,
        EtatVerificationAdresse: InformationsClientForProcess.EtatVerificationAdresse,
        IdentifiantTiers: InformationsClientForProcess.IdentifiantTiers,
        IndicateurFactureBraille: InformationsClientForProcess.IndicateurFactureBraille,
        IndicateurPliNonDistribue: InformationsClientForProcess.IndicateurPliNonDistribue,
        LibellePays: InformationsClientForProcess.LibellePays,
        NumeroClient: InformationsClientForProcess.NumeroClient,
        Ville: InformationsClientForProcess.Ville,
        CodeApe: InformationsClientForProcess.CodeApe,
        codeCivilite: InformationsClientForProcess.codeCivilite,
        Email: InformationsClientForProcess.Email,
        Nom: InformationsClientForProcess.Nom,
        NumeroSiret: InformationsClientForProcess.NumeroSiret,
        NumeroTelephoneEtranger: InformationsClientForProcess.NumeroTelephoneEtranger,
        Prenom: InformationsClientForProcess.Prenom,
        RaisonSociale: InformationsClientForProcess.RaisonSociale
      }
    };
  }

  static GenerateRueConcessionDtoRequestBody(
    codeCommune: number,
    numero: number | undefined
  ): RueDtoRequest {
    return {
      CodeCommune: codeCommune,
      Numero: numero
    };
  }

  static GenerateRueDbDtoRequestBody(
    codeCommune: number,
    numero: number | undefined,
    nom: string,
    codeTypeVoie: string
  ): RueDbDto {
    return {
      CodeCommune: codeCommune,
      Numero: numero ? numero : undefined,
      Nom: nom,
      CodeTypeVoie: codeTypeVoie
    };
  }

  static GenerateAdresseDtoRequestBody(
    numeroDansLaRue: number | null,
    complementNumeroDansLaRue: string,
    etage: string,
    complementAdresse: string,
    rue: RueDbDto
  ): AdresseDbDto {
    return {
      NumeroDansLaRue: numeroDansLaRue ? numeroDansLaRue : null,
      ComplementNumeroDansLaRue: complementNumeroDansLaRue,
      Etage: etage,
      ComplementAdresse: complementAdresse,
      Rue: rue
    };
  }

  static GenerateAdresseCompteurDtoRequestBody(
    numeroDansLaRue: number,
    complementNumeroDansLaRue: string,
    rue: RueDtoRequest
  ): AdresseDtoRequest {
    return {
      NumeroDansLaRue: numeroDansLaRue,
      ComplementNumeroDansLaRue: complementNumeroDansLaRue,
      Rue: rue
    };
  }

  static GenerateCompteurRequestBody(
    numeroPointDesserte: string,
    numeroAbonnement: string,
    numeroAbonnementActif: string,
    emplacementCompteur: string,
    codeRaccordement: string,
    indicateurBagueAntiFraude: boolean,
    codeVigik: boolean,
    codeMotifDysfonctionnementTeleReleve: string,
    codeMotifAbsenceTeleReleve: string,
    indicateurGestionM2O: boolean,
    codeGestionM2OCommune: string | null,
    indicateurClapetAntiRetour: boolean,
    datePoseClapetAntiRetour: string | null,
    codeEtatClapetAntiRetour: string,
    adresseCompteur: AdresseDtoRequest
  ): CompteurRequest {
    return {
      NumeroPointDesserte: numeroPointDesserte,
      NumeroAbonnement: numeroAbonnement,
      NumeroAbonnementActif: numeroAbonnementActif,
      EmplacementCompteur: emplacementCompteur,
      CodeRaccordement: codeRaccordement,
      IndicateurBagueAntiFraude: indicateurBagueAntiFraude,
      CodeVigik: codeVigik,
      CodeMotifDysfonctionnementTeleReleve: codeMotifDysfonctionnementTeleReleve,
      CodeMotifAbsenceTeleReleve: codeMotifAbsenceTeleReleve,
      IndicateurGestionM2O: indicateurGestionM2O,
      CodeGestionM2OCommune: codeGestionM2OCommune,
      IndicateurClapetAntiRetour: indicateurClapetAntiRetour,
      DatePoseClapetAntiRetour: datePoseClapetAntiRetour,
      CodeEtatClapetAntiRetour: codeEtatClapetAntiRetour,
      AdresseCompteur: adresseCompteur
    };
  }
  static GenerateInformationsAppareilPointDesserteRequestBody(
    informationsAppareilPointDesserte: CompteurRequest
  ): InformationsAppareilPointDesserteRequest {
    return {
      informationsAppareilPointDesserte: informationsAppareilPointDesserte
    };
  }

  static GenerateEvenementRequestBody(
    numeroAbonnement: string | NullOrUndefined,
    numeroClient: string | NullOrUndefined,
    dateLimite: string | NullOrUndefined
  ): EvenementRequest {
    return {
      NumeroAbonnement: numeroAbonnement,
      NumeroClient: numeroClient,
      DateLimite: dateLimite
    };
  }

  static GenerateClientEvenementRequestBody(
    numeroAbonnement: string | NullOrUndefined,
    numeroClient: string | NullOrUndefined,
    dateLimite: string | NullOrUndefined
  ): EvenementRequest {
    return {
      NumeroAbonnement: numeroAbonnement,
      NumeroClient: numeroClient,
      DateLimite: dateLimite
    };
  }

  static GenerateCommuneCompteurRequestBody(
    codeCommune: number | null,
    numeroCompteurPhysique: string
  ): CommuneCompteurRequest {
    return {
      CodeCommune: codeCommune,
      NumeroCompteurPhysique: numeroCompteurPhysique
    };
  }

  static GenerateCommuneRueNomRequestBody(
    communeRue: number | null,
    rue: number | null,
    numeroDebut: number | null,
    numeroFin: number | null,
    exclusionAbtAnnule: boolean,
    nomAbonne: string
  ): CommuneRueNomRequest {
    return {
      CommuneRue: communeRue,
      Rue: rue,
      NumeroDebut: numeroDebut,
      NumeroFin: numeroFin,
      ExclusionAbtAnnule: exclusionAbtAnnule,
      NomAbonne: nomAbonne
    };
  }
  static GenerateAnnulerAbonnementRequestBody(numeroAbonnement: string): AnnulerAbonnementRequest {
    return {
      numeroAbonnement: numeroAbonnement
    };
  }

  static GenerateChangerEtatAbonnementRequestBody(
    numeroAbonnement: string,
    codeEtatCible: string
  ): ChangerEtatAbonnementRequest {
    return {
      numeroAbonnement: numeroAbonnement,
      codeEtatCible: codeEtatCible
    };
  }
  static GenerateSegmentationRequestBody(
    numeroAbonnement: string,
    codeActivite: string,
    codeCategorieAbonnement: string,
    codeSousCategorieAbonnement: string,
    codeParticulariteAbonnement: string,
    codeSensibiliteAbonnement: string,
    idTypeHabitation: number,
    codeTypeAbonnementARisque: string
  ): MettreAjourSegmentationRequest {
    return {
      NumeroAbonnement: numeroAbonnement,
      CodeActivite: codeActivite,
      CodeCategorieAbonnement: codeCategorieAbonnement,
      CodeSousCategorieAbonnement: codeSousCategorieAbonnement,
      CodeParticulariteAbonnement: codeParticulariteAbonnement,
      CodeSensibiliteAbonnement: codeSensibiliteAbonnement,
      IdTypeHabitation: idTypeHabitation,
      CodeTypeAbonnementARisque: codeTypeAbonnementARisque
    };
  }
  static GenerateAjoutConsommationRequestBody(
    anneeHistorique: string,
    codeEmetteur: string,
    codeNatureHistoriqueIndex: string,
    codeTypeReleve: string,
    dateIndexReleve: string | undefined,
    numeroAbonnement: string,
    periodeHistorique: string,
    valeurIndex: number,
    volumeASupprimer: number,
    volumeConsomme: number,
    volumeCtr: number,
    volumeModifie: number,
    VolumeModifiePrecedent: number,
    IndicateurAnalyseReleve: string | null
  ): CreationConsommationRequest {
    return {
      AnneeFacturationEnCours: anneeHistorique,
      AnneeHistorique: anneeHistorique,
      CodeEmetteur: codeEmetteur,
      CodeNatureHistoriqueIndex: codeNatureHistoriqueIndex,
      CodeTypeReleve: codeTypeReleve,
      DateIndexReleve: dateIndexReleve,
      NumeroAbonnement: numeroAbonnement,
      PeriodeFacturationEnCours: periodeHistorique,
      PeriodeHistorique: periodeHistorique,
      ValeurIndex: valeurIndex,
      VolumeASupprimer: volumeASupprimer,
      VolumeConsomme: volumeConsomme,
      VolumeCtr: volumeCtr,
      VolumeModifie: volumeModifie,
      VolumeModifiePrecedent: VolumeModifiePrecedent,
      IndicateurAnalyseReleve: IndicateurAnalyseReleve
    };
  }

  static GenerateCommentaireRequestBody(
    commentaire: string,
    numeroAbonnement: string,
    numeroChapitre1: string,
    observation: string
  ): MettreAjourCommentaireRequest {
    return {
      Commentaire: commentaire,
      NumeroAbonnement: numeroAbonnement,
      NumeroChapitre1: numeroChapitre1,
      Observation: observation
    };
  }

  static GenerateServicesDiversRequestBody(
    emailTuteur: string,
    indicateurOffreConfiance: string,
    indicateurPresenceTuteur: string,
    motDePasseOffreConfiance: string,
    numeroAbonnement: string,
    referentOffreConfiance: string
  ): mettreAjourServicesDiversRequest {
    return {
      EmailTuteur: emailTuteur,
      IndicateurOffreConfiance: indicateurOffreConfiance,
      IndicateurPresenceTuteur: indicateurPresenceTuteur,
      MotDePasseOffreConfiance: motDePasseOffreConfiance,
      NumeroAbonnement: numeroAbonnement,
      ReferentOffreConfiance: referentOffreConfiance
    };
  }

  static GenerateRechercheGeneriqueRequestBody(
    pageCourante: number,
    nbParPage: number,
    force: boolean,
    typeDtoName: string,
    orderByProperty: string,
    orderByDescending: boolean,
    criteres: FilterSearch[]
  ): MettreAjourRechercheGeneriqueRequest {
    return {
      PageCourante: pageCourante,
      NbParPage: nbParPage,
      Force: force,
      TypeDtoName: typeDtoName,
      OrderByProperty: orderByProperty,
      OrderByDescending: orderByDescending,
      Criteres: criteres
    };
  }

  static GenerateConcessionRequestBody(
    numeroAbonnement: string,
    civiliteTitulaireConcession: CiviliteDto | undefined,
    adresse: AdresseDto,
    nomTitulaireConcession: string,
    identifiantTiers?: string
  ): MettreAjourConcessionRequest {
    return {
      NumeroAbonnement: numeroAbonnement,
      CiviliteTitulaireConcession: civiliteTitulaireConcession,
      Adresse: adresse,
      NomTitulaireConcession: nomTitulaireConcession,
      IdentifiantTiers: identifiantTiers
    };
  }

  static GenerateAdresseAvecEtageDtoRequestBody(
    numeroDansLaRue: number,
    complementNumeroDansLaRue: string,
    etage: string,
    complementAdresse: string,
    rue: RueDtoRequest
  ): AdresseDto {
    return {
      NumeroDansLaRue: numeroDansLaRue,
      ComplementNumeroDansLaRue: complementNumeroDansLaRue,
      Etage: etage,
      ComplementAdresse: complementAdresse,
      Rue: rue
    };
  }

  static GenerateAdministratifRequestBody(
    numeroAbonnement: string,
    nombreOccupants: string,
    nombreLocaux: string,
    nombreLocauxProfessionnel: string,
    numeroSiret: string,
    codeApe: string,
    indicateurChorus: boolean,
    indicateurEDI: boolean,
    chorus: Chorus,
    edi: EDI,
    dateNaissance: DateStringNull
  ): MettreAjourAdministratifRequest {
    return {
      NumeroAbonnement: numeroAbonnement,
      NombreOccupants: nombreOccupants,
      NombreLocaux: nombreLocaux,
      NombreLocauxProfessionnel: nombreLocauxProfessionnel,
      NumeroSiret: numeroSiret,
      CodeApe: codeApe,
      IndicateurChorus: indicateurChorus,
      IndicateurEDI: indicateurEDI,
      Chorus: chorus,
      EDI: edi,
      DateNaissance: dateNaissance
    };
  }

  static GenerateCiviliteRequestBody(code: string): Civilite {
    return {
      Code: code
    };
  }

  static GenerateProprietaireGerantRequestBody(
    numero: string,
    civilite: Civilite | undefined,
    nomProprietaire: string,
    adressePostaleProprietaire: string,
    codePostalProprietaire: string,
    villeProprietaire: string,
    numeroTelephoneProprietaire: string,
    adresseMailProprietaire: string
  ): MettreAjourProprietaireGerantRequest {
    return {
      Numero: numero,
      Civilite: civilite,
      NomProprietaire: nomProprietaire,
      AdressePostaleProprietaire: adressePostaleProprietaire,
      CodePostalProprietaire: codePostalProprietaire,
      VilleProprietaire: villeProprietaire,
      NumeroTelephoneProprietaire: numeroTelephoneProprietaire,
      AdresseMailProprietaire: adresseMailProprietaire
    };
  }

  static GenerateTourneeDtoRequestBody(numero: number): TourneeDto {
    return {
      Numero: numero
    };
  }

  static GenerateLotDtoRequestBody(
    numeroLot: number,
    numeroTournee: number,
    tournee: TourneeDto
  ): LotDto {
    return {
      NumeroLot: numeroLot,
      NumeroTournee: numeroTournee,
      Tournee: tournee
    };
  }

  static GeneratePointDesserteDtoRequestBody(
    numero: string,
    numeroOrdreReleve: number,
    commentaireReleve: string,
    lot: LotDto
  ): PointDesserteDto {
    return {
      Numero: numero,
      NumeroOrdreReleve: numeroOrdreReleve,
      CommentaireReleve: commentaireReleve,
      Lot: lot
    };
  }

  static GenerateMettreAJourInformationsReleveRequestBody(
    numeroAbonnement: string,
    periodeFacturationEnCours: string,
    anneeFacturationEnCours: string,
    volumeAcompteParDefaut: number,
    pointDesserte: PointDesserteDto
  ): MettreAJourInformationsReleveRequest {
    return {
      NumeroAbonnement: numeroAbonnement,
      PeriodeFacturationEnCours: periodeFacturationEnCours,
      AnneeFacturationEnCours: anneeFacturationEnCours,
      VolumeAcompteParDefaut: volumeAcompteParDefaut,
      PointDesserte: pointDesserte
    };
  }

  static HasFichiersFactureRequestBody(numeroFacture: string): HasFichiersFactureRequest {
    return {
      NumeroFacture: numeroFacture
    };
  }

  static GenerateMettreAJourInformationsFacturationRequestBody(
    axe: string | NullOrUndefined,
    codeCategorieAbonnementFacturation: string,
    codeCategorieFacturation: string,
    codeEtatAbonement: string | NullOrUndefined,
    codeEtatRaccordement: string | NullOrUndefined,
    codeModeDistribution: number | NullOrUndefined,
    codeRedevanceAssainissement: string | NullOrUndefined,
    codeRedevancePollution: string | NullOrUndefined,
    codeRegimeCompteur: string,
    codeSociete: string | NullOrUndefined,
    codeTraiteJuridiqueCessionInterne: string | NullOrUndefined,
    codeTypeAbonnement: string,
    codeTypeAbonnementFacturation: string,
    codeTypeAppareil: string,
    codeTypeReseauFacturation: string,
    codeTypeUsage: string,
    coefficientPollution: number,
    coefficientRejet: number,
    dateEffet: DateStringNull,
    dateProchainePrimeFixe: DateStringNull | undefined,
    dateRaccordabiliteAssainissement: DateStringNull,
    dateRaccordementAssainissement: DateStringNull,
    declassement: boolean,
    indicateurEchantillonage: boolean,
    indicateurGrosConsommateur: string,
    indicateurRedevanceEntretienBranchement: string,
    nombrePrimesFixesOuVolumeSouscrit: number,
    numeroAbonnement: string,
    numeroLotGrosConsommateur: number,
    numeroOrdreReleveGrosConsommateur: number,
    numeroPointDesserte: string | NullOrUndefined,
    pasFacturationAuto: boolean,
    prorataPrimeFixe: boolean,
    rubriqueAnalytiqueCessionInterne: string | NullOrUndefined,
    typeRegroupement: number | NullOrUndefined,
    volumeSouscritAVenir: number,
    volumeSupplementaireAVenir: number
  ): MettreAJourInformationsFacturationRequest {
    return {
      Axe: axe,
      CodeCategorieAbonnementFacturation: codeCategorieAbonnementFacturation,
      CodeCategorieFacturation: codeCategorieFacturation,
      CodeEtatAbonement: codeEtatAbonement,
      CodeEtatRaccordement: codeEtatRaccordement,
      CodeModeDistribution: codeModeDistribution,
      CodeRedevanceAssainissement: codeRedevanceAssainissement,
      CodeRedevancePollution: codeRedevancePollution,
      CodeRegimeCompteur: codeRegimeCompteur,
      CodeSociete: codeSociete,
      CodeTraiteJuridiqueCessionInterne: codeTraiteJuridiqueCessionInterne,
      CodeTypeAbonnement: codeTypeAbonnement,
      CodeTypeAbonnementFacturation: codeTypeAbonnementFacturation,
      CodeTypeAppareil: codeTypeAppareil,
      CodeTypeReseauFacturation: codeTypeReseauFacturation,
      CodeTypeUsage: codeTypeUsage,
      CoefficientPollution: coefficientPollution,
      CoefficientRejet: coefficientRejet,
      DateEffet: dateEffet,
      DateProchainePrimeFixe: dateProchainePrimeFixe,
      DateRaccordabiliteAssainissement: dateRaccordabiliteAssainissement,
      DateRaccordementAssainissement: dateRaccordementAssainissement,
      Declassement: declassement,
      IndicateurEchantillonage: indicateurEchantillonage,
      IndicateurGrosConsommateur: indicateurGrosConsommateur,
      IndicateurRedevanceEntretienBranchement: indicateurRedevanceEntretienBranchement,
      NombrePrimesFixesOuVolumeSouscrit: nombrePrimesFixesOuVolumeSouscrit,
      NumeroAbonnement: numeroAbonnement,
      NumeroLotGrosConsommateur: numeroLotGrosConsommateur,
      NumeroOrdreReleveGrosConsommateur: numeroOrdreReleveGrosConsommateur,
      NumeroPointDesserte: numeroPointDesserte,
      PasFacturationAuto: pasFacturationAuto,
      ProrataPrimeFixe: prorataPrimeFixe,
      RubriqueAnalytiqueCessionInterne: rubriqueAnalytiqueCessionInterne,
      TypeRegroupement: typeRegroupement,
      VolumeSouscritAVenir: typeRegroupement,
      VolumeSupplementaireAVenir: volumeSupplementaireAVenir
    };
  }

  static GenerateClientFactureRequestBody(
    numeroClient: string,
    identifiantTiers: string,
    nombreExemplairesFacture: string,
    nombreJoursSupplementairesPrelevement: string,
    libellePrelevement: string,
    indicateurDematerialisation: string,
    indicateurCmu: string
  ): MettreAjourClientFactureRequest {
    return {
      NumeroClient: numeroClient,
      IdentifiantTiers: identifiantTiers,
      NombreExemplairesFacture: nombreExemplairesFacture,
      NombreJoursSupplementairesPrelevement: nombreJoursSupplementairesPrelevement,
      LibellePrelevement: libellePrelevement,
      IndicateurDematerialisation: indicateurDematerialisation,
      IndicateurCmu: indicateurCmu
    };
  }

  static GenerateClientCommunicationRequestBody(
    numeroClient: string,
    identifiantTiers: string,
    numeroTelephone1: string,
    numeroTelephone2: string,
    numeroTelephoneEtranger: string,
    adresseMail: string,
    stopEmail: boolean,
    codeMediaContact: string | null,
    indicateurAlerteArretEau: string,
    formatedNumeroTelephone1: string | null,
    formatedNumeroTelephone2: string | null
  ): MettreAjoursClientCommunicationRequest {
    return {
      NumeroClient: numeroClient,
      IdentifiantTiers: identifiantTiers,
      NumeroTelephone1: numeroTelephone1,
      NumeroTelephone2: numeroTelephone2,
      NumeroTelephoneEtranger: numeroTelephoneEtranger,
      AdresseMail: adresseMail,
      StopEmail: stopEmail,
      CodeMediaContact: codeMediaContact,
      IndicateurAlerteArretEau: indicateurAlerteArretEau,
      FormatedNumeroTelephone1: formatedNumeroTelephone1,
      FormatedNumeroTelephone2: formatedNumeroTelephone2
    };
  }

  static GenerateClientAdresseRequestBody(
    numeroClient: string,
    indicateurPliNonDistribue: string,
    codePays: string,
    complementAdresse: string,
    adresse: string,
    ville: string,
    bureauDistributeur: string,
    codePostal: string,
    etatVerificationAdresse: string
  ): MettreAjoursClientAdresseRequest {
    return {
      NumeroClient: numeroClient,
      IndicateurPliNonDistribue: indicateurPliNonDistribue,
      CodePays: codePays,
      ComplementAdresse: complementAdresse,
      Adresse: adresse,
      Ville: ville,
      BureauDistributeur: bureauDistributeur,
      CodePostal: codePostal,
      EtatVerificationAdresse: etatVerificationAdresse
    };
  }

  static GenerateClientAdministratifRequestBody(
    codeApe: string,
    codeCivilite: string,
    codeOrigine: string,
    codeQualite: string,
    codeZone: string,
    dateDemandeRGPD: string | null,
    identifiantTiers: string,
    indicateurDemandeRGPD: boolean,
    indicateurGrandCompte: string,
    indicateurPrenomRequis: string,
    nomClient: string,
    numeroClient: string,
    numeroSiret: string,
    prenomClient: string,
    raisonSociale: string,
    referenceClientTravaux: string,
    soldeClient: number
  ): MettreAjourClientAdministratifRequest {
    return {
      CodeApe: codeApe,
      CodeCivilite: codeCivilite,
      CodeOrigine: codeOrigine,
      CodeQualite: codeQualite,
      CodeZone: codeZone,
      DateDemandeRGPD: dateDemandeRGPD,
      IdentifiantTiers: identifiantTiers,
      IndicateurDemandeRGPD: indicateurDemandeRGPD,
      IndicateurGrandCompte: indicateurGrandCompte,
      IndicateurPrenomRequis: indicateurPrenomRequis,
      NomClient: nomClient,
      NumeroClient: numeroClient,
      NumeroSiret: numeroSiret,
      PrenomClient: prenomClient,
      RaisonSociale: raisonSociale,
      ReferenceClientTravaux: referenceClientTravaux,
      SoldeClient: soldeClient
    };
  }

  static GenerateClientInfoPesRequestBody(
    numeroClient: string,
    categorieTiers: CategorieTiers,
    natureIdTiers: NatureIdTiers,
    natureJuridiqueTiers: NatureJuridiqueTiers,
    typeTiers: TypeTiers
  ): MettreAjourClientInfoPesRequest {
    return {
      NumeroClient: numeroClient,
      CategorieTiers: categorieTiers,
      NatureIdTiers: natureIdTiers,
      NatureJuridiqueTiers: natureJuridiqueTiers,
      TypeTiers: typeTiers
    };
  }

  static GenerateRessourceInterventionRequestBody(
    codeAction: string,
    codeCommune: number,
    codeMotif: string,
    codeTypeIntervention: string,
    numeroRue: number,
    numeroRueDebut: number | null
  ): RessourceInterventionRequest {
    return {
      CodeAction: codeAction,
      CodeCommune: codeCommune,
      CodeMotif: codeMotif,
      CodeTypeIntervention: codeTypeIntervention,
      NumeroRue: numeroRue,
      NumeroRueDebut: numeroRueDebut
    };
  }

  static GenerateAjoutInterventionRequestBody(
    codeActionLancement: string,
    codeChantier: string,
    codeCommune: number,
    codeMotifLancement: string,
    codeTraiteJuridique: string,
    codeTypeTrancheHoraire: string,
    commentaireLancement: string,
    complementNumeroDansLaRueDebut: string,
    complementNumeroDansLaRueFin: string,
    dateRendezVous: Date | string,
    dureeInterventionPrevue: number,
    indicateurUrgent: boolean,
    nomProgrammeEmmeteur: string,
    nombreRessource: number,
    numeroAbonnement: string,
    numeroClient: string,
    numeroDansLaRueDebut: number,
    numeroDansLaRueFin: number,
    numeroRue: number,
    typeIntervention: string
  ): AjoutInterventionRequest {
    return {
      CodeActionLancement: codeActionLancement,
      CodeChantier: codeChantier,
      CodeCommune: codeCommune,
      CodeMotifLancement: codeMotifLancement,
      CodeTraiteJuridique: codeTraiteJuridique,
      CodeTypeTrancheHoraire: codeTypeTrancheHoraire,
      CommentaireLancement: commentaireLancement,
      ComplementNumeroDansLaRueDebut: complementNumeroDansLaRueDebut,
      ComplementNumeroDansLaRueFin: complementNumeroDansLaRueFin,
      DateRendezVous: dateRendezVous,
      DureeInterventionPrevue: dureeInterventionPrevue,
      IndicateurUrgent: indicateurUrgent,
      NomProgrammeEmmeteur: nomProgrammeEmmeteur,
      NombreRessource: nombreRessource,
      NumeroAbonnement: numeroAbonnement,
      NumeroClient: numeroClient,
      NumeroDansLaRueDebut: numeroDansLaRueDebut,
      NumeroDansLaRueFin: numeroDansLaRueFin,
      NumeroRue: numeroRue,
      TypeIntervention: typeIntervention
    };
  }

  static GenererRapportInterventionRequestBody(
    idIntervention: string
  ): GenererRapportInterventionRequest {
    return {
      idIntervention: idIntervention
    };
  }

  //#region Demande Branchement
  static GenerateClientDbRequestBody(
    codeCivilite: string | null,
    raisonSociale: string | null,
    nom: string | null,
    numeroTelephone1: number | null,
    email: string | null,
    identifiantTiers: string | null,
    indicateurPliNonDistribue: string,
    adresse: string,
    codePostal: string,
    bureauDistributeur: string,
    etatVerificationAdresse: string
  ): InformationsClientForProcessAbonnementDto {
    return {
      CodeCivilite: codeCivilite,
      RaisonSociale: raisonSociale,
      Nom: nom,
      NumeroTelephone1: numeroTelephone1,
      Email: email,
      IdentifiantTiers: identifiantTiers,
      IndicateurPliNonDistribue: indicateurPliNonDistribue,
      Adresse: adresse,
      CodePostal: codePostal,
      BureauDistributeur: bureauDistributeur,
      EtatVerificationAdresse: etatVerificationAdresse
    };
  }

  static GeneratePaiementDbRequestBody(
    indicateurPaiementSur: boolean | null,
    codeModePaiement: string | null,
    dateValiditeCB: DateStringNull | undefined,
    jourDePrelevement: number | null,
    referenceBancaire: ReferenceBancaireDto,
    hasHistoriquePaiement: boolean,
    nomSociete: string | null,
    codeIcsSociete: string | null
  ): InformationsPaiementDto {
    return {
      IndicateurPaiementSur: indicateurPaiementSur,
      CodeModePaiement: codeModePaiement,
      DateValiditeCB: dateValiditeCB,
      JourDePrelevement: jourDePrelevement,
      ReferenceBancaire: referenceBancaire,
      HasHistoriquePaiement: hasHistoriquePaiement,
      NomSociete: nomSociete,
      CodeIcsSociete: codeIcsSociete
    };
  }

  static GenerateReferenceBancaireDbDtoRequestBody(
    canalAcquisitionSepa: CanalAcquisitionSepa | null,
    codeBic: string | null,
    codeIban: string | null,
    dateRum: string | null,
    domiciliation: string | null,
    numeroAbonnement: string | null,
    numeroRum: string | null,
    titulaire: string | null
  ): ReferenceBancaireDto {
    return {
      CanalAcquisitionSepa: canalAcquisitionSepa,
      CodeBic: codeBic,
      CodeIban: codeIban,
      DateRum: dateRum,
      Domiciliation: domiciliation,
      NumeroAbonnement: numeroAbonnement,
      NumeroRum: numeroRum,
      Titulaire: titulaire
    };
  }

  static GenerateCanalAcquisitionSepaDtoRequestBody(code: string | null): CanalAcquisitionSepa {
    return {
      Code: code
    };
  }

  static GenerateProprietaireDbRequestBody(
    codeCivilite: string | null,
    nomProprietaire: string | null,
    numeroTelephoneProprietaire: string | null,
    adressePostaleProprietaire: string | null,
    villeProprietaire: string | null,
    codePostalProprietaire: string | null,
    adresseMailProprietaire: string | null
  ): InformationsProprietaireDto {
    return {
      CodeCivilite: codeCivilite,
      NomProprietaire: nomProprietaire,
      NumeroTelephoneProprietaire: numeroTelephoneProprietaire,
      AdressePostaleProprietaire: adressePostaleProprietaire,
      VilleProprietaire: villeProprietaire,
      CodePostalProprietaire: codePostalProprietaire,
      AdresseMailProprietaire: adresseMailProprietaire
    };
  }

  static GenerateSegmentationDbRequestBody(
    codeActivite: string | null,
    codeCategorieAbonnement: string | null,
    codeSousCategorieAbonnement: string | null,
    codeParticulariteAbonnement: string | null,
    codeSensibiliteAbonnement: string | null,
    idTypeHabitation: number | null,
    codeTypeAbonnementARisque: string | null
  ): InformationsSegmentationDto {
    return {
      CodeActivite: codeActivite,
      CodeCategorieAbonnement: codeCategorieAbonnement,
      CodeSousCategorieAbonnement: codeSousCategorieAbonnement,
      CodeParticulariteAbonnement: codeParticulariteAbonnement,
      CodeSensibiliteAbonnement: codeSensibiliteAbonnement,
      IdTypeHabitation: idTypeHabitation,
      CodeTypeAbonnementARisque: codeTypeAbonnementARisque
    };
  }

  static GenerateDemandeBranchementRequestBody(
    indicateurBranchementEau: boolean,
    indicateurBranchementEauUsee: boolean,
    indicateurEnvoiCourrier: boolean,
    informationsAbonnementForProcess: InformationsAbonnementForProcessDto,
    informationsClientForProcessAbonnement: InformationsClientForProcessAbonnementDto,
    informationsPaiement: InformationsPaiementDto,
    informationsProprietaire: InformationsProprietaireDto,
    informationsSegmentation: InformationsSegmentationDto,
    numeroTravo: string
  ): DemandeBranchementRequest {
    return {
      IndicateurBranchementEau: indicateurBranchementEau,
      IndicateurBranchementEauUsee: indicateurBranchementEauUsee,
      IndicateurEnvoiCourrier: indicateurEnvoiCourrier,
      InformationsAbonnementForProcess: informationsAbonnementForProcess,
      InformationsClientForProcessAbonnement: informationsClientForProcessAbonnement,
      InformationsPaiement: informationsPaiement,
      InformationsProprietaire: informationsProprietaire,
      InformationsSegmentation: informationsSegmentation,
      NumeroTravo: numeroTravo
    };
  }
  //#endregion
  static GenerateSimulerFpaRequestBody(
    numAbonnement: string,
    nombreEcheance: number,
    datePremiereEcheance: string,
    listeFactureId: Array<string>,
    montant: number
  ): GenerationSimulerRequest {
    return {
      informationsFacilitePaiementDto: {
        NumeroAbonnement: numAbonnement,
        NombreEcheance: nombreEcheance,
        PremiereEcheance: datePremiereEcheance,
        ListeFactureId: listeFactureId,
        Montant: montant
      }
    };
  }
  static GenerateModifierEtatFpaRequestBody(
    numeroFacilitePaiementEcheancier: string,
    nouvelEtat: string
  ): ModifierEtatRequest {
    return {
      numeroFacilitePaiementEcheancier: numeroFacilitePaiementEcheancier,
      nouvelEtat: nouvelEtat
    };
  }
  static CreationRemboursementRequestBody(
    numeroAbonnement: string,
    codeTypeMotifRemboursement: string,
    commentaire: string
  ): CreationRemboursementRequest {
    return {
      NumeroAbonnement: numeroAbonnement,
      CodeTypeMotifRemboursement: codeTypeMotifRemboursement,
      Commentaire: commentaire
    };
  }
  static GenerateSimulerPrelevementRequestBody(
    numeroAbonnement: string,
    nombreEcheance: number,
    premiereEcheance: string,
    montantReference: number,
    montantMensualise: number
  ): SimulerMensualitesRequest {
    return {
      informationsPrelevementDto: {
        NumeroAbonnement: numeroAbonnement,
        NombreEcheance: nombreEcheance,
        PremiereEcheance: premiereEcheance,
        MontantReference: montantReference,
        MontantMensualise: montantMensualise
      }
    };
  }

  static GenerateAvoirRequestBody(
    codeMotifAvoir: string,
    isAvoirEditable: boolean,
    justification1: string,
    justification2: string,
    justification3: string,
    justification4: string,
    numeroAbonnement: string,
    numeroFacture: number,
    paragraphe1: string,
    paragraphe2: string,
    paragraphe3: string,
    paragraphe4: string,
    paragraphe5: string,
    typeAvoir: number,
    detailsFacture: AvoirPartielCalculVolume[] | AvoirPartielCalculMontant[],
    volumeAssainissement: number,
    volumeEau: number
  ): CreerAvoirPartielVolumeRequest | CreerAvoirPartielMontantRequest {
    return {
      CodeMotifAvoir: codeMotifAvoir,
      IsAvoirEditable: isAvoirEditable,
      Justification1: justification1,
      Justification2: justification2,
      Justification3: justification3,
      Justification4: justification4,
      NumeroAbonnement: numeroAbonnement,
      NumeroFacture: numeroFacture,
      Paragraphe1: paragraphe1,
      Paragraphe2: paragraphe2,
      Paragraphe3: paragraphe3,
      Paragraphe4: paragraphe4,
      Paragraphe5: paragraphe5,
      TypeAvoir: typeAvoir,
      DetailsFacture: detailsFacture,
      VolumeAssainissement: volumeAssainissement,
      VolumeEau: volumeEau
    };
  }
  static GenerateAvoirTotalRequestBody(
    numeroAbonnement: string,
    numeroFacture: number,
    typeAvoir: number,
    codeMotifAvoir: string,
    justification1: string,
    justification2: string,
    justification3: string,
    justification4: string,
    paragraphe1: string,
    paragraphe2: string,
    paragraphe3: string,
    paragraphe4: string,
    paragraphe5: string,
    isAvoirEditable: boolean
  ): CreerAvoirTotalRequest {
    return {
      NumeroAbonnement: numeroAbonnement,
      NumeroFacture: numeroFacture,
      TypeAvoir: typeAvoir,
      CodeMotifAvoir: codeMotifAvoir,
      Justification1: justification1,
      Justification2: justification2,
      Justification3: justification3,
      Justification4: justification4,
      Paragraphe1: paragraphe1,
      Paragraphe2: paragraphe2,
      Paragraphe3: paragraphe3,
      Paragraphe4: paragraphe4,
      Paragraphe5: paragraphe5,
      IsAvoirEditable: isAvoirEditable
    };
  }

  static FonctionWebRequestBody(
    matricule: string,
    codesFonctionsWeb: string[]
  ): FonctionWebRequest {
    return {
      matricule: matricule,
      codesFonctionsWeb: codesFonctionsWeb
    };
  }

  static GenerateChangerMotDePasseRequestBody(
    login: string,
    ancienMotDePasse: string,
    nouveauMotDePasse: string
  ): ChangerMotDePasseRequest {
    return {
      login: login,
      ancienMotDePasse: ancienMotDePasse,
      nouveauMotDePasse: nouveauMotDePasse
    };
  }

  static CalculAvoirPartielMontantRequestBody(
    numeroFacture: number,
    montant: string
  ): CalculAvoirPartielMontantRequest {
    return {
      numeroFacture: numeroFacture,
      montant: montant
    };
  }

  static CalculAvoirPartielVolumeRequestBody(
    numeroFacture: number,
    informationsCalculAvoirPartielVolume: InformationsCalculAvoirPartielVolume
  ): CalculAvoirPartielVolumesRequest {
    return {
      numeroFacture: numeroFacture,
      InformationsCalculAvoirPartielVolume: informationsCalculAvoirPartielVolume
    };
  }

  static FonctionWebPostRequestBody(
    matriculeSource: string,
    matriculeCible: string
  ): FonctionWebPostRequest {
    return {
      matriculeSource: matriculeSource,
      matriculeCible: matriculeCible
    };
  }

  //#region Mutation
  static GenerateInformationsAbonnementForProcessMDtoRequestBody(
    numeroAbonnement: string,
    codeActivite: string,
    civiliteTitulaireConcession: CiviliteMDto,
    nomTitulaireConcession: string,
    adresse: AdresseMDto,
    dateNaissance: DateStringNull,
    numeroSiret: string,
    codeAPE: string,
    observation: string,
    commentaire: string,
    nombreOccupants: number | null,
    identifiantTiers: string,
    nomProprietaire: string
  ): InformationsAbonnementForProcessMDto {
    return {
      NumeroAbonnement: numeroAbonnement,
      CodeActivite: codeActivite,
      CiviliteTitulaireConcession: civiliteTitulaireConcession,
      NomTitulaireConcession: nomTitulaireConcession,
      Adresse: adresse,
      DateNaissance: dateNaissance,
      NumeroSiret: numeroSiret,
      CodeAPE: codeAPE,
      Observation: observation,
      Commentaire: commentaire,
      NombreOccupants: nombreOccupants,
      IdentifiantTiers: identifiantTiers,
      NomProprietaire: nomProprietaire
    };
  }

  static GenerateInformationsAbonnementForProcessDtoRequestBody(
    numeroAbonnement: string,
    codeActivite: string,
    civiliteTitulaireConcession: CiviliteMDto,
    nomTitulaireConcession: string,
    adresse: AdresseDbDto,
    dateNaissance: DateStringNull,
    numeroSiret: string,
    codeAPE: string,
    observation: string,
    commentaire: string,
    nombreOccupants: number | null,
    identifiantTiers: string,
    nomProprietaire: string
  ): InformationsAbonnementForProcessDto {
    return {
      NumeroAbonnement: numeroAbonnement,
      CodeActivite: codeActivite,
      CiviliteTitulaireConcession: civiliteTitulaireConcession,
      NomTitulaireConcession: nomTitulaireConcession,
      Adresse: adresse,
      DateNaissance: dateNaissance,
      NumeroSiret: numeroSiret,
      CodeAPE: codeAPE,
      Observation: observation,
      Commentaire: commentaire,
      NombreOccupants: nombreOccupants,
      IdentifiantTiers: identifiantTiers,
      NomProprietaire: nomProprietaire
    };
  }

  static GenerateCiviliteDtoRequestBody(code: string | null): CiviliteMDto {
    return {
      Code: code
    };
  }

  static GenerateAdresseMDtoRequestBody(
    numeroDansLaRue: number | null,
    complementNumeroDansLaRue: string,
    complementAdresse: string,
    etage: string,
    rue: RueMDto
  ): AdresseMDto {
    return {
      NumeroDansLaRue: numeroDansLaRue,
      ComplementNumeroDansLaRue: complementNumeroDansLaRue,
      ComplementAdresse: complementAdresse,
      Etage: etage,
      Rue: rue
    };
  }

  static GenerateRueDtoRequestBody(
    codeCommune: number,
    numero: number | undefined,
    nom: string,
    codeTypeVoie: string
  ): RueMDto {
    return {
      CodeCommune: codeCommune,
      Numero: numero ? numero : undefined,
      Nom: nom,
      CodeTypeVoie: codeTypeVoie
    };
  }

  static GenerateInformationsClientForProcessAbonnementMDtoRequestBody(
    codeCivilite: string,
    raisonSociale: string,
    nom: string,
    Prenom: string,
    numeroTelephone1: string | null,
    numeroTelephone2: string | null,
    numeroTelephoneEtranger: string,
    email: string,
    dateNaissance: DateStringNull,
    numeroSiret: string,
    codeApe: string,
    numeroClient: string,
    identifiantTiers: string,
    indicateurPliNonDistribue: string,
    indicateurFactureBraille: boolean,
    codePays: string,
    complementAdresse: string,
    adresse: string,
    ville: string,
    codePostal: string,
    bureauDistributeur: string,
    libellePays: string,
    etatVerificationAdresse: string
  ): InformationsClientForProcessAbonnementMDto {
    return {
      CodeCivilite: codeCivilite,
      RaisonSociale: raisonSociale,
      Nom: nom,
      Prenom: Prenom,
      NumeroTelephone1: numeroTelephone1,
      NumeroTelephone2: numeroTelephone2,
      NumeroTelephoneEtranger: numeroTelephoneEtranger,
      Email: email,
      DateNaissance: dateNaissance,
      NumeroSiret: numeroSiret,
      CodeApe: codeApe,
      NumeroClient: numeroClient,
      IdentifiantTiers: identifiantTiers,
      IndicateurPliNonDistribue: indicateurPliNonDistribue,
      IndicateurFactureBraille: indicateurFactureBraille,
      CodePays: codePays,
      ComplementAdresse: complementAdresse,
      Adresse: adresse,
      Ville: ville,
      CodePostal: codePostal,
      BureauDistributeur: bureauDistributeur,
      LibellePays: libellePays,
      EtatVerificationAdresse: etatVerificationAdresse
    };
  }
  //#endregion
  static GenerateInformationsPaiementMDtoRequestBody(
    numeroAbonnement: string,
    indicateurPaiementSur: boolean | null,
    codeModePaiement: string,
    dateValiditeCB: DateStringNull,
    jourDePrelevement: number | null,
    referenceBancaire: ReferenceBancaireMDto,
    hasHistoriquePaiement: boolean,
    nomSociete: string,
    codeIcsSociete: string
  ): InformationsPaiementMDto {
    return {
      NumeroAbonnement: numeroAbonnement,
      IndicateurPaiementSur: indicateurPaiementSur,
      CodeModePaiement: codeModePaiement,
      DateValiditeCB: dateValiditeCB,
      JourDePrelevement: jourDePrelevement,
      ReferenceBancaire: referenceBancaire,
      HasHistoriquePaiement: hasHistoriquePaiement,
      NomSociete: nomSociete,
      CodeIcsSociete: codeIcsSociete
    };
  }

  static GenerateReferenceBancaireMDtoRequestBody(
    numeroAbonnement: string,
    titulaire: string,
    domiciliation: string,
    codeIban: string,
    codeBic: string,
    numeroRum: string,
    dateRum: DateStringNull,
    canalAcquisitionSepa: CanalAcquisitionSepa | null
  ): ReferenceBancaireMDto {
    return {
      NumeroAbonnement: numeroAbonnement,
      Titulaire: titulaire,
      Domiciliation: domiciliation,
      CodeIban: codeIban,
      CodeBic: codeBic,
      NumeroRum: numeroRum,
      DateRum: dateRum,
      CanalAcquisitionSepa: canalAcquisitionSepa
    };
  }

  static GenerateInformationsProprietaireMDtoRequestBody(
    numero: string,
    codeCivilite: string,
    nomProprietaire: string,
    numeroTelephoneProprietaire: string,
    adressePostaleProprietaire: string,
    villeProprietaire: string,
    codePostalProprietaire: string,
    adresseMailProprietaire: string
  ): InformationsProprietaireMDto {
    return {
      Numero: numero,
      CodeCivilite: codeCivilite,
      NomProprietaire: nomProprietaire,
      NumeroTelephoneProprietaire: numeroTelephoneProprietaire,
      AdressePostaleProprietaire: adressePostaleProprietaire,
      VilleProprietaire: villeProprietaire,
      CodePostalProprietaire: codePostalProprietaire,
      AdresseMailProprietaire: adresseMailProprietaire
    };
  }

  static GenerateInformationsSegmentationMDtoRequestBody(
    numeroAbonnement: string,
    codeActivite: string,
    codeCategorieAbonnement: string,
    codeSousCategorieAbonnement: string,
    codeParticulariteAbonnement: string,
    codeSensibiliteAbonnement: string,
    idTypeHabitation: number | null,
    codeTypeAbonnementARisque: string,
    codeSociete: string,
    codeQualitePaiement: string
  ): InformationsSegmentationMDto {
    return {
      NumeroAbonnement: numeroAbonnement,
      CodeActivite: codeActivite,
      CodeCategorieAbonnement: codeCategorieAbonnement,
      CodeSousCategorieAbonnement: codeSousCategorieAbonnement,
      CodeParticulariteAbonnement: codeParticulariteAbonnement,
      CodeSensibiliteAbonnement: codeSensibiliteAbonnement,
      IdTypeHabitation: idTypeHabitation,
      CodeTypeAbonnementARisque: codeTypeAbonnementARisque,
      CodeSociete: codeSociete,
      CodeQualitePaiement: codeQualitePaiement
    };
  }

  static GenerateInformationsFactureEntrantForProcessAbonnementMDtoRequestBody(
    indicateurFraisAccesService: boolean,
    nbMois: number | null,
    nbJours: number | null,
    nombrePrimesFixesOuVolumeSouscrit: number | null,
    dateEffet: DateStringNull,
    codeDiametreBranchement: number | null
  ): InformationsFactureEntrantForProcessAbonnementMDto {
    return {
      IndicateurFraisAccesService: indicateurFraisAccesService,
      NbMois: nbMois,
      NbJours: nbJours,
      NombrePrimesFixesOuVolumeSouscrit: nombrePrimesFixesOuVolumeSouscrit,
      DateEffet: dateEffet,
      CodeDiametreBranchement: codeDiametreBranchement
    };
  }

  static GenerateMutationRequestBody(
    numeroAbonnementAMuter: string,
    index: number,
    type: string,
    dateMutation: DateStringNull,
    informationsAbonnement: InformationsAbonnementForProcessMDto,
    informationsClient: InformationsClientForProcessAbonnementMDto,
    informationsSegmentation: InformationsSegmentationMDto,
    informationsModePaiement: InformationsPaiementMDto,
    informationsProprietaire: InformationsProprietaireMDto,
    informationsFactureEntrant: InformationsFactureEntrantForProcessAbonnementMDto
  ): MutationRequest {
    return {
      NumeroAbonnementAMuter: numeroAbonnementAMuter,
      Index: index,
      Type: type,
      DateMutation: dateMutation,
      InformationsAbonnement: informationsAbonnement,
      InformationsClient: informationsClient,
      InformationsSegmentation: informationsSegmentation,
      InformationsModePaiement: informationsModePaiement,
      InformationsProprietaire: informationsProprietaire,
      InformationsFactureEntrant: informationsFactureEntrant
    };
  }

  static GenerateMiseEnServiceRequestBody(
    informationsAbonnement: InformationsAbonnementForProcessMDto,
    informationsClient: InformationsClientForProcessAbonnementMDto,
    informationsModePaiement: InformationsPaiementMDto,
    informationsFactureEntrant: InformationsFactureEntrantForProcessAbonnementMDto
  ): MiseEnServiceRequest {
    return {
      InformationsAbonnement: informationsAbonnement,
      InformationsClient: informationsClient,
      InformationsModePaiement: informationsModePaiement,
      InformationsFactureEntrant: informationsFactureEntrant
    };
  }
  //#endregion

  static TraitementImmediatRequestBody(
    numeroAbonnement: string,
    html: string,
    commentaire: string,
    codeKitCourrier: string,
    codeProcedure: string,
    IdsCourrier: number[],
    IdsDocument: string[],
    IdsFacture: number[],
    CodeTypeFlux: string // TODO cette clé est dans la recette (payload) mais pas dans swagger et la requête passe sans. A creuser
  ): TraitementImmediatRequest {
    return {
      numeroAbonnement: numeroAbonnement,
      html: html,
      commentaire: commentaire,
      codeKitCourrier: codeKitCourrier,
      codeProcedure: codeProcedure,
      IdsCourrier: IdsCourrier,
      IdsDocument: IdsDocument,
      IdsFacture: IdsFacture,
      CodeTypeFlux: CodeTypeFlux // TODO cette clé est dans la recette (payload) mais pas dans swagger et la requête passe sans. A creuser
    };
  }

  static PreviewHtmlToPdfRequestBody(fichierHtml: string): PreviewHtmlToPdfRequest {
    return {
      fichierHtml: fichierHtml
    };
  }

  static CreerEtTransfererRequestBody(
    NumeroAbonnement: string,
    CodeKitCourrier: string,
    CodeMotif: string,
    Commentaire: string,
    DateReception: string
  ): CreerEtTransfererRequest {
    return {
      NumeroAbonnement: NumeroAbonnement,
      CodeKitCourrier: CodeKitCourrier,
      CodeMotif: CodeMotif,
      Commentaire: Commentaire,
      DateReception: DateReception
    };
  }

  static AnnulerCourrierRequestBody(identifiant: number): AnnulerCourrierRequest {
    return {
      identifiant: identifiant
    };
  }

  static GetBaseDocumentaireByAbonnementRequestBody(
    numeroAbonnement: string,
    codesSujet: string[]
  ): BaseDocumentaireRequest {
    return {
      numeroAbonnement: numeroAbonnement,
      codesSujet: codesSujet
    };
  }

  static GenerateCloturerInterventionRequestBody(
    numeroIntervention: string,
    commentaireExecution: string,
    codeMotifInterventionExecution: string,
    codeActionInterventionExecution: string,
    dateExecution?: string,
    HeureExecution?: string,
    creneauxInterventions?: Creneau[],
    indicateurHeureSup?: string,
    indicateurAstreinte?: string,
    matriculeRessourceExecution?: string,
    codeMotifInfructueux?: string,
    indicateurClotureeSansObjet?: string,
    interventionInfosClotureDto?: {
      NumeroCompteur: string;
      IndexCompteur: number;
      EtatAppareil: string;
      MotifPoseDepose: string;
      RedevanceAssainissement: string;
      TypeReleve: string;
    }
  ): CloturerInterventionRequest {
    return {
      numeroIntervention: numeroIntervention,
      CommentaireExecution: commentaireExecution,
      DateExecution: dateExecution,
      DateExecutionHeure: HeureExecution,
      CreneauxInterventions: creneauxInterventions,
      IndicateurHeureSup: indicateurHeureSup,
      IndicateurAstreinte: indicateurAstreinte,
      CodeMotifInterventionExecution: codeMotifInterventionExecution,
      CodeActionInterventionExecution: codeActionInterventionExecution,
      MatriculeRessourceExecution: matriculeRessourceExecution,
      CodeMotifInfructueux: codeMotifInfructueux,
      IndicateurClotureeSansObjet: indicateurClotureeSansObjet,
      InterventionInfosClotureDto: interventionInfosClotureDto
    };
  }
}
