import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InfosTechniquesState } from './infos-techniques.state';

export const selectInfosTechniques = createFeatureSelector<InfosTechniquesState>('infosTechniques');

export const selectIsRadioReleve = createSelector(
  selectInfosTechniques,
  (state: InfosTechniquesState) => state.isRadioReleve
);

export const selectIsTeleReleve = createSelector(
  selectInfosTechniques,
  (state: InfosTechniquesState) => state.isTeleReleve
);
