import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from 'src/app/shared/utils/dateUnixUtils';

@Pipe({
  name: 'dateUnixToDate'
})
export class DateUnixToDatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return formatDate(value);
  }
}
