import { Action, createReducer, on } from '@ngrx/store';
import { init, updateFacture } from './creation-facture-manuelle.actions';
import { initialState, FactureState } from './creation-facture-manuelle.state';

const factureReducer = createReducer(
  initialState,
  on(updateFacture, (state, action: ReturnType<typeof updateFacture>) => ({
    ...state,
    ...action.payload
  })),
  on(init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: FactureState | undefined, action: Action) {
  return factureReducer(state, action);
}
