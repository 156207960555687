<div class="lg:mx-28 md:mx-20" [formGroup]="clientForm">
  <div class="grid grid-cols-2 gap-20 mx-auto">
    <div class="grid grid-cols-6 gap-5">
      <div class="col-span-3">
        <label for="civilite" class="label-input">Civilité</label>
        <p-dropdown
          [options]="civilites"
          formControlName="civilite"
          [disabled]="!edit"
          [filter]="true"
          class="w-full"
          optionLabel="Value"
          [placeholder]="'Sélectionnez...'">
        </p-dropdown>
      </div>
      <div class="col-span-3">
        <label for="nom" class="label-input">Nom de concession</label>
        <input
          type="text"
          pInputText
          class="app-input w-full"
          [readOnly]="!edit"
          [ngClass]="{ 'input-readOnly': !edit }"
          formControlName="nom"
          [maxlength]="35" />
      </div>
      <div class="col-span-2">
        <label for="numeroDansLaRue" class="label-input">N° rue</label>
        <input
          type="text"
          pInputText
          pKeyFilter="int"
          class="app-input w-full"
          [readOnly]="!edit"
          [ngClass]="{ 'input-readOnly': !edit }"
          formControlName="numeroDansLaRue"
          [maxlength]="4" />
      </div>
      <div class="col-span-2">
        <label for="cpl" class="label-input">Cpl</label>
        <input
          type="text"
          pInputText
          pKeyFilter="alpha"
          class="app-input w-full"
          [readOnly]="!edit"
          [ngClass]="{ 'input-readOnly': !edit }"
          formControlName="cpl"
          [maxlength]="1" />
      </div>
      <div class="col-span-2">
        <label for="etage" class="label-input">Etage</label>
        <input
          type="text"
          pInputText
          class="app-input w-full"
          [readOnly]="!edit"
          [ngClass]="{ 'input-readOnly': !edit }"
          formControlName="etage"
          [maxlength]="3" />
      </div>
      <div class="col-span-3">
        <label for="telephone1" class="label-input">N° téléphone 1</label>
        <input
          type="text"
          pInputText
          class="app-input w-full"
          [readOnly]="!edit"
          [ngClass]="{ 'input-readOnly': !edit, 'border-error': telephone1?.invalid }"
          formControlName="telephone1"
          [maxlength]="10" />
        <div
          *ngIf="telephone1?.invalid && (telephone1?.dirty || telephone1?.touched)"
          class="error">
          <div *ngIf="telephone1?.errors?.['pattern']">Format du téléphone incorrect</div>
        </div>
      </div>
      <div class="col-span-3">
        <label for="telephone2" class="label-input">N° téléphone 2</label>
        <input
          type="text"
          pInputText
          class="app-input w-full"
          [readOnly]="!edit"
          [ngClass]="{ 'input-readOnly': !edit, 'border-error': telephone2?.invalid }"
          formControlName="telephone2"
          [maxlength]="10" />
        <div
          *ngIf="telephone2?.invalid && (telephone2?.dirty || telephone2?.touched)"
          class="error">
          <div *ngIf="telephone2?.errors?.['pattern']">Format du téléphone incorrect</div>
        </div>
      </div>
      <div class="col-span-3">
        <label for="email" class="label-input">Email</label>
        <input
          type="text"
          pInputText
          class="app-input w-full"
          [readOnly]="!edit"
          [ngClass]="{
            'input-readOnly': !edit,
            'border-error': email?.invalid
          }"
          formControlName="email"
          [maxlength]="100" />
        <div *ngIf="email?.invalid && (email?.dirty || email?.touched)" class="text-error-500">
          <div *ngIf="email?.errors?.['pattern']">
            {{ resource.GlobalTexts.Generic.BadEmailFormat }}
          </div>
        </div>
      </div>
      <div class="col-span-3">
        <label for="dateNaissance" class="label-input">Date de naissance</label>
        <p-calendar
          formControlName="dateNaissance"
          [ngClass]="{ 'input-readOnly': !edit }"
          class="w-full block"></p-calendar>
      </div>
      <div class="col-span-3">
        <label for="numeroSIRET" class="label-input">N° SIRET</label>
        <input
          type="text"
          pInputText
          class="app-input w-full"
          [readOnly]="!edit"
          [ngClass]="{ 'input-readOnly': !edit }"
          formControlName="numeroSIRET"
          [maxlength]="14" />
      </div>
      <div class="col-span-3">
        <label for="codeAPE" class="label-input">Code APE</label>
        <input
          type="text"
          pInputText
          class="app-input w-full"
          [readOnly]="!edit"
          [ngClass]="{ 'input-readOnly': !edit }"
          formControlName="codeAPE"
          [maxlength]="5" />
      </div>
    </div>
    <div>
      <!-- ADRESSE + TIMBRE -->
      <div class="grid grid-cols-3 gap-5">
        <div>
          <label for="civiliteClient" class="label-input">Civilité</label>
          <p-dropdown
            [options]="civilites"
            formControlName="civiliteClient"
            [filter]="true"
            class="w-full"
            [disabled]="!edit"
            optionLabel="Value"
            [placeholder]="'Sélectionnez...'">
          </p-dropdown>
        </div>
        <div [ngClass]="{ 'col-span-2': !prenomRequis }">
          <label for="raisonSociale" class="label-input">{{
            prenomRequis ? 'Nom' : 'Raison Sociale'
          }}</label>
          <input
            type="text"
            pInputText
            class="app-input w-full"
            [readOnly]="!edit"
            [ngClass]="{ 'input-readOnly': !edit }"
            formControlName="raisonSociale"
            [maxlength]="35" />
        </div>
        <div *ngIf="prenomRequis">
          <label for="prenomClient" class="label-input">Prénom</label>
          <input
            type="text"
            pInputText
            class="app-input w-full"
            [readOnly]="!edit"
            [ngClass]="{ 'input-readOnly': !edit }"
            formControlName="prenomClient"
            [maxlength]="35" />
        </div>
      </div>
      <div class="col-span-2 flex flex-col gap-2 mt-5">
        <h6 class="uppercase mb-1">{{ resource.resilier.adresseFacturation }}</h6>

        <div class="leading-6 flex flex-wrap w-3/4 relative h-40 smi-widget-adresse mb-8">
          <span class="smi-timbre">
            <fa-icon [icon]="faImage" size="2xl"></fa-icon>
          </span>
          <div class="absolute left-12 right-12 bottom-6">
            <p class="mb-2">{{ client.adresse }}</p>
            <p class="mb-2">{{ client.ville }}</p>
            <div class="smi-adr-cpville">
              <span class="smi-adr-cp mb-2">
                <span>{{ client.codePostal }}</span>
              </span>
              <div class="smi-adr-city">
                <span>{{ client.bureauDistributeur }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="flex flex-1 items-center justify-end space-x-3 mx-6 px-6 mt-10">
  <button class="button-cancel flex" (click)="returnPreviousForm()">
    <img class="py-0.5 pr-1" [src]="resource.svgPath.west" alt="westSVG" />Précédent
  </button>
  <button class="button-submit flex" (click)="submitForm()" [disabled]="!clientForm.valid">
    {{ resource.form.next }}<img class="py-0.5 pl-1" [src]="resource.svgPath.east" alt="eastSvg" />
  </button>
</div>
