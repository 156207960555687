import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AbonnementState } from './abonnement.state';

export const selectAbonnement = createFeatureSelector<AbonnementState>('abonnement');

export const selectNumeroAbonnement = createSelector(
  selectAbonnement,
  (state: AbonnementState) => state.numeroAbonnement
);

export const selectNumeroClient = createSelector(
  selectAbonnement,
  (state: AbonnementState) => state.numeroClient
);

export const selectNumeroPointDesserte = createSelector(
  selectAbonnement,
  (state: AbonnementState) => state.numeroPointDesserte
);

export const selectEtatAbonnement = createSelector(
  selectAbonnement,
  (state: AbonnementState) => state.etatAbonnement
);

export const selectNumeroAppareil = createSelector(
  selectAbonnement,
  (state: AbonnementState) => state.numeroAppareil
);

export const selectListInterventionsUpdate = createSelector(
  selectAbonnement,
  (state: AbonnementState) => state.listInterventionsUpdate
);

export const selectListCourriersUpdate = createSelector(
  selectAbonnement,
  (state: AbonnementState) => state.listCourriersUpdate
);
