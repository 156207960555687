import { isUndefined } from 'is-what';
import { IRight } from '../../interface/authorisations/right.interface';
import { IToTreeNode } from '../../interface/to-tree-node.interface';
import { WaterpTreeNode } from '../../interface/waterp-tree-node.interface';

export class Authorisation implements IToTreeNode {
  private name: string;
  private authorisationList?: Map<string, Authorisation[]>;
  private tooltip?: string;
  private leaf: boolean;
  private disabled: boolean;
  private code: string;

  /**
   * Permet d'instancier une autorisation issue du serveur en une version
   * compréhensible par l'interface
   * @param options Suite des paramètres à fournir pour la construction de l'objet
   * @param options.authorisation L'objet issue du serveur dont nous nous serviront pour créer l'objet
   * @param options.habilitationName Nom de remplacement de la combinaison Code + Libellé de l'autorisation
   * @param options.list Nom de la liste où l'autorisation doit être assignée
   * @param options.disabled Si nous souhaitons la prendre en compte mais sans la rendre sélectionnable
   */
  constructor(options?: {
    authorisation: IRight;
    habilitationName?: string;
    list?: string;
    code?: string;
    disabled?: boolean;
  }) {
    if (options) {
      let libelleIHM: string =
        Object.entries(options.authorisation).find(
          (property: [string, any]) => property[0] === 'IHM'
        )?.[1].Libelle ?? '';

      this.name =
        options.habilitationName ??
        options.authorisation.Code +
          (options.authorisation.Libelle ? '~' + options.authorisation.Libelle : '') +
          (libelleIHM ? '~' + libelleIHM : '');
      this.code = options.code ?? options.authorisation.Code;
      this.tooltip =
        options.authorisation.Description ??
        options.authorisation.Commentaire ??
        options.authorisation.Libelle;
      this.leaf = !options.habilitationName && !options.list;
      this.disabled = options.disabled ?? false;
      if (options.list) {
        this.authorisationList = new Map<string, Authorisation[]>().set(
          options.list,
          new Array<Authorisation>(new Authorisation({ authorisation: options.authorisation }))
        );
      }
    } else {
      this.name = '';
      this.authorisationList = undefined;
      this.tooltip = undefined;
      this.leaf = false;
      this.disabled = true;
      this.code = '';
    }
  }

  //#region Accesseurs
  public getName(): string {
    return this.name;
  }

  public setName(name: string): void {
    this.name = name;
  }

  public getAuthorisationList(): Map<string, Authorisation[]> | undefined {
    return this.authorisationList;
  }

  public setAuthorisationList(authorisationList: Map<string, Authorisation[]>): void {
    this.authorisationList = authorisationList;
  }

  public getTooltip(): string {
    return this.tooltip ?? '';
  }

  public setTooltip(tooltip: string): void {
    this.tooltip = tooltip;
  }

  public isLeaf(): boolean {
    return this.leaf;
  }

  public setLeaf(isLeaf: boolean): void {
    this.leaf = isLeaf;
  }

  public isDisabled(): boolean {
    return this.disabled;
  }

  public setDisabled(disabled: boolean): void {
    this.disabled = disabled;
  }
  public getCode(): string {
    return this.code;
  }
  public setCode(code: string): void {
    this.code = code;
  }
  //#endregion

  /**
   * Permet d'obtenir la version de cet objet sans sa liste d'autorisation, permettant
   * d'assigner l'objet uniquement pour sa référence, sinon le tree node se retrouve
   * faussé dans sa sélection d'enfant unitaire
   * @returns "This" sans ses enfants
   */
  private withoutList(): Authorisation {
    let onlyThis: Authorisation = new Authorisation();
    onlyThis.name = this.name;
    onlyThis.tooltip = this.tooltip;
    onlyThis.leaf = this.leaf;
    onlyThis.disabled = this.disabled;
    onlyThis.code = this.code;
    return onlyThis;
  }

  /**
   * Permet de convertir cet objet en TreeNode
   * @returns La version TreeNode de cet objet
   */
  public toTreeNode(
    keyTable: number[],
    parent: WaterpTreeNode<Authorisation>,
    parentLevel: number
  ): WaterpTreeNode<Authorisation> {
    let branch: WaterpTreeNode<Authorisation> = {
      key: keyTable.join('-'),
      label: this.name,
      type: 'N-' + parentLevel,
      expanded: false,
      leaf: this.leaf,
      parent: parent,
      data: this.withoutList()
    };

    if (this.tooltip) {
      branch.tooltip = {
        text: this.tooltip,
        delay: 500,
        disabled: isUndefined(this.tooltip),
        appendTo: 'body'
      };
    }

    branch.expanded = !isUndefined(this.authorisationList) && this.authorisationList.size > 0;
    if (!isUndefined(this.authorisationList)) {
      let level: number = parentLevel + 1;
      let childLevel: number = level + 1;

      this.authorisationList.forEach((values: Authorisation[], key: string) => {
        if (keyTable[level] >= 0) {
          keyTable[level]++;
        } else {
          keyTable.push(0);
        }
        keyTable.splice(childLevel);

        let keyTree: WaterpTreeNode<Authorisation> = {
          key: keyTable.join('-'),
          label: key,
          parent: branch,
          expanded: true,
          leaf: false,
          type: 'N-' + level
        };
        keyTree.children = values.map((authorisation: Authorisation, index: number) => {
          keyTable[childLevel] = index;
          return authorisation.toTreeNode(keyTable, keyTree, childLevel);
        });

        branch.children
          ? branch.children.push(keyTree)
          : (branch.children = new Array<WaterpTreeNode<Authorisation>>(keyTree));
      });
    }

    return branch;
  }

  /**
   * Permet d'ajouter un droit dans la liste d'autorisations de cet objet indexé par la liste fournie
   * @param params Formulation du droit et de la liste où le trouver
   */
  public set(params: { this: Authorisation; in: string }): void {
    if (this.authorisationList) {
      this.authorisationList?.has(params.in)
        ? this.authorisationList
            .get(params.in)
            ?.find((toAdd: Authorisation) => toAdd.getName() === params.this.getName()) ??
          this.authorisationList.get(params.in)?.push(params.this)
        : this.authorisationList.set(params.in, new Array<Authorisation>(params.this));
    } else {
      this.authorisationList = new Map<string, Authorisation[]>();
      this.authorisationList.set(params.in, new Array<Authorisation>(params.this));
    }
  }

  /**
   * Permet de supprimer un droit contenue dans la liste d'autorisations de cet objet
   * @param params Formulation du droit et de la liste où le trouver
   */
  public remove(params: { this: Authorisation; in: string }): void {
    if (this.has(params.this)) {
      if (this.authorisationList?.has(params.in)) {
        let length: number = this.authorisationList.get(params.in)?.length ?? 0;
        let index: number =
          this.authorisationList
            .get(params.in)
            ?.findIndex(
              (toRemove: Authorisation) => toRemove.getName() === params.this.getName()
            ) ?? -1;

        if (index > -1) {
          length > 1
            ? this.authorisationList.get(params.in)?.splice(index, 1)
            : this.authorisationList.delete(params.in);
        }
      }
    }
  }

  /**
   * Permet de vérifier si l'autorisation à examiner est dans l'une de nos listes de droits
   * ? Ne remonte pas dans quelle liste il a été trouvé
   * @param right Authorisation à examiner
   * @returns Vrai si c'est le cas, Faux sinon
   */
  public has(right: Authorisation): boolean {
    let hasRight: boolean = false;
    if (this.authorisationList) {
      this.authorisationList.forEach(
        (values: Authorisation[]) =>
          (hasRight =
            hasRight ||
            !isUndefined(
              values.find(
                (subAuthorisation: Authorisation) => subAuthorisation.getName() === right.getName()
              )
            ))
      );
    }
    return hasRight;
  }

  /**
   * Permet de trier les listes contenues dans l'ordre souhaité
   * @param ascend Trie dans l'ordre croissant/alphabétique si Vrai
   */
  public sort(ascend: boolean = true): void {
    this.authorisationList?.forEach((values: Authorisation[]) =>
      values.sort((a: Authorisation, b: Authorisation) =>
        ascend ? a.getName().localeCompare(b.getName()) : b.getName().localeCompare(a.getName())
      )
    );
  }
}
