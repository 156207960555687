<div class="lg:mx-28 md:mx-20 space-y-5" [formGroup]="referenceBancaireForm">
  <div class="grid grid-cols-4 gap-5 max-w-[700px] mx-auto">
    <div class="col-span-2">
      <div class="flex-col">
        <label for="modePaiement" class="label-input">Mode de paiement</label>
        <p-dropdown
          [options]="modePaiements"
          formControlName="modePaiement"
          class="w-full"
          optionLabel="Value"
          [placeholder]="resource.form.phDropDownList">
        </p-dropdown>
        <small
          class="p-error"
          *ngIf="
            modePaiement?.dirty && modePaiement?.invalid && (modePaiement?.errors)!['required']
          "
          >{{ resource.message.errorMandatory }}</small
        >
      </div>
    </div>
    <div
      class="col-span-1"
      *ngIf="(isMensualisationCB || isPrelevementCB) && !isTip && !isTipSansRIB">
      <div class="flex-col">
        <label for="dateFinValiditeCB">Fin de validité de la CB</label>
        <p-calendar formControlName="dateFinValiditeCB"></p-calendar>
      </div>
    </div>
    <div class="col-span-1" *ngIf="(isMensualisation || isMensualisationCB) && !isTipSansRIB">
      <div class="flex-col">
        <label for="jourPrelevement" class="label-input">Jour de prélèvement</label>
        <p-dropdown
          [options]="jourPrelevements"
          formControlName="jourPrelevement"
          optionLabel="Value"
          [placeholder]="resource.form.phDropDownList">
        </p-dropdown>
        <small
          class="p-error"
          *ngIf="
            (submitted || jourPrelevement?.touched) &&
            jourPrelevement?.invalid &&
            (jourPrelevement?.errors)!['required']
          "
          >{{ resource.message.errorMandatory }}</small
        >
      </div>
    </div>
  </div>
  <div
    class="flex-col max-w-[700px] mx-auto space-y-2"
    *ngIf="!isMensualisationCB && !isPrelevementCB && !isTipSansRIB">
    <label for="acquisitionConsentement" class="label-input">Acquisition du consentement</label>
    <div class="grid grid-cols-4 gap-5">
      <div class="flex space-x-3">
        <p-radioButton
          formControlName="acquisitionConsentement"
          value="C"
          inputId="courrier"></p-radioButton>
        <label for="courrier">COURRIER</label>
      </div>
      <div class="flex space-x-3">
        <p-radioButton [disabled]="true" inputId="internetClient"></p-radioButton>
        <label for="internetClient" class="readOnlyLabel">INTERNET CLIENT</label>
      </div>
      <div class="flex space-x-3">
        <p-radioButton
          formControlName="acquisitionConsentement"
          value="T"
          inputId="telephonie"></p-radioButton>
        <label for="telephonie">TELEPHONIE</label>
      </div>
    </div>
    <small
      class="p-error"
      *ngIf="
        (submitted || acquisitionConsentement?.touched) &&
        acquisitionConsentement?.invalid &&
        (acquisitionConsentement?.errors)!['required']
      "
      >{{ resource.message.errorMandatory }}</small
    >
  </div>
  <div
    class="flex-col max-w-[700px] mx-auto"
    *ngIf="!isMensualisationCB && !isPrelevementCB && !isTipSansRIB">
    <label for="titulaire" class="label-input">Titulaire</label>
    <input type="text" class="w-full" pInputText formControlName="titulaire" [maxlength]="24" />
    <small
      class="p-error"
      *ngIf="
        (submitted || titulaire?.touched) && titulaire?.invalid && (titulaire?.errors)!['required']
      "
      >{{ resource.message.errorMandatory }}</small
    >
  </div>
  <div
    class="flex-col max-w-[700px] mx-auto"
    *ngIf="!isMensualisationCB && !isPrelevementCB && !isTipSansRIB">
    <label for="iban" class="label-input">IBAN</label>
    <div class="grid grid-cols-8">
      <p-inputMask
        class="app-input w-full col-span-7"
        [autoClear]="false"
        [unmask]="true"
        slotChar=""
        mask="**** **** **** **** **** **** **** **** **** ****"
        [maxlength]="34"
        [style]="{ 'text-transform': 'uppercase' }"
        formControlName="iban"></p-inputMask>
      <span class="center" style="padding-top: 0.2rem">
        <fa-icon *ngIf="isLoading" [icon]="faSpinner" [spin]="true" size="2xl"></fa-icon>
        <fa-icon
          *ngIf="!isLoading && ibanIsValid && research"
          [icon]="faSquareCheck"
          style="color: #69aa1e"
          size="2xl"></fa-icon>
        <fa-icon
          *ngIf="!isLoading && !ibanIsValid && research"
          [icon]="faSquareXmark"
          style="color: #ff3e38"
          size="2xl"></fa-icon>
      </span>
      <small
        class="p-error col-span-8"
        *ngIf="(submitted || iban?.touched) && iban?.invalid && (iban?.errors)!['required']"
        >{{ resource.message.errorMandatory }}</small
      >
    </div>
  </div>
  <div
    class="grid grid-cols-8 gap-5 max-w-[700px] mx-auto"
    *ngIf="!isMensualisationCB && !isPrelevementCB && !isTipSansRIB">
    <div class="flex-col col-span-3">
      <label for="domiciliation" class="label-input">Domiciliation</label>
      <input type="text" class="app-input w-full" pInputText formControlName="domiciliation" [maxlength]="24" />
      <small
        class="p-error"
        *ngIf="
          (submitted || domiciliation?.touched) &&
          domiciliation?.invalid &&
          (domiciliation?.errors)!['required']
        "
        >{{ resource.message.errorMandatory }}</small
      >
    </div>
    <div class="flex-col col-span-4">
      <label for="bic" class="label-input">BIC</label>
      <input
        type="text"
        class="app-input w-full"
        pInputText
        formControlName="bic"
        [style]="{ 'text-transform': 'uppercase' }"
        [maxlength]="11" />
      <small
        class="p-error"
        *ngIf="(submitted || bic?.touched) && bic?.invalid && (bic?.errors)!['required']"
        >{{ resource.message.errorMandatory }}</small
      >
    </div>
    <span class="center" style="padding-top: 1.4rem; margin-left: -1.1rem">
      <fa-icon *ngIf="isLoading" [icon]="faSpinner" [spin]="true" size="2xl"></fa-icon>
      <fa-icon
        *ngIf="!isLoading && bicIsValid && research"
        [icon]="faSquareCheck"
        style="color: #69aa1e"
        size="2xl"></fa-icon>
      <fa-icon
        *ngIf="!isLoading && !bicIsValid && research"
        [icon]="faSquareXmark"
        style="color: #ff3e38"
        size="2xl"></fa-icon>
    </span>
  </div>
  <div
    class="grid grid-cols-6 gap-5 max-w-[700px] mx-auto"
    *ngIf="!isMensualisationCB && !isPrelevementCB && !isTipSansRIB">
    <div class="flex-col col-span-2">
      <div *ngIf="!isTip">
        <label for="numeroRUM" class="label-input">Numéro RUM</label>
        <input
          type="text"
          class="app-input w-full input-readOnly"
          readonly
          pInputText
          formControlName="numeroRUM"
          [maxlength]="35" />
      </div>
    </div>
    <div class="flex-col col-span-2">
      <div *ngIf="!isTip">
        <label class="label-input">Date RUM</label>
        <input
          type="text"
          class="app-input w-full input-readOnly"
          readonly
          pInputText
          formControlName="dateRUM" />
      </div>
    </div>
    <div class="flex-col col-span-2">
      <button class="button-submit secondary w-full my-5" (click)="cleanInfoBanque()">
        <fa-icon [icon]="faTrash"></fa-icon> Vider coordonnées bancaires
      </button>
    </div>
  </div>
</div>
<div class="flex flex-1 items-center justify-end space-x-3 mx-6 px-6 mt-10">
  <button class="button-cancel flex mr-0">
    <img class="py-0.5 pr-1" [src]="resource.svgPath.close" alt="closeSVG" />Annuler
  </button>
  <div class="flex flex-col separator"></div>
  <button class="button-cancel flex" (click)="returnPreviousForm()">
    <img class="py-0.5 pr-1" [src]="resource.svgPath.west" alt="westSVG" />Précédent
  </button>
  <button class="button-submit flex" (click)="submitForm()">
    Suivant<img class="py-0.5 pl-1" [src]="resource.svgPath.east" alt="eastSvg" />
  </button>
</div>
