<mat-sidenav-container autosize>
  <mat-sidenav #sidenav mode="side" opened="isExpanded">
    <mat-nav-list>
      <app-menu
        *ngIf="webFunctions != undefined"
        [isExpanded]="isExpanded"
        [webFunctions]="webFunctions"></app-menu>
    </mat-nav-list>
  </mat-sidenav>

  <!-- Content - rendered Content for the current router path into the <router-outlet> -->
  <mat-sidenav-content>
    <mat-toolbar class="header flex flex-wrap">
      <div class="header-left-container">
        <button
          pTooltip="Afficher/masquer le menu"
          tooltipPosition="right"
          class="btn-square"
          (click)="updateIsExpandedMenu()">
          <mat-icon class="burger-menu">{{ resource.layout.menu }}</mat-icon>
        </button>
        <h1 class="header-title">{{ title }}</h1>
      </div>
      <div class="flex space-x-2" *ngIf="showShortcut && shortcut">
        <div
          class="circle cursor-pointer"
          pTooltip="Paiement sécurisé"
          tooltipPosition="bottom"
          *ngIf="shortcut?.IndicateurPaiementSur"
          (click)="showPaiementModal()">
          <i class="icon waterpIcons-PaiementSecurise text-white relative z-10"></i>
        </div>
        <div class="steak" *ngIf="shortcut?.IndicateurPaiementSur"></div>
        <div
          class="h-[40px] w-[40px] rounded-full relative z-0 flex items-center justify-center cursor-default"
          pTooltip="Connexion site web"
          tooltipPosition="bottom"
          [ngClass]="shortcut.IndicateurConnexionAEL ? 'bg-primary-800' : 'bg-neutral-300'">
          <span>
            <i class="icon waterpIcons-ClientConnection text-white"></i>
          </span>
        </div>
        <div class="steak"></div>

        <div
          class="circle cursor-pointer"
          pTooltip="Edition de la fiche abonné(e)"
          tooltipPosition="bottom"
          (click)="pdfShow = true; genererFicheAbonnment()">
          <i class="relative z-10"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <g clip-path="url(#clip0_703_21067)">
                <path
                  d="M19 8H5C3.34 8 2 9.34 2 11V15C2 16.1 2.9 17 4 17H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V17H20C21.1 17 22 16.1 22 15V11C22 9.34 20.66 8 19 8ZM15 19H9C8.45 19 8 18.55 8 18V14H16V18C16 18.55 15.55 19 15 19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM17 3H7C6.45 3 6 3.45 6 4V6C6 6.55 6.45 7 7 7H17C17.55 7 18 6.55 18 6V4C18 3.45 17.55 3 17 3Z"
                  fill="#ffffff" />
              </g>
              <defs fill="#ffffff">
                <clipPath id="clip0_703_21067" fill="#ffffff">
                  <rect width="24" height="24" fill="transparent" />
                </clipPath>
              </defs>
            </svg>
          </i>
        </div>
        <div
          class="circle cursor-pointer"
          *ngIf="shortcut?.IndicateurChorus"
          pTooltip="Portail Chorus Pro"
          tooltipPosition="bottom">
          <a
            href="https://portail.chorus-pro.gouv.fr/aife_csm/?id=aife_index"
            target="_blank"
            rel="noopener">
            <i class="icon waterpIcons-AccesChorus text-white relative z-10"></i>
          </a>
        </div>
        <div
          [class]="triggerPDS360.styleClass"
          class="text-white"
          *ngIf="triggerPDS360?.visible"
          [pTooltip]="triggerPDS360.tooltip"
          tooltipPosition="bottom">
          <a [href]="triggerPDS360.url" target="_blank" rel="noopener">
            <span>
              <i [class]="triggerPDS360.icon"></i>
            </span>
          </a>
        </div>
      </div>
      <div class="mr-4">
        <span *ngIf="isAdmin" class="text-xs bg-alert-500 text-white rounded-xl mx-2 px-2 py-0.5"
          >Admin</span
        >
        <!-- This button triggers the overlay and is it's origin -->
        <button
          class="user-menu-btn"
          (click)="isOpen = !isOpen"
          type="button"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin">
          {{ UserName }}
        </button>
        <fa-icon
          [icon]="faUserCircle"
          style="color: #999c9e; font-size: medium; margin-right: 20px"></fa-icon>

        <!-- This template displays the overlay content and is connected to the button -->
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="isOpen"
          cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
          [cdkConnectedOverlayHasBackdrop]="true"
          (backdropClick)="isOpen = false">
          <ul class="profile-list">
            <li>
              <div class="flex flex-row">
                <div class="app-icon-user-content">
                  <fa-icon [icon]="faUserCircle" class="app-icon-user-circle"></fa-icon>
                </div>
                <div class="flex flex-col jc-center mx-3 space-y-1">
                  <span class="app-user-name">{{ UserName }}</span>
                  <span class="app-user-number"
                    >{{ resource.layout.identifiant }} {{ UserMatricule }}</span
                  >
                  <span
                    *ngIf="isAdmin"
                    class="text-xs bg-alert-500 text-white rounded-md text-center px-2 py-0.5 w-full"
                    >Admin</span
                  >
                </div>
              </div>
            </li>
            <li>
              <i class="pi pi-user app-icon-profile"></i>
              <span class="app-profile" (click)="profil()"> {{ resource.layout.profil }}</span>
            </li>
            <li>
              <i class="pi pi-sign-out app-icon-profile"></i>
              <span class="app-profile" (click)="logout()">{{ resource.layout.deconnexion }}</span>
            </li>
          </ul>
        </ng-template>
      </div>
    </mat-toolbar>

    <div class="container mx-auto contents">
      <router-outlet></router-outlet>
    </div>
    <!--<div class="historique">-->
    <div
      class="main-historique"
      *ngIf="
        (cards | async) != undefined && (cards | async) != null && (cards | async)!.length > 0
      ">
      <div
        class="historique-letters h-28 p-1 flex flex-col justify-around items-center"
        (click)="toggleCards()">
        <fa-icon [icon]="faCaretLeft"></fa-icon>
        <span class="turn">Historique</span>
      </div>
      <div class="historique-cards" *ngIf="showCards">
        <div class="card" *ngFor="let card of cards | async">
          <div class="header-card">
            <div class="name" [hidden]="!card.name">{{ card.name }}</div>
            <div class="time">{{ card.time }}</div>
          </div>
          <div class="clAb">
            <a
              *ngIf="card.numIris"
              class="cli"
              (click)="
                navigateToSynthese('/client/synthese/' + card.numClient + '/' + card.numIris)
              "
              [hidden]="!card.numClient">
              <div class="icon-number">
                <i class="icon waterpIcons-User mr-2"></i>
                <span class="underline" pTooltip="Ouvrir la synthèse Client">{{
                  card.numClient
                }}</span>
              </div>
            </a>
            <a
              *ngIf="!card.numIris"
              class="cli"
              (click)="navigateToSynthese('/client/synthese/' + card.numClient)"
              [hidden]="!card.numClient">
              <div class="icon-number">
                <i class="icon waterpIcons-User mr-2"></i>
                <span class="underline" pTooltip="Ouvrir la synthèse Client">{{
                  card.numClient
                }}</span>
              </div>
            </a>
            <a
              class="abo"
              (click)="navigateToSynthese('/abonnement/synthese/' + card.numAbonne)"
              [hidden]="!card.numAbonne">
              <div class="icon-number">
                <i class="icon waterpIcons-Abonnement mr-2"></i>
                <span
                  class="underline"
                  pTooltip="Ouvrir la synthèse Abonnement"
                  tooltipPosition="left"
                  >{{ card.numAbonne }}</span
                >
              </div></a
            >
          </div>
          <a [href]="getIrisUrl(card.numIris)" [hidden]="!card.numIris" target="_blank">
            <div class="icon-number">
              <i class="icon waterpIcons-Tiers mr-2"></i>
              <span class="underline" pTooltip="Lien vers Tiers">{{ card.numIris }}</span>
            </div>
          </a>
          <div class="text-[10px]" [hidden]="card.type !== 'IN' && card.type !== 'CR'">
            <i class="icon waterpIcons-Intervention" [hidden]="card.type !== 'IN'"></i
            ><i class="icon waterpIcons-Email" [hidden]="card.type !== 'CR'"></i>
            <a
              [hidden]="!card.numAbonne"
              (click)="navigateToSynthese('/abonnement/synthese/' + card.numAbonne, card.type)">
              <span
                class="underline"
                [pTooltip]="
                  card.type == 'IN'
                    ? 'Ouvrir le dossier Intervention'
                    : 'Ouvrir le dossier Courrier'
                "
                >{{ card.type == 'IN' ? card.numInter : card.courrierNum }}</span
              >
            </a>
          </div>
          <div class="text-[10px]" [hidden]="card.type !== 'IN' && card.type !== 'CR'">
            {{ card?.motif + '-' + card?.actionCourrier }}
          </div>
        </div>
      </div>
    </div>
    <!--</div>-->
  </mat-sidenav-content>
</mat-sidenav-container>
<!-- Affichage PDF-->
<waterp-pdf-viewer></waterp-pdf-viewer>
