import { Facture } from 'src/app/core/models/creationFactureManuelle.model';

export type FactureState = Facture;

export const initialState: FactureState = {
  detailsFacture: [],
  montantTtc: 0,
  numeroLotCalendrierFacturation: '',
  exercice: '',
  dateDebut: '',
  dateFin: '',
  volumeConsomme: 0,
  volumeSolde: 0,
  nombreDeplacement: 0,
  derniersPrix: false,
  assainissement: false,
  eau: false,
  primeFixe: false,
  calculerIsClicked: false,
  disabledCheckboxes: false
};
