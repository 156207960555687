import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WizardState } from './wizard.state';

export const selectWizard = createFeatureSelector<WizardState>('wizard');

export const selectLocalisationData = createSelector(
  selectWizard,
  (state: WizardState) => state.localisation
);

export const selectIndexData = createSelector(selectWizard, (state: WizardState) => state.index);

export const selectClientData = createSelector(selectWizard, (state: WizardState) => state.client);

export const selectProprietaireData = createSelector(
  selectWizard,
  (state: WizardState) => state.proprietaire
);

export const selectReferenceBancaireData = createSelector(
  selectWizard,
  (state: WizardState) => state.referenceBancaire
);

export const selectInfoSegmentationData = createSelector(
  selectWizard,
  (state: WizardState) => state.infoSegmentation
);

export const selectDocumentData = createSelector(
  selectWizard,
  (state: WizardState) => state.document
);

export const selectFactureEntrantData = createSelector(
  selectWizard,
  (state: WizardState) => state.factureEntrant
);
