import { Injectable } from '@angular/core';
import { Router, Resolve } from '@angular/router';
import { Observable, map, tap } from 'rxjs';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { ParametrageGeneriqueService } from 'src/app/api/services/parametrageGenerique.service';
import { Data } from '../../models/reference-data.model';
import { CodeLibelle } from 'src/app/api/models/interface/CodeLibelle';

@Injectable({
  providedIn: 'root'
})
export class GetAllCommunesResolver implements Resolve<Data[]> {
  constructor(private parametrageService: ParametrageGeneriqueService, private router: Router) {}

  resolve(): Observable<Data[]> {
    return this.parametrageService.getAllList('CommuneDto').pipe(
      tap((response: GeneriqueResponse) =>
        [112, 121].includes(response.Code) ? this.router.navigateByUrl('authentification') : null
      ),
      map((response: GeneriqueResponse) =>
        response.Result.map((commune: CodeLibelle) => ({
          Key: commune.Code,
          Value: commune.Libelle
        })).sort((a: Data, b: Data) => a.Value.localeCompare(b.Value))
      )
    );
  }
}
