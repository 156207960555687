import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { AppConfig } from 'src/app/app.config';
import { WaterpHttpService } from 'src/app/shared/services/waterp-http.service';
import { ActivateServiceRequest } from '../models/request/type-service/ActivateServiceRequest';
import { ParametersRequest } from '../models/shared/parametersRequest';
import { AbstractService } from './abstract.service';
import { TypeService } from '../models/class/type-service';

@Injectable({
  providedIn: 'root'
})
export class TypesServicesService extends AbstractService {
  parametersRequest!: ParametersRequest;
  public availableServices: TypeService[] = [];
  isLoading: boolean = false;

  constructor(config: AppConfig, private waterpHttpService: WaterpHttpService) {
    super(config);
  }

  //#region /TypeService/GetAvailableServices : Récupérer les services disponibles d'un abonnement
  /**
   * @param numeroAbonnement
   */
  public getAvailableServices(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/TypeService/GetAvailableServices',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    this.isLoading = true;
    return this.waterpHttpService.get(path, this.parametersRequest).pipe(
      map((response: GeneriqueResponse) => {
        response.Result =
          response.Result?.map((service: TypeService) => new TypeService(service)) ??
          new Array<TypeService>();
        this.availableServices = response.Result;
        this.isLoading = false;
        return response;
      })
    );
  }
  // #endregion

  //#region /TypeService/GetNextEtatTypeService : Récupérer le prochain état du type de service d'un abonnement.
  /**
   * @param numeroAbonnement
   * @param codeTypeService
   */
  public getNextEtatTypeService(
    numeroAbonnement: string,
    codeTypeService: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/TypeService/GetNextEtatTypeService',
      parameters: [
        { name: 'numeroAbonnement', value: numeroAbonnement },
        { name: 'codeTypeService', value: codeTypeService }
      ]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  // #endregion
  //#region /TypeService/ActivateService: ActivateService
  /**
   * @param body
   */
  public activateService(body: ActivateServiceRequest): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/TypeService/ActivateService`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion
  //#region /TypeService/GetPlaquettePubService : Récupérer le prochain état du type de service d'un abonnement.
  /**
   * @param numeroAbonnement
   * @param codeTypeService
   */
  public getPlaquettePubService(
    numeroAbonnement: string,
    codeTypeService: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/TypeService/GetPlaquettePubService',
      parameters: [
        { name: 'numeroAbonnement', value: numeroAbonnement },
        { name: 'codeTypeService', value: codeTypeService }
      ]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  // #endregion
  //#region /TypeService/ManageEtatService: Gestion du changement d'état d'un service soucris par l'abonnné
  /**
   * @param body
   */
  public manageEtatService(body: any): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/TypeService/ManageEtatService`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion
}
