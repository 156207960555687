import { createAction, props } from '@ngrx/store';
import { Etat } from '../../models/etat.model';

export const patchEtat = createAction('[Etat] Patch Etat info', props<{ payload: Etat }>());

export const updateIsMiseEnService = createAction(
  '[Etat] Update IsMiseEnService',
  props<{ isMiseEnService: boolean }>()
);

export const updateIsResiliable = createAction(
  '[Etat] Update IsResiliable',
  props<{ isResiliable: boolean }>()
);

export const updateIsEchangeable = createAction(
  '[Etat] Update IsEchangeable',
  props<{ isEchangeable: boolean }>()
);

export const updateIsAnnulable = createAction(
  '[Etat] Update IsAnnulable',
  props<{ isAnnulable: boolean }>()
);

export const init = createAction('[Init]');
