import { Pipe, PipeTransform } from '@angular/core';
import { formatDateWithHours } from 'src/app/shared/utils/dateUnixUtils';

@Pipe({
  name: 'dateUnixToHours'
})
export class DateUnixToHoursPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let formattedFullDate = formatDateWithHours(value);
    return formattedFullDate.split(' ')[1];
  }
}

