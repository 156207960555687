import { Action, createReducer, on } from '@ngrx/store';
import {
  init,
  patchInfosTechniques,
  updateIsRadioReleve,
  updateIsTeleReleve
} from './infos-techniques.actions';
import { initialState, InfosTechniquesState } from './infos-techniques.state';

const infosTechniquesReducer = createReducer(
  initialState,
  on(patchInfosTechniques, (state, action: ReturnType<typeof patchInfosTechniques>) => ({
    ...state,
    ...action.payload
  })),
  on(updateIsRadioReleve, (state, { isRadioReleve }: ReturnType<typeof updateIsRadioReleve>) => ({
    ...state,
    isRadioReleve
  })),
  on(updateIsTeleReleve, (state, { isTeleReleve }: ReturnType<typeof updateIsTeleReleve>) => ({
    ...state,
    isTeleReleve
  })),
  on(init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: InfosTechniquesState | undefined, action: Action) {
  return infosTechniquesReducer(state, action);
}
