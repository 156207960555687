import { Abonnement } from '../interface/Abonnement';
import { EtatService } from '../interface/EtatService';
import { TypeService } from './type-service';

export class AbonnementParTypeService {
  private Identifiant?: number;
  private CodeTypeService: string;
  private CodeEtatService: string;
  private Libelle?: string;
  private NextCode?: string;
  private NumeroAbonnement: string;
  private DateEtat?: Date;
  private DateDebut?: Date;
  private DateFin?: Date;
  private NumeroLotCalendrier: string;
  private IndicateurEtatProchaineFacturation: string;
  private DateCreation?: Date;
  private Abonnement?: Abonnement;
  private EtatService?: EtatService;
  private TypeService?: TypeService;

  constructor(distantAbtByTypSCE?: AbonnementParTypeService) {
    this.Identifiant = distantAbtByTypSCE?.Identifiant;
    this.CodeTypeService = distantAbtByTypSCE?.CodeTypeService ?? '';
    this.CodeEtatService = distantAbtByTypSCE?.CodeEtatService ?? '';
    this.Libelle = distantAbtByTypSCE?.Libelle;
    this.NextCode = distantAbtByTypSCE?.NextCode;
    this.NumeroAbonnement = distantAbtByTypSCE?.NumeroAbonnement ?? '';
    this.DateEtat = distantAbtByTypSCE?.DateEtat;
    this.DateDebut = distantAbtByTypSCE?.DateDebut;
    this.DateFin = distantAbtByTypSCE?.DateFin;
    this.NumeroLotCalendrier = distantAbtByTypSCE?.NumeroLotCalendrier ?? '';
    this.IndicateurEtatProchaineFacturation =
      distantAbtByTypSCE?.IndicateurEtatProchaineFacturation ?? '';
    this.DateCreation = distantAbtByTypSCE?.DateCreation;
    if (distantAbtByTypSCE?.Abonnement) this.Abonnement = distantAbtByTypSCE?.Abonnement;
    this.EtatService = distantAbtByTypSCE?.EtatService;
    if (distantAbtByTypSCE?.TypeService)
      this.TypeService = new TypeService(distantAbtByTypSCE?.TypeService);
  }

  //#region Accesseurs
  public getIdentifiant(): number | undefined {
    return this.Identifiant;
  }

  public setIdentifiant(identifiant: number): void {
    this.Identifiant = identifiant;
  }

  public getCodeTypeService(): string {
    return this.CodeTypeService;
  }

  public setCodeTypeService(codeTypeService: string): void {
    this.CodeTypeService = codeTypeService;
  }

  public getCodeEtatService(): string {
    return this.CodeEtatService;
  }

  public setCodeEtatService(codeEtatService: string): void {
    this.CodeEtatService = codeEtatService;
  }

  public getLibelle(): string | undefined {
    return this.Libelle;
  }

  public setLibelle(Libelle: string): void {
    this.Libelle = Libelle;
  }

  public getNextCode(): string | undefined {
    return this.NextCode;
  }

  public setNextCode(NextCode: string): void {
    this.NextCode = NextCode;
  }

  public getNumeroAbonnement(): string {
    return this.NumeroAbonnement;
  }

  public setNumeroAbonnement(numeroAbonnement: string): void {
    this.NumeroAbonnement = numeroAbonnement;
  }

  public getDateEtat(): Date | undefined {
    return this.DateEtat;
  }

  public setDateEtat(dateEtat: Date): void {
    this.DateEtat = dateEtat;
  }

  public getDateDebut(): Date | undefined {
    return this.DateDebut;
  }

  public setDateDebut(dateDebut: Date): void {
    this.DateDebut = dateDebut;
  }

  public getDateFin(): Date | undefined {
    return this.DateFin;
  }

  public setDateFin(dateFin: Date): void {
    this.DateFin = dateFin;
  }

  public getNumeroLotCalendrier(): string {
    return this.NumeroLotCalendrier;
  }

  public setNumeroLotCalendrier(numeroLotCalendrier: string): void {
    this.NumeroLotCalendrier = numeroLotCalendrier;
  }

  public getIndicateurEtatProchaineFacturation(): string {
    return this.IndicateurEtatProchaineFacturation;
  }

  public setIndicateurEtatProchaineFacturation(indicateurEtatProchaineFacturation: string): void {
    this.IndicateurEtatProchaineFacturation = indicateurEtatProchaineFacturation;
  }

  public getDateCreation(): Date | undefined {
    return this.DateCreation;
  }

  public setDateCreation(dateCreation: Date): void {
    this.DateCreation = dateCreation;
  }

  public getAbonnement(): Abonnement | undefined {
    return this.Abonnement;
  }

  public setAbonnement(abonnement: Abonnement): void {
    this.Abonnement = abonnement;
  }

  public getEtatService(): EtatService | undefined {
    return this.EtatService;
  }

  public setEtatService(etatService: EtatService): void {
    this.EtatService = etatService;
  }

  public getTypeService(): TypeService | undefined {
    return this.TypeService;
  }

  public setTypeService(typeService: TypeService): void {
    this.TypeService = typeService;
  }
  //#endregion
}
