import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InterventionByIdentifiantResponse } from 'src/app/api/models/response/intervention/InterventionByIdentifiantResponse';

@Injectable({
  providedIn: 'root'
})
export class InterventionDataService {
  //#region Properties Input
  private _intervention$ = new BehaviorSubject<InterventionByIdentifiantResponse | null>(null);
  intervention$ = this._intervention$.asObservable();

  private _isFromRoute$ = new BehaviorSubject<boolean>(false);
  isFromRoute$ = this._isFromRoute$.asObservable();

  private _bounceIn$ = new BehaviorSubject<boolean>(true);
  bounceIn$ = this._bounceIn$.asObservable();
  //#endregion

  setIntervention(state: InterventionByIdentifiantResponse) {
    this._intervention$.next(state);
  }

  setIsFromRoute(state: boolean) {
    this._isFromRoute$.next(state);
  }

  setBounceIn(state: boolean) {
    this._bounceIn$.next(state);
  }
}

