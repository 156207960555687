import { Injectable } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { WaterpHttpService } from 'src/app/shared/services/waterp-http.service';
import { GeneriqueResponse } from '../models/shared/generiqueResponse';
import { ParametersRequest } from '../models/shared/parametersRequest';
import { AbstractService } from './abstract.service';
import { TypesServicesService } from './types-services.service';
import { AbonnementParTypeService } from '../models/class/abonnement-par-type-service';

@Injectable({
  providedIn: 'root'
})
export class AbonnementParTypeServiceService extends AbstractService {
  parametersRequest!: ParametersRequest;
  public subscribedServices: AbonnementParTypeService[] = [];
  isLoading: boolean = false;
  constructor(
    config: AppConfig,
    private waterpHttpService: WaterpHttpService,
    private typesServicesService: TypesServicesService
  ) {
    super(config);
  }

  //#region /AbonnementParTypeService/GetAllByAbonnement: Récupération des services associés à un abonnement
  /**
   * @param numeroAbonnement
   */
  public getAllByAbonnement(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/AbonnementParTypeService/GetAllByAbonnement',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    this.isLoading = true;
    return this.waterpHttpService.get(path, this.parametersRequest).pipe(
      map((response: GeneriqueResponse) => {
        response.Result =
          response.Result?.map(
            (service: AbonnementParTypeService) => new AbonnementParTypeService(service)
          ) ?? new Array<AbonnementParTypeService>();
        this.subscribedServices = response.Result;
        this.isLoading = false;
        return response;
      })
    );
  }
  //#endregion

  async setNextEtat(service: any, numeroAbonnement: string) {
    await this.getNextEtat(service.CodeTypeService, service, numeroAbonnement);
  }

  async getNextEtat(code: string, service: any, numeroAbonnement: string) {
    this.typesServicesService.getNextEtatTypeService(numeroAbonnement, code).subscribe({
      next: res => {
        // Le WS me renvoit une donnée si le service posséde un état....
        if (res.Code === 100 && res.Result !== null) {
          service.Libelle = res.Result[0].Libelle;
          service.NextCode = res.Result[0].Code;
          this.subscribedServices.push(new AbonnementParTypeService(service));
        }
        // ...mais certains types de service n'ont pas d'état associé (ex : ecoreleve)
        // on les ajoute donc sans traitement sur l'état
        else {
          this.subscribedServices.push(new AbonnementParTypeService(service));
        }
      }
    });
  }

  //#region /AbonnementParTypeService/GetActiveServices : Récupération des services actifs associés à un abonnement
  /**
   * @param numeroAbonnement
   */
  public getActiveServices(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/AbonnementParTypeService/GetActiveServices',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  // #endregion
}
