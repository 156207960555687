<div class="lg:mx-28 md:mx-20" [formGroup]="factureEntrantForm" *ngIf="factureEntrantForm">
  <div class="grid grid-cols-2 gap-5 max-w-[500px] mx-auto">
    <div class="space-y-3">
      <div>
        <label for="dateEffet" class="required">Date d'effet</label>
        <p-calendar
          formControlName="dateEffet"
          class="w-full block"
          [maxDate]="maxDate"></p-calendar>
        <small
          class="p-error"
          *ngIf="dateEffet?.touched && dateEffet?.invalid && (dateEffet?.errors)!['required']"
          >{{ resource.message.errorMandatory }}</small
        >
      </div>
      <div>
        <p-checkbox formControlName="fraisAcces" [binary]="true"></p-checkbox>
        <label for="toutes" class="text-primary-600 font-bold">
          Frais d'accès au service
          <span *ngIf="factureEntrant">
            {{
              factureEntrant.FraisAccesService | currency : 'EUR' : 'symbol' : undefined : 'fr-FR'
            }}
          </span>
        </label>
      </div>
      <div *ngIf="isIndexVisible">
        <label for="indexConsentementComplexe" class="label-input"
          >Index consentement complexe</label
        >
        <input
          type="number"
          min="0"
          pInputText
          class="w-1/4"
          formControlName="indexConsentementComplexe" />
      </div>
    </div>
    <div class="space-y-4">
      <div>
        <label for="nbPrimeFixe" class="label-input">Nbre de primes fixes</label>
        <p-inputNumber
          [min]="0"
          [max]="9999999"
          class="waterp-inputnumber"
          formControlName="nbPrimeFixe"></p-inputNumber>
      </div>
      <div>
        <label for="nbJour" class="label-input">Nbre de jours</label>
        <input type="number" min="0" pInputText class="w-1/4" formControlName="nbJour" />
      </div>
      <div>
        <label for="nbMoisFacture" class="label-input">Nbre de mois facturé</label>
        <input type="number" min="0" pInputText class="w-1/4" formControlName="nbMoisFacture" />
      </div>
      <div>
        <label for="diametreFacturation" class="label-input">Diamètre facturation</label>
        <p-dropdown
          [options]="diametres"
          formControlName="diametreFacturation"
          class="w-full"
          optionValue="key"
          [showClear]="true"
          [placeholder]="resource.form.phDropDownList"
          [virtualScroll]="true"
          [virtualScrollItemSize]="50">
          <ng-template let-item pTemplate="selectedItem">
            <div class="item-value flex gap-2">
              <span class="label">{{ item.value }}</span>
            </div>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <div class="item-value">
              <span class="badge">{{ item.key }}</span>
              <span class="label">{{ item.value }}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>
</div>
<div class="flex flex-1 items-center justify-end space-x-3 mx-6 px-6 mt-10">
  <button class="button-cancel flex mr-0">
    <img class="py-0.5 pr-1" [src]="resource.svgPath.close" alt="closeSVG" />{{
      resource.form.cancel
    }}
  </button>
  <div class="flex flex-col separator"></div>
  <button class="button-cancel flex" [disabled]="submitIsLoading" (click)="returnPreviousForm()">
    <img class="py-0.5 pr-1" [src]="resource.svgPath.west" alt="westSVG" />{{
      resource.form.previous
    }}
  </button>
  <button class="button-submit flex" [disabled]="submitIsLoading" (click)="submitForm()">
    <fa-icon ng *ngIf="submitIsLoading" [icon]="faSpinner" [spin]="true" size="xs"></fa-icon
    ><fa-icon ng class="ml-1" [icon]="faCheck" size="xs"></fa-icon>{{ resource.form.validate }}
  </button>
</div>
