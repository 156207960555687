import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInfoCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { ChipModule } from 'primeng/chip';
import { lastValueFrom } from 'rxjs';
import { InterventionByIdentifiantResponse } from 'src/app/api/models/response/intervention/InterventionByIdentifiantResponse';
import { InterventionService } from 'src/app/api/services/intervention.service';
import { AppResource } from 'src/app/app.resource';
import { State } from 'src/app/core/state/core.state';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { FrameComponent } from 'src/app/shared/components/frame/frame.component';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { PhotosViewerComponent } from 'src/app/shared/components/photos-viewer/photos-viewer.component';
import { PipeModule } from 'src/app/shared/modules/pipe.module';
import { AbonnementDataService } from 'src/app/shared/services/abonnement-data.service';
import { InterventionDataService } from 'src/app/shared/services/intervention-data.service';
import { ApiResponseBodyUtils } from 'src/app/shared/utils/apiResponseBodyUtils';
import {
  convertirDuree,
  formatDate,
  formatDateWithHours
} from 'src/app/shared/utils/dateUnixUtils';

@Component({
  selector: 'app-consulter-intervention',
  templateUrl: './consulter-intervention.component.html',
  styleUrls: ['./consulter-intervention.component.scss'],
  imports: [
    CommonModule,
    FrameComponent,
    LoaderComponent,
    FontAwesomeModule,
    ChipModule,
    PipeModule,
    PhotosViewerComponent
  ],
  standalone: true
})
export class ConsulterInterventionComponent extends BaseComponent {
  @Input() isVisible: boolean = false;
  @Input() numAbonnement!: string;
  @Input() numIntervention!: string;
  @Input() bounceIn: boolean = true;
  @Input() intervention!: InterventionByIdentifiantResponse;
  @Input() isFromRoute: boolean = false;
  modifyVisiblity: boolean = false;

  isLoading: boolean = true;
  titleFrame!: string;
  photosId: Array<string> = [];
  detailsVisible: boolean = false;
  galeriePhotosIsVisible: boolean = false;

  faWarning = faWarning;
  faInfoCircle = faInfoCircle;

  constructor(
    store: Store<State>,
    resources: AppResource,
    messageService: MessageService,
    private interventionService: InterventionService,
    private abonnementDataService: AbonnementDataService,
    private interventionDataService: InterventionDataService,
    private title: Title
  ) {
    super(store, resources, messageService);

    this.abonnementDataService.numAbonnement$.subscribe(numAbonnement => {
      this.numAbonnement = numAbonnement;
    });

    this.abonnementDataService.numIntervention$.subscribe(numIntervention => {
      if (this.numIntervention != numIntervention) {
        this.isVisible = false;
        this.isVisible = true;
        this.numIntervention = numIntervention;
        this.afficherIntervention(
          this.numIntervention ?? this.intervention.IdentifiantIntervention
        );
      }
    });

    this.abonnementDataService.consultInterventionIsVisible$.subscribe(
      consultInterventionIsVisible => {
        this.isVisible = consultInterventionIsVisible;
      }
    );

    this.interventionDataService.isFromRoute$.subscribe(isFromRoute => {
      this.isFromRoute = isFromRoute;
      this.isVisible = true;
    });

    this.interventionDataService.intervention$.subscribe(intervention => {
      if (intervention) this.intervention = intervention;
    });

    this.interventionDataService.bounceIn$.subscribe(bounceIn => {
      this.bounceIn = bounceIn;
    });
  }

  // envoi valeur de isVisible au parent (pour fermer la div)
  modifyIsVisible(value: boolean) {
    this.abonnementDataService.setShowBouncingFrame('consultIntervention', value);
  }

  // afficher intervention
  async afficherIntervention(numIntervention: string) {
    this.changeTitle(numIntervention);

    this.isLoading = true;
    if (numIntervention) {
      const intervention = this.isFromRoute
        ? this.intervention
        : ApiResponseBodyUtils.ExtractResponseBody<InterventionByIdentifiantResponse>(
            await lastValueFrom(
              this.interventionService.getInterventionByIdentifiant(numIntervention)
            )
          );
      if (intervention) {
        if (intervention.DateLimite) {
          intervention.DateLimite = formatDate(intervention.DateLimite);
        }
        if (intervention.DateRendezVous) {
          intervention.DateRendezVous = formatDateWithHours(intervention.DateRendezVous);
        }
        if (intervention.DateExecution) {
          intervention.DateExecution = formatDateWithHours(intervention.DateExecution);
        }
        intervention.DureeStr = convertirDuree(intervention.DureeInterventionPrevue);
        this.intervention = intervention;
        this.isVisible = this.intervention !== null && this.intervention !== undefined;
      }
    }
    this.isLoading = false;
  }
  afficherPhotos(photosIds: Array<string>) {
    this.photosId = photosIds;
    this.galeriePhotosIsVisible = true;
  }

  changeTitle(numIntervention: string) {
    this.titleFrame =
      this.resource.GlobalTexts.Intervention.Dossier.TitlePanel.ReadOnly + numIntervention;
    if (this.isFromRoute) {
      this.title.setTitle('Synthèse intervention n°' + numIntervention + ' - Wat.erp Web');
      this.numIntervention = numIntervention;
    }
  }
}
