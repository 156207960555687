import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AppResource {
  private resource: any = null!;
  private dictReturnCodeMessages: { [code: string]: string } = {};

  constructor(private http: HttpClient) {}

  public getResource(key: any) {
    return this.resource[key];
  }

  loadReturnCodeDictionary() {
    this.dictReturnCodeMessages = {};
    for (let key in this.resource.Constants.ReturnCode) {
      let value = this.resource.Constants.OverridenReturnMessage[key]
        ? this.resource.Constants.OverridenReturnMessage[key]
        : this.resource.Constants.ReturnCode[key];
      this.dictReturnCodeMessages![value.Code] = value.Libelle;
    }
  }
  public resolveReturnCode(code: any): string {
    if (this.dictReturnCodeMessages[code]) {
      return this.dictReturnCodeMessages[code];
    }
    console.warn(`error code ${code} is missing in configuration.js`);
    return this.resource.Constants.ReturnCodeMessages.GENERIC_OperationFailed;
  }

  public load(): Promise<void> {
    console.log('Entree dans Resource.Load');
    return new Promise<void>((resolve, reject) => {
      const request = this.http.get(`./locale/configuration.json`);
      if (request) {
        request.subscribe({
          next: (responseData: any) => {
            this.resource = responseData;
            this.loadReturnCodeDictionary();
            resolve();
          },
          error: error => {
            console.error(
              'error when parsing to json configuration.json : ' + JSON.stringify(error)
            );
          }
        });
      } else {
        console.error('Locale resource file "locale.json" is not valid');
        reject();
      }
    });
  }
}
