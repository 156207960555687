import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { lastValueFrom, take } from 'rxjs';
import { LibelleEtatAbonnement } from 'src/app/api/models/enum/EtatAbonnement.enum';
import { PropertiesWizard } from 'src/app/api/models/interface/DataKeyValue';
import { InformationsFactureEntrantResponse } from 'src/app/api/models/response/abonnement/InformationsFactureEntrantResponse';
import { AbonnementService } from 'src/app/api/services/abonnement.service';
import { AppResource } from 'src/app/app.resource';
import { Index } from 'src/app/core/models/wizard.model';
import * as fromAbonnement from 'src/app/core/state/abonnement';
import { State } from 'src/app/core/state/core.state';
import * as fromWizard from 'src/app/core/state/wizard';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { ApiResponseBodyUtils } from 'src/app/shared/utils/apiResponseBodyUtils';
import { KeyFilterModule } from 'primeng/keyfilter';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CalendarModule,
    RadioButtonModule,
    FontAwesomeModule,
    TooltipModule,
    InputTextModule,
    KeyFilterModule
  ],
  standalone: true
})
export class IndexComponent extends BaseComponent implements OnInit {
  @Output() validForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() etatIndex: EventEmitter<string> = new EventEmitter<string>();
  @Output() etatIsVisible: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updateWizard: EventEmitter<PropertiesWizard> = new EventEmitter<PropertiesWizard>();

  numAbonnement!: string;

  branchementOuvert: boolean = true;
  indicateurConsentementComplexe: boolean = false;
  codeEtatBranchement: string = '';
  deposeCompteur: boolean = false;
  unknow: boolean = false;
  submitted: boolean = false;
  maxDate: Date = new Date();

  infoFactureEntrant!: InformationsFactureEntrantResponse;
  hasAppareil: boolean = false;

  faCircleInfo = faCircleInfo;

  //#region FormGroup
  indexForm!: FormGroup;
  //#endregion

  //#region GetFormControls
  get dateMutation() {
    return this.indexForm.get('dateMutation');
  }

  get typeIndex() {
    return this.indexForm.get('typeIndex');
  }

  get index() {
    return this.indexForm.get('index');
  }
  //#endregion

  constructor(
    store: Store<State>,
    resources: AppResource,
    messageService: MessageService,
    private abonnementService: AbonnementService,
    private datePipe: DatePipe
  ) {
    super(store, resources, messageService);

    //Initialisation des formulaires
    this.initForm();
  }

  override async ngOnInit() {
    super.ngOnInit();

    let numeroAbonnement = await lastValueFrom(
      this.store.select(fromAbonnement.selectNumeroAbonnement).pipe(take(1))
    );
    if (numeroAbonnement) {
      this.numAbonnement = numeroAbonnement;
    }

    let indexData = await lastValueFrom(
      this.store.select(fromWizard.selectIndexData).pipe(take(1))
    );

    this.hasAppareil = indexData.hasAppareil ? indexData.hasAppareil : false;

    this.indicateurConsentementComplexe = indexData.indicateurConsentementComplexe
      ? indexData.indicateurConsentementComplexe
      : false;

    this.codeEtatBranchement = indexData.codeEtatBranchement ? indexData.codeEtatBranchement : '';

    await this.initDataForm(indexData);
  }

  initForm() {
    this.indexForm = new FormGroup(
      {
        dateMutation: new FormControl<string | null>(null, Validators.required),
        typeIndex: new FormControl<string | null>(null, Validators.required),
        index: new FormControl<string | null>(null, Validators.maxLength(8))
      },
      {
        updateOn: 'change'
      }
    );

    this.typeIndex?.valueChanges.subscribe(res => {
      this.showForm(res);
    });

    this.indexForm.valueChanges.subscribe(() => {
      this.automaticSave();
    });
  }

  async initDataForm(index: Index) {
    let date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    if (index.dateMutation) this.dateMutation?.setValue(new Date(index.dateMutation));

    this.infoFactureEntrant =
      ApiResponseBodyUtils.ExtractResponseBody<InformationsFactureEntrantResponse>(
        await lastValueFrom(
          this.abonnementService.getInformationsFactureEntrantForProcess(
            date ? date : '',
            this.numAbonnement
          )
        )
      );

    await this.bindData(index);

    this.index?.setValue(index.index);
  }

  submitForm() {
    if (this.indexForm.valid) {
      this.validForm.emit(true);
    } else {
      this.indexForm.markAllAsTouched();
    }
  }

  async cancelForm() {
    let indexData = await lastValueFrom(
      this.store.select(fromWizard.selectIndexData).pipe(take(1))
    );

    await this.initDataForm(indexData);
  }

  clearValidators() {
    this.dateMutation?.clearValidators();
    this.typeIndex?.clearValidators();
    this.index?.clearValidators();
  }

  updateValidators() {
    this.dateMutation?.updateValueAndValidity({ emitEvent: false });
    this.typeIndex?.updateValueAndValidity({ emitEvent: false });
    this.index?.updateValueAndValidity({ emitEvent: false });
  }

  async bindData(index: Index) {
    let enable = true;

    if (index.indicateurConsentementComplexe) {
      this.typeIndex?.setValue(this.resource.Constants.Releve.CodeConditionReleve.Consentement);
      this.etatIndex.emit(LibelleEtatAbonnement.EnAttentePremiereUtilisation);
      //Code commenté au cas où on nous demande d'afficher l'index via WS par la suite, pour l'instant on le vide
      //this.index?.setValue(
      //  ApiResponseBodyUtils.ExtractResponseBody<number>(
      //    await lastValueFrom(
      //      this.abonnementService.getIndexConsentementComplexe(this.numAbonnement)
      //    )
      //  )
      //);
      index.index = null;

      this.deposeCompteur = false;
    } else {
      // Présence compteur ?
      if (!this.hasAppareil) {
        // On désactivera certains items
        enable = false;
        // On selectionne par defaut
        this.typeIndex?.setValue(this.resource.Constants.Releve.CodeConditionReleve.Inconnu);
        this.etatIndex.emit(LibelleEtatAbonnement.EnAttentePremiereUtilisation);
      }

      // On sélectionne index réel par defaut si présence d'un index sur l'appareil
      if (index.index != null && this.hasAppareil) {
        this.typeIndex?.setValue(this.resource.Constants.Releve.CodeConditionReleve.Reel);
        this.etatIndex.emit(LibelleEtatAbonnement.EnService);
      }

      // Item eventuellement désactivé
      if (
        !enable &&
        (this.typeIndex?.value == this.resource.Constants.Releve.CodeConditionReleve.Reel ||
          this.typeIndex?.value == this.resource.Constants.Releve.CodeConditionReleve.Estime)
      ) {
        this.deposeCompteur = false;
        this.etatIndex.emit(LibelleEtatAbonnement.EnService);
      } else if (
        !enable &&
        this.typeIndex?.value == this.resource.Constants.Releve.CodeConditionReleve.Inconnu
      ) {
        // Flag DeposeCompteur à true pour afficher le message de "compteur déposé"
        this.deposeCompteur =
          this.infoFactureEntrant.CodeModeDistribution !=
          this.resource.Constants.Abonnement.CodeModeDistribution.AbonnementSansDistribution;
        // Si enable == false alors il n'y a pas d'appareil
        //  ok... mais il se peut qu'il n'y ait pas d'appareil parce que l'abonnement est de type assainissement seulement
        //  dans ce cas "compteur déposé" n'a aucun sens
        this.etatIndex.emit(LibelleEtatAbonnement.EnAttentePremiereUtilisation);

        // const propertiesWizard: PropertiesWizard = {
        //   Index: 1,
        //   Properties: [
        //     { Key: 'ignoreStep', Value: true },
        //     { Key: 'show', Value: false },
        //     { Key: 'valid', Value: true }
        //   ]
        // };
        // this.updateWizard.emit(propertiesWizard);
      } else if (
        !enable &&
        this.typeIndex?.value == this.resource.Constants.Releve.CodeConditionReleve.Consentement
      ) {
        // on ne prends pas en compte dans la liste des radio-boutons 'Consentement Complexe'
        this.etatIndex.emit(LibelleEtatAbonnement.EnAttentePremiereUtilisation);
      } else {
        if (
          this.typeIndex?.value !== this.resource.Constants.Releve.CodeConditionReleve.Consentement
        ) {
          this.deposeCompteur = false;
          this.etatIndex.emit(LibelleEtatAbonnement.EnAttentePremiereUtilisation);
        }
      }
    }

    // Détermine l'icone de l'état du branchement : ouvert (vert) ou fermé (rouge)
    if (
      this.codeEtatBranchement == 'P' ||
      this.codeEtatBranchement == 'F' ||
      this.indicateurConsentementComplexe == true
    ) {
      this.branchementOuvert = false;
    }

    if (!enable) this.typeIndex?.disable();

    this.showForm(this.typeIndex?.value);
  }

  showForm(result: string) {
    this.clearValidators();

    if (result == null) {
      this.unknow = true;
      this.etatIsVisible.emit(false);

      //Si index inconnu, vignette "En attente" et unknow == true
    } else if (result == this.resource.Constants.Releve.CodeConditionReleve.Inconnu) {
      this.unknow = true;
      this.etatIndex.emit(LibelleEtatAbonnement.EnAttentePremiereUtilisation);
      this.etatIsVisible.emit(true);

      //Si index consentement complexe, vignette "En attente" et unknow == false
    } else if (result == this.resource.Constants.Releve.CodeConditionReleve.Consentement) {
      this.unknow = false;
      this.etatIndex.emit(LibelleEtatAbonnement.EnAttentePremiereUtilisation);
      this.etatIsVisible.emit(true);

      //Si index réel ou estimé, vignette "En service"
    } else {
      this.dateMutation?.setValidators(Validators.required);
      this.index?.setValidators(Validators.required);
      this.unknow = false;
      this.etatIndex.emit(LibelleEtatAbonnement.EnService);
      this.etatIsVisible.emit(true);
    }

    this.updateValidators();
  }

  automaticSave() {
    this.store.dispatch(
      fromWizard.updateIndex({
        payload: {
          dateMutation:
            this.typeIndex?.value != this.resource.Constants.Releve.CodeConditionReleve.Inconnu
              ? this.datePipe.transform(this.dateMutation?.value, 'MM/dd/yyyy')
              : this.datePipe.transform(new Date(), 'MM/dd/yyyy'),
          typeIndex: this.typeIndex?.value,
          index:
            this.typeIndex?.value != this.resource.Constants.Releve.CodeConditionReleve.Inconnu
              ? this.index?.value
              : '',
          hasAppareil: this.hasAppareil,
          indicateurConsentementComplexe: this.indicateurConsentementComplexe
        }
      })
    );
  }
}
