import * as fromInfosPdf from 'src/app/core/state/infos-pdf';
import * as fromRoot from 'src/app/core/state/core.state';
import { Store } from '@ngrx/store';
// Transform data to blob
export function dataToBlob(data: string) {
  const byteString = window.atob(data);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  return url;
}

// Transforme un tableau de bytes en string base 64
export function bytesToBase64(bytes: any) {
  let binary = '';
  let b = new Uint8Array(bytes);
  let length = b.byteLength;
  for (let i = 0; i < length; i++) {
    binary += String.fromCharCode(b[i]);
  }
  return window.btoa(binary);
}

//ouvre un pdf dans un nouvelle onglet
export function openPDF(url: string) {
  window.open(url, '_blank');
}
export function updateStorePDF(
  data: number[],
  title: string,
  store: Store<fromRoot.State>,
  xmlData?: string
) {
  store.dispatch(fromInfosPdf.updateShowPdf({ showPdf: true }));
  store.dispatch(
    fromInfosPdf.updateTitle({
      title: title
    })
  );
  store.dispatch(fromInfosPdf.updateDocumentContent({ documentContent: data }));
  if (xmlData && xmlData.length > 0) {
    store.dispatch(fromInfosPdf.updateXmlData({ xmlData: xmlData }));
  }
}
