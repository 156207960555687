import { Action, createReducer, on } from '@ngrx/store';
import {
  init,
  patchOperation,
  updateAvoirPartiel,
  updateAvoirTotal,
  updateInfosAvoir,
  updateNumeroFacture
} from './operation.actions';
import { initialState, OperationState } from './operation.state';

const operationReducer = createReducer(
  initialState,
  on(patchOperation, (state, action: ReturnType<typeof patchOperation>) => ({
    ...state,
    ...action.payload
  })),
  on(updateAvoirPartiel, (state, { showAvoirPartiel }: ReturnType<typeof updateAvoirPartiel>) => ({
    ...state,
    showAvoirPartiel
  })),
  on(updateAvoirTotal, (state, { showAvoirTotal }: ReturnType<typeof updateAvoirTotal>) => ({
    ...state,
    showAvoirTotal
  })),
  on(updateNumeroFacture, (state, { numeroFacture }: ReturnType<typeof updateNumeroFacture>) => ({
    ...state,
    numeroFacture
  })),
  on(updateInfosAvoir, (state, { infosAvoir }: ReturnType<typeof updateInfosAvoir>) => ({
    ...state,
    infosAvoir
  })),
  on(init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: OperationState | undefined, action: Action) {
  return operationReducer(state, action);
}
