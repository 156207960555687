import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { AppConfig } from 'src/app/app.config';
import { WaterpHttpService } from 'src/app/shared/services/waterp-http.service';
import { AjoutInterventionRequest } from '../models/request/interventions/ajoutInterventionRequest';
import { AnnulerInterventionRequest } from '../models/request/interventions/annulerInterventionRequest';
import { CloturerInterventionRequest } from '../models/request/interventions/cloturerInterventionRequest';
import { GenererRapportInterventionRequest } from '../models/request/interventions/genererRapportInterventionRequest';
import { ModifInterventionRequest } from '../models/request/interventions/modifInterventionRequest';
import { ParametersRequest } from '../models/shared/parametersRequest';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class InterventionService extends AbstractService {
  parametersRequest!: ParametersRequest;
  constructor(
    config: AppConfig,
    private waterpHttpService: WaterpHttpService,
    private httpClient: HttpClient
  ) {
    super(config);
  }

  //#region /intervention: Récupérer la liste des interventions par abonnement
  /**
   * @param numeroAbonnement
   */
  public rechercherByAbonnement(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Intervention/RechercherByAbonnement',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion
  //#region /Intervention/GetActionsByMotifIntervention: Récupérer les actions intervention en fonction d'un motif d'intervention
  /**
   * @param codeMotif
   */
  public getActionsByMotifIntervention(codeMotif: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Intervention/GetActionsByMotifIntervention',
      parameters: [{ name: 'codeMotif', value: codeMotif }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Intervention/GetInformationsToCreate: Récupérer les informations nécessaires à la création d'une intervention
  /**
   * @param codeAction
   * @param codeMotif
   */
  public getInformationsToCreate(
    codeAction: string,
    codeMotif: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Intervention/GetInformationsToCreate',
      parameters: [
        { name: 'codeAction', value: codeAction },
        { name: 'codeMotif', value: codeMotif }
      ]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion
  //#region /Intervention/GetInformationsToCreateByAbonnement: Récupérer les informations nécessaires à la création d'une intervention
  /**
   * @param codeAction
   * @param codeMotif
   * @param numeroAbonnement
   */
  public getInformationsToCreateByAbonnement(
    codeAction: string,
    codeMotif: string,
    numeroAbonnement: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Intervention/GetInformationsToCreateByAbonnement',
      parameters: [
        { name: 'codeAction', value: codeAction },
        { name: 'codeMotif', value: codeMotif },
        { name: 'numeroAbonnement', value: numeroAbonnement }
      ]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Intervention/InterventionEnCoursExist: Vérifier si une intervention avec l'action/motif est déjà en cours
  /**
   * @param codeAction
   * @param codeMotif
   */
  public interventionEnCoursExist(
    codeAction: string,
    codeMotif: string,
    numeroAbonnement: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Intervention/InterventionEnCoursExist',
      parameters: [
        { name: 'codeAction', value: codeAction },
        { name: 'codeMotif', value: codeMotif },
        { name: 'numeroAbonnement', value: numeroAbonnement }
      ]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#region /Intervention/MettreAJour: Mettre à jour une intervention
  /**
   * @param body
   */
  public mettreAJour(body: ModifInterventionRequest): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Intervention/MettreAJour`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion
  //#region /Intervention/Ajouter: Récupérer la ressource prévue pour une intervention
  /**
   * @param body
   */
  public ajouter(body: AjoutInterventionRequest): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Intervention/Ajouter`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion
  //#region /Intervention/Annuler: Annuler une intervention
  /**
   * @param body
   */
  public annuler(body: AnnulerInterventionRequest): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Intervention/Annuler`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion
  //#region /Intervention/LancerEmailInterventionUrgent: Envoyer le mail d'intervention urgente
  /**
   * @param body
   */
  public lancerEmailInterventionUrgent(body: string): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Intervention/LancerEmailInterventionUrgent`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion
  //#endregion
  //#region /Intervention/GetInterventionByIdentifiant: Récupérer les informations d'une intervention par son identifiant
  /**
   * @param identifiant
   */
  public getInterventionByIdentifiant(identifiant: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Intervention/GetInterventionByIdentifiant',
      parameters: [{ name: 'identifiant', value: identifiant }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion
  //#region /Intervention/GetCriByUUId: Récupérer le numéro Compte Rendu Intervention
  /**
   * @param uuId
   */
  public getCriByUUId(uuId: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Intervention/GetCriByUUId',
      parameters: [{ name: 'uuId', value: uuId }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion
  //#region /Intervention/GenererRapportIntervention: Générer un fichier de rapport d'intervention au type souhaité via WebApi ASPOSE
  /**
   * @param body
   */
  public genererRapportIntervention(body: GenererRapportInterventionRequest) {
    let headers = this.defaultHeaders;

    //Define Headers
    headers = headers.set('Content-Type', 'application/xml');
    headers = headers.set('Accept', '*/*');
    headers = headers.set('Token', this.waterpHttpService.token);

    return this.httpClient.post(`${this.basePath}/Intervention/GenererRapportIntervention`, body, {
      headers: headers,
      observe: 'response',
      responseType: 'text'
    });
  }
  //#endregion
  //#region /intervention/EditerFicheIntervention: Edition de la Fiche Intervention, Archive le document si option activée
  /**
   * @param idIntervention
   */
  public editerFicheIntervention(idIntervention: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Intervention/EditerFicheIntervention',
      parameters: [{ name: 'idIntervention', value: idIntervention }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion
  //#region /intervention/GenererRapportChangementCompteur: Edition de la Fiche Intervention, Archive le document si option activée
  /**
   * @param numeroAbonnement
   */
  public genererRapportChangementCompteur(idIntervention: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Intervention/GenererRapportChangementCompteur',
      parameters: [{ name: 'idIntervention', value: idIntervention }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion
  //#region /Intervention/RechercherByLocalisation
  /**
   * @param codeCommune
   * @param nbParPage
   * @param numeroRue
   * @param numeroRueDebut
   * @param numeroRueFin
   * @param pageCourante
   */
  public rechercherByLocalisation(
    codeCommune: number,
    nbParPage: number,
    numeroRue: number,
    numeroRueDebut: number,
    numeroRueFin: number,
    pageCourante: number
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Intervention/RechercherByLocalisation',
      parameters: [
        { name: 'codeCommune', value: codeCommune },
        { name: 'nbParPage', value: nbParPage },
        { name: 'numeroRue', value: numeroRue },
        { name: 'numeroRueDebut', value: numeroRueDebut },
        { name: 'numeroRueFin', value: numeroRueFin },
        { name: 'pageCourante', value: pageCourante }
      ]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  // ---------------------------------------------------------------------------------

  //#region /veolia/APIGatewayAngular/Intervention/GetIdentifiantsPhoto
  /**
   * @description Renvoie les identifiants ArchivePhotos associés à l'intervention
   * @param numeroPhoto
   */
  public getIdentifiantsPhoto(numeroIntervention: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Intervention/GetIdentifiantsPhoto',
      parameters: [{ name: 'numeroIntervention', value: numeroIntervention }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  // ---------------------------------------------------------------------------------

  //#region /Intervention/GetPhoto: Renvoie la photo en fonction de son identifiant
  /**
   * @param numeroPhoto
   */
  public getPhoto(numeroPhoto: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Intervention/GetPhoto',
      parameters: [{ name: 'numeroPhoto', value: numeroPhoto }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion
  //#region /Intervention/Cloturer: CloturerIntervention
  /**
   * @param body
   */
  public cloturer(body: CloturerInterventionRequest): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Intervention/Cloturer`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion
  //#region /Intervention/GetMotifActionForMutation: GetMotifActionForMutation
  /**
   * @param body
   */
  public getMotifActionForMutation(numeroAbonnement: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Intervention/GetMotifActionForMutation',
      parameters: [{ name: 'numeroAbonnement', value: numeroAbonnement }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion
}
