<div class="lg:mx-28 md:mx-20" [formGroup]="proprietaireForm">
  <div class="grid grid-cols-3 gap-5 max-w-[500px] mx-auto">
    <div>
      <div class="flex-col">
        <label for="civilite" class="label-input">Civilité</label>
        <p-dropdown
          [options]="civilities"
          formControlName="civilite"
          [virtualScroll]="true"
          [virtualScrollItemSize]="10"
          [filter]="true"
          class="w-full"
          optionLabel="Value"
          [placeholder]="resource.form.phDropDownList">
        </p-dropdown>
        <small
          class="p-error"
          *ngIf="civilite?.touched && civilite?.invalid && (civilite?.errors)!['required']"
          >{{ resource.message.errorMandatory }}</small
        >
      </div>
    </div>
    <div class="col-span-2">
      <label for="proprietaireGerant" class="label-input">Propriétaire ou gérant</label>
      <input
        type="text"
        class="app-input w-full"
        pInputText
        [maxlength]="35"
        formControlName="proprietaireGerant" />
    </div>
    <div class="col-span-3">
      <label for="adressePostale" class="label-input">Adresse postale</label>
      <input
        type="text"
        class="app-input w-full"
        pInputText
        formControlName="adressePostale"
        [maxlength]="35" />
    </div>
    <div>
      <label for="codePostal" class="label-input">Code postal</label>
      <input
        type="text"
        class="app-input w-full"
        pInputText
        [maxlength]="5"
        pKeyFilter="int"
        formControlName="codePostal" />
    </div>
    <div class="col-span-2">
      <label for="ville" class="label-input">Ville</label>
      <input
        type="text"
        class="app-input w-full"
        pInputText
        formControlName="ville"
        [maxlength]="35" />
    </div>
    <div>
      <label for="numeroTelephone" class="label-input">Téléphone</label>
      <input
        [class]="numeroTelephone?.invalid ? 'border-error' : ''"
        pInputText
        pKeyFilter="int"
        class="app-input w-full"
        type="text"
        [maxlength]="10"
        formControlName="numeroTelephone" />
      <div
        *ngIf="numeroTelephone?.invalid && (numeroTelephone?.dirty || numeroTelephone?.touched)"
        class="error">
        <div *ngIf="numeroTelephone?.errors?.['pattern']">Format du téléphone incorrect</div>
      </div>
    </div>
    <div class="col-span-2">
      <div class="col-form">
        <label for="email" class="label-input">Email</label>
        <input
          [class]="email?.invalid ? 'border-error' : ''"
          type="text"
          class="app-input w-full"
          formControlName="email"
          pInputText
          [maxlength]="100" />
        <div *ngIf="email?.invalid && (email?.dirty || email?.touched)" class="error">
          <div *ngIf="email?.errors?.['pattern']">
            {{ resource.GlobalTexts.Generic.BadEmailFormat }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-1 items-center justify-end space-x-3 mx-6 px-6 mt-10">
    <button class="button-cancel flex mr-0" (click)="cancelForm()">
      <img class="py-0.5 pr-1" [src]="resource.svgPath.close" alt="closeSVG" />Annuler
    </button>
    <div class="flex flex-col separator"></div>
    <button class="button-cancel flex" (click)="returnPreviousForm()">
      <img class="py-0.5 pr-1" [src]="resource.svgPath.west" alt="westSVG" />Précédent
    </button>
    <button class="button-submit flex" (click)="submitForm()">
      Suivant<img class="py-0.5 pl-1" [src]="resource.svgPath.east" alt="eastSvg" />
    </button>
  </div>
</div>
