import { createAction, props } from '@ngrx/store';
import {
  InfoSegmentation,
  Localisation,
  Proprietaire,
  ReferenceBancaire,
  Document,
  Client,
  FactureEntrant,
  Index
} from 'src/app/core/models/wizard.model';

export const updateLocalisation = createAction(
  '[Wizard] Update Localisation',
  props<{ payload: Partial<Localisation> }>()
);

export const updateIndex = createAction(
  '[Mutation] Update Index',
  props<{ payload: Partial<Index> }>()
);

export const updateClient = createAction(
  '[Wizard] Update Client',
  props<{ payload: Partial<Client> }>()
);

export const updateProprietaire = createAction(
  '[Wizard] Update Proprietaire',
  props<{ payload: Partial<Proprietaire> }>()
);

export const updateReferenceBancaire = createAction(
  '[Wizard] Update ReferenceBancaire',
  props<{ payload: Partial<ReferenceBancaire> }>()
);

export const updateInfoSegmentation = createAction(
  '[Wizard] Update InfoSegmentation',
  props<{ payload: Partial<InfoSegmentation> }>()
);

export const updateDocument = createAction(
  '[Wizard] Update Document',
  props<{ payload: Partial<Document> }>()
);

export const updateFactureEntrant = createAction(
  '[Mutation] Update Facture Entrant',
  props<{ payload: Partial<FactureEntrant> }>()
);

export const resetLocalisation = createAction('[Wizard] Reset Localisation');

export const resetIndex = createAction('[Mutation] Reset LoIndexcalisation');

export const resetClient = createAction('[Wizard] Reset Client');

export const resetProprietaire = createAction('[Wizard] Reset Proprietaire');

export const resetReferenceBancaire = createAction('[Wizard] Reset ReferenceBancaire');

export const resetInfoSegmentation = createAction('[Wizard] Reset InfoSegmentation');

export const resetDocument = createAction('[Wizard] Reset Document');

export const resetFactureEntrant = createAction('[Mutation] Reset FactureEntrant');

export const init = createAction('[Init]');
