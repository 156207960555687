import { DossierCompte } from 'src/app/core/models/dossier-compte.model';

export type DossierCompteState = DossierCompte;

export const initialState: DossierCompteState = {
  isMensualisable: false,
  isFPA: false,
  isRemboursable: false,
  isFactureManuelle: false,
  isAutreFactureManuelle: false,
  hasEcheancierFPA: false,
  hasEcheancierFPACB: false
};
