import { Wizard } from '../../models/wizard.model';

export type WizardState = Wizard;

export const initialState: WizardState = {
  localisation: {
    commune: null,
    ville: null,
    codePostal: null,
    rue: null,
    numeroRue: null,
    cpl: null,
    etage: null,
    complementAdresse: null,
    idTiersAbo: null,
    idTiersDest: null
  },
  index: {
    dateMutation: null,
    typeIndex: null,
    index: null,
    hasAppareil: false,
    indicateurConsentementComplexe: false,
    codeEtatBranchement: null
  },
  client: {
    codeCivilite: null,
    nom: null,
    numeroRue: 0,
    numeroDansLaRue: 0,
    cpl: null,
    etage: null,
    telephone1: null,
    telephone2: null,
    email: null,
    dateNaissance: null,
    numeroSIRET: null,
    codeAPE: null,
    civiliteClient: null,
    raisonSociale: null,
    prenomClient: null,
    adresse: null,
    codePostal: null,
    ville: null,
    bureauDistributeur: null,
    etatVerificationAdresse: null,
    identifiantTiersAbo: null,
    identifiantTiersDest: null,
    indicateurPliNonDistribue: null
  },
  proprietaire: {
    civilite: null,
    proprietaireGerant: null,
    adressePostale: null,
    codePostal: null,
    ville: null,
    numeroTelephone: null,
    email: null
  },
  referenceBancaire: {
    modePaiement: null,
    dateValiditeCB: null,
    jourPrelevement: null,
    acquisitionConsentement: null,
    titulaire: null,
    iban: null,
    domiciliation: null,
    bic: null,
    numeroRUM: null,
    dateRUM: null
  },
  infoSegmentation: {
    nombreOccupant: null,
    observation: null,
    commentaire: null,
    activite: null,
    typeAbonne: null,
    typeUtilisateur: null,
    particulariteContrat: null,
    typeHabitation: null,
    sensibilite: null,
    typeClientRisque: null
  },
  document: {
    branchementEauPotable: false,
    branchementEauUsee: false,
    envoiCourrier: false,
    numeroDemandeTravO: null
  },
  factureEntrant: {
    dateEffet: null,
    fraisAcces: false,
    nbPrimeFixe: null,
    nbJour: null,
    nbMoisFacture: null,
    diametreFacturation: null
  }
};
