import { Action, createReducer, on } from '@ngrx/store';
import {
  init,
  patchInfosFinancieres,
  updateNombreNonValeur,
  updateNombreFacturesManuelles
} from './infos-financieres.actions';
import { initialState, InfosFinancieresState } from './infos-financieres.state';

const infosFinancieresReducer = createReducer(
  initialState,
  on(patchInfosFinancieres, (state, action: ReturnType<typeof patchInfosFinancieres>) => ({
    ...state,
    ...action.payload
  })),
  on(
    updateNombreNonValeur,
    (state, { nombreNonValeur }: ReturnType<typeof updateNombreNonValeur>) => ({
      ...state,
      nombreNonValeur
    })
  ),
  on(
    updateNombreFacturesManuelles,
    (state, { nombreFacturesManuelles }: ReturnType<typeof updateNombreFacturesManuelles>) => ({
      ...state,
      nombreFacturesManuelles
    })
  ),
  on(init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: InfosFinancieresState | undefined, action: Action) {
  return infosFinancieresReducer(state, action);
}
