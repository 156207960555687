import { Option } from 'src/app/shared/models/option';

export class Diametre {
  Code: number;
  Value: string;

  constructor(diametre: Diametre) {
    this.Code = diametre?.Code ?? 0;
    this.Value = diametre?.Code?.toString() ?? '0';
  }

  public toOption(): Option<number, string> {
    return new Option(
      this.Code != undefined ? this.Code : 0,
      this.Code != undefined ? this.Value : '0'
    );
  }
}
