import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { lastValueFrom, map, Observable, take } from 'rxjs';
import { DropDownList } from 'src/app/api/models/interface/KeyValue';
import { InformationsFactureEntrantResponse } from 'src/app/api/models/response/abonnement/InformationsFactureEntrantResponse';
import { ListeDiametresResponse } from 'src/app/api/models/shared/listeDiametresResponse';
import { AbonnementService } from 'src/app/api/services/abonnement.service';
import { ParametrageGeneriqueService } from 'src/app/api/services/parametrageGenerique.service';
import { AppResource } from 'src/app/app.resource';
import * as fromAbonnement from 'src/app/core/state/abonnement';
import { State } from 'src/app/core/state/core.state';
import * as fromWizard from 'src/app/core/state/wizard';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { ApiResponseBodyUtils } from 'src/app/shared/utils/apiResponseBodyUtils';
import { InputNumberModule } from 'primeng/inputnumber';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { Option } from 'src/app/shared/models/option';
import { Diametre } from 'src/app/api/models/class/diametre';
import { Index } from 'src/app/core/models/wizard.model';

@Component({
  selector: 'app-facture-entrant',
  templateUrl: './facture-entrant.component.html',
  styleUrls: ['./facture-entrant.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CalendarModule,
    CheckboxModule,
    DropdownModule,
    FontAwesomeModule,
    InputTextModule,
    InputNumberModule
  ],
  standalone: true
})
export class FactureEntrantComponent extends BaseComponent implements OnInit {
  @Output() validForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() previousForm: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() submitEvent = new EventEmitter<boolean>();
  @Input() lastStep: boolean = false;
  @Input() submitIsLoading!: boolean;
  @Input() miseEnService: boolean = false;

  faSpinner = faSpinner;
  faCheck = faCheck;

  numAbonnement!: string;
  maxDate: Date = new Date();
  isIndexVisible: boolean = false;

  //#region FormGroup
  factureEntrantForm!: FormGroup;
  diametres: DropDownList[] = [];
  factureEntrant!: InformationsFactureEntrantResponse;
  //#endregion

  //#region GetFormControls
  get dateEffet() {
    return this.factureEntrantForm.get('dateEffet');
  }

  get fraisAcces() {
    return this.factureEntrantForm.get('fraisAcces');
  }

  get indicateurConsentementComplexe() {
    return this.factureEntrantForm.get('indicateurConsentementComplexe');
  }

  get indexConsentementComplexe() {
    return this.factureEntrantForm.get('indexConsentementComplexe');
  }

  get nbPrimeFixe() {
    return this.factureEntrantForm.get('nbPrimeFixe');
  }

  get nbJour() {
    return this.factureEntrantForm.get('nbJour');
  }

  get nbMoisFacture() {
    return this.factureEntrantForm.get('nbMoisFacture');
  }

  get diametreFacturation() {
    return this.factureEntrantForm.get('diametreFacturation');
  }
  //#endregion

  constructor(
    store: Store<State>,
    resources: AppResource,
    messageService: MessageService,

    private abonnementService: AbonnementService,
    private generiqueService: ParametrageGeneriqueService,
    private datePipe: DatePipe
  ) {
    super(store, resources, messageService);

    //Initialisation des formulaires
    this.initForm();
  }

  override async ngOnInit() {
    let numeroAbonnement = await lastValueFrom(
      this.store.select(fromAbonnement.selectNumeroAbonnement).pipe(take(1))
    );
    if (numeroAbonnement) {
      this.numAbonnement = numeroAbonnement;
    }

    this.initDataForm();
  }

  initForm() {
    this.factureEntrantForm = new FormGroup(
      {
        dateEffet: new FormControl<string | null>(null, Validators.required),
        fraisAcces: new FormControl<boolean>(false),
        indexConsentementComplexe: new FormControl<number | null>(null),
        indicateurConsentementComplexe: new FormControl<boolean>(false),
        nbPrimeFixe: new FormControl<number | null>(null, Validators.maxLength(7)),
        nbJour: new FormControl<number | null>(null),
        nbMoisFacture: new FormControl<number | null>(null),
        diametreFacturation: new FormControl<DropDownList | null>(null)
      },
      {
        updateOn: 'change'
      }
    );

    this.store.select(fromWizard.selectIndexData).subscribe((response: Index) => {
      this.nbJour?.disable();
      this.nbMoisFacture?.disable();

      if (response.typeIndex == 'I' || null) {
        this.dateEffet?.disable();
        this.fraisAcces?.disable();
        this.nbPrimeFixe?.disable();
        this.diametreFacturation?.disable();
      }
      return response;
    });

    this.fraisAcces?.valueChanges.subscribe(res => {
      if (res) {
        this.getCalculFraisMiseEnService().subscribe();
      } else this.factureEntrant.FraisAccesService = 0;
    });

    this.factureEntrantForm.valueChanges.subscribe(() => {
      this.automaticSave();
    });
  }

  initDataForm() {
    this.getDiametres().subscribe();

    this.getInformationsFactureEntrantForProcess().subscribe((response: GeneriqueResponse) => {
      this.factureEntrant = response.Result;

      this.dateEffet?.setValue(new Date(), { emitEvent: false });
      this.fraisAcces?.setValue(this.factureEntrant.IndicateurFraisAccesService, {
        emitEvent: false
      });
      this.indexConsentementComplexe?.setValue(this.factureEntrant.IndexConsentementComplexe, {
        emitEvent: false
      });
      this.indicateurConsentementComplexe?.setValue(
        this.factureEntrant.IndicateurConsentementComplexe != undefined
          ? this.factureEntrant.IndicateurConsentementComplexe
          : false,
        { emitEvent: false }
      );
      this.nbPrimeFixe?.setValue(
        this.factureEntrant.NombrePrimesFixesOuVolumeSouscrit
          ? this.factureEntrant.NombrePrimesFixesOuVolumeSouscrit
          : 0,
        { emitEvent: false }
      );
      this.nbJour?.setValue(this.factureEntrant.NbJours ? this.factureEntrant.NbJours : 0, {
        emitEvent: false
      });
      this.nbMoisFacture?.setValue(this.factureEntrant.NbMois ? this.factureEntrant.NbMois : 0, {
        emitEvent: false
      });

      this.diametreFacturation?.setValue(
        this.diametres.find(obj => {
          return obj.Key === this.factureEntrant.CodeDiametreBranchement;
        }),
        { emitEvent: false }
      );
    });

    if (this.indicateurConsentementComplexe?.value && this.miseEnService) {
      this.isIndexVisible = true;
      this.indexConsentementComplexe?.disable();
    }
  }

  returnPreviousForm() {
    this.previousForm.emit(true);
  }

  async submitForm() {
    this.automaticSave();
    if (!this.submitIsLoading && this.factureEntrantForm.valid) {
      this.submitIsLoading = true;
      if (this.lastStep) this.submitEvent.emit(true);
      else this.validForm.emit(true);
    } else {
      this.submitIsLoading = false;
      this.factureEntrantForm.markAllAsTouched();
    }
  }

  async cancelForm() {
    await this.initDataForm();
  }

  automaticSave() {
    this.store.dispatch(
      fromWizard.updateFactureEntrant({
        payload: {
          dateEffet: this.datePipe.transform(this.dateEffet?.value, 'MM/dd/yyyy'),
          fraisAcces: this.fraisAcces?.value ? true : false,
          nbPrimeFixe: this.nbPrimeFixe?.value,
          nbJour: this.nbJour?.value,
          nbMoisFacture: this.nbMoisFacture?.value,
          diametreFacturation: this.diametreFacturation?.value
        }
      })
    );
  }

  public getDiametres(): Observable<GeneriqueResponse> {
    return this.generiqueService.getAllList('DiametreDto').pipe(
      map((response: GeneriqueResponse) => {
        if ((response.Result?.length ?? 0) === 0) {
          response.Result = new Array<Option<number, string>>();
        } else {
          response.Result = response.Result.map((diametre: Diametre) =>
            new Diametre(diametre).toOption()
          ).sort((a: Option<number, string>, b: Option<number, string>) => a.key - b.key);
        }
        this.diametres = response.Result;
        return response;
      })
    );
  }

  public getInformationsFactureEntrantForProcess(): Observable<GeneriqueResponse> {
    let date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    return this.abonnementService
      .getInformationsFactureEntrantForProcess(date ? date : '', this.numAbonnement)
      .pipe(
        map((response: GeneriqueResponse) => {
          return response;
        })
      );
  }

  public getCalculFraisMiseEnService(): Observable<GeneriqueResponse> {
    return this.abonnementService.calculFraisMiseEnService(this.numAbonnement).pipe(
      map((response: GeneriqueResponse) => {
        this.factureEntrant.FraisAccesService = response.Result;
        return response;
      })
    );
  }
}
