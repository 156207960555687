import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RedirectMutationGuard implements CanActivate {
  constructor(private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    if (route.queryParams['actionByProcess'] == undefined)
      this.router.navigate(['abonnement/synthese/' + route.queryParams['id']]);
    else
      this.router.navigate([
        'abonnement/synthese/' +
          route.queryParams['id'] +
          '/' +
          route.queryParams['actionByProcess'] +
          '/' +
          route.queryParams['idTiersAbo'] +
          '/' +
          route.queryParams['idTiersDest']
      ]);
    return true;
  }
}
