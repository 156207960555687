import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { lastValueFrom, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/state/core.state';
import * as fromContext from 'src/app/core/state/context';
import * as fromHeader from 'src/app/core/state/header';
import { BaseComponent } from '../components/base/base.component';
import { getItemLocalStorage, getItemLocalStorageJson, setItemLocalStorage } from '../utils/localStorageUtils';
import { TokenService } from '../services/token.service';
import { Ressource } from 'src/app/api/models/class/Ressource';
import { AppConfig } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<State>,
    private router: Router,
    private tokenService: TokenService,
    private appConfig: AppConfig
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const build = this.appConfig.getConfig('VersionBuild');
    const build_navigator = getItemLocalStorage('VersionBuild');
    if (build != build_navigator) {
      this.store.dispatch({ type: '[Init]' });
      localStorage.clear();
      setItemLocalStorage('VersionBuild', build);
    }

    let tokenExist = await lastValueFrom(
      this.store.select(fromContext.selectContextAuthToken).pipe(take(1))
    );

    let ressourceExist = await lastValueFrom(
      this.store.select(fromContext.selectContextRessource).pipe(take(1))
    );

    let webFunctionExist = await lastValueFrom(
      this.store.select(fromHeader.selectWebFunctions).pipe(take(1))
    );

    if (!tokenExist || !ressourceExist) {
      console.log('Token not found in store, check in Local Storage');
      let tokenLS = getItemLocalStorage('token');
      let ressourceLS = getItemLocalStorageJson('ressource') as Ressource;

      if (tokenLS && ressourceLS) {
        tokenExist = tokenLS;
        this.tokenService.set(tokenLS);
        this.store.dispatch(
          fromContext.patchContext({
            payload: {
              authToken: tokenLS,
              urlRedirect: '',
              ressource: ressourceLS
            }
          })
        );
        console.log('After check in Local Storage', tokenExist);
      }

      if (webFunctionExist.length == 0) {
        let webfunctionLS = getItemLocalStorageJson('webfunction');
        if (webfunctionLS != null)
          this.store.dispatch(fromHeader.updateWebFunctions({ webFunctions: webfunctionLS }));
      }
    }

    if (tokenExist) {
      console.log('AuthGuard', 'OK');
      this.tokenService.set(tokenExist);
      return true;
    } else {
      BaseComponent.redirectToAuthentification(this.store, this.router, state.url);
      console.log('AuthGuard', 'KO');
      return false;
    }
  }
}
