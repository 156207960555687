import { createAction, props } from '@ngrx/store';
import { InfosTechniques } from 'src/app/core/models/infos-techniques.model';

export const patchInfosTechniques = createAction(
  '[Infos techniques] Patch Infos techniques',
  props<{ payload: InfosTechniques }>()
);

export const updateIsRadioReleve = createAction(
  '[Infos techniques] Update is radio releve',
  props<{ isRadioReleve: boolean }>()
);

export const updateIsTeleReleve = createAction(
  '[Infos techniques] Update is tele releve',
  props<{ isTeleReleve: boolean }>()
);

export const init = createAction('[Init]');
