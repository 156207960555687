export class RegexBank {
  static oneLetter: RegExp = /^[a-zA-Z]$/;
  static codeLengthFour: RegExp = /^\d{4}$/;
  static FirstLetterUppercaseWords: RegExp = new RegExp(/[A-Z][a-z]+/, 'g');
  static phone: RegExp =
    /^(?<indicator>(?<international>(?:\((?:\+|00)33\))|(?:\+|00)33)|0)\s?(?<type>(?<standard>[1-5])|(?<cellPhone>[6-7])|(?<company>8)|(?<standardVoIP>9))(?<number>(?:(?:\s\d{2}){4}|(?:\.\d{2}){4}|(?:-\d{2}){4}|\d{8}))$/;
  static telephone: RegExp = /^[0-9]{10}$/;
  static login: RegExp = /^[A-Za-z]+_[A-Za-z]{1}$/;
  static email: RegExp =
    /^(?<identifier>[a-zA-Z0-9_.+-]+)(?<domain>@[a-zA-Z0-9-_]+\.[a-zA-Z0-9-.]{2,})$/;
  static emailsSeparatedSemiComma: RegExp = new RegExp(
    /^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/
  );
  static matVeoliaAlphanumeric: RegExp = /^[a-zA-Z0-9]{0,6}$/;
  static decimalInput: RegExp = /^(\d*\.)?\d+$/;
  static password: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^\s\w\d]).{15,}$/;
  static mediumRegex: string = '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^\\s\\w\\d]).{10,}$'; // string format utilisé pour le module Password de PrimeNg à des fins de paramétrage
  static strongRegex: string = '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^\\s\\w\\d]).{15,}$'; // string format utilisé pour le module Password de PrimeNg à des fins de paramétrage
}
