import { createAction, props } from '@ngrx/store';
import { Context } from '../../models/context.model';

export const patchContext = createAction(
  '[Authentification] Patch Authentification info',
  props<{ payload: Context }>()
);

export const updateToken = createAction(
  '[Authentification] Update Token',
  props<{ authToken: string }>()
);

export const updateUrlRedirect = createAction(
  '[Authentification] Update Url Redirect',
  props<{ urlRedirect: string | null }>()
);

export const init = createAction('[Init]');
