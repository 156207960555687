import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InfosPdfState } from './infos-pdf.state';

export const selectInfosPdf = createFeatureSelector<InfosPdfState>('infosPdf');

export const selectShowPdf = createSelector(
  selectInfosPdf,
  (state: InfosPdfState) => state.showPdf
);
export const selectXmlData = createSelector(
  selectInfosPdf,
  (state: InfosPdfState) => state.xmlData
);
export const selectTitle = createSelector(selectInfosPdf, (state: InfosPdfState) => state.title);
export const selectDocumentContent = createSelector(
  selectInfosPdf,
  (state: InfosPdfState) => state.documentContent
);
