//#region Import
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccordionModule } from 'primeng/accordion';
import { MenubarModule } from 'primeng/menubar';
//#endregion

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { faInfoCircle, faTint } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { MenuItem, MessageService } from 'primeng/api';
import { WebFonctions } from 'src/app/api/models/interface/WebFunction';
import { AppConfig } from 'src/app/app.config';
import { AppResource } from 'src/app/app.resource';
import * as fromRoot from 'src/app/core/state/core.state';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { haveAuthorisation } from 'src/app/shared/utils/webFonctionsUtils';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  imports: [CommonModule, FontAwesomeModule, MenubarModule, AccordionModule, RouterLink],
  standalone: true
})
export class MenuComponent extends BaseComponent implements OnInit {
  @Input() isExpanded!: boolean;
  @Input() webFunctions!: WebFonctions;
  @Output() modifyTitle = new EventEmitter<string>();
  currentDate: string = '';
  tooltip: string = '';
  date: Date = new Date();
  months: string[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  menuItems!: MenuItem[];

  faTint = faTint;
  faInfoCircle = faInfoCircle;

  constructor(
    store: Store<fromRoot.State>,
    resources: AppResource,
    messageService: MessageService,
    private config: AppConfig,
    private router: Router
  ) {
    super(store, resources, messageService);
  }

  override async ngOnInit() {
    // get current date format YYYY.MM
    this.currentDate = this.config.getConfig('version');
    this.tooltip = `Release : WaterpWeb ${this.config.getConfig(
      'version'
    )} (${this.config.getConfig('version-net')})`;

    this.generateMenuItems();
  }

  generateMenuItems() {
    this.menuItems = [
      {
        label: this.resource.layout.abonnement,
        icon: 'icon waterpIcons-Abonnement',
        items: [
          {
            label: this.resource.layout.rechercheAbonnement,
            icon: '',
            routerLink: 'recherche-abonnement'
          },
          {
            label: this.resource.layout.rechercheClient,
            icon: '',
            routerLink: 'recherche-client'
          },
          {
            label: this.resource.layout.branchementEPNonGere,
            icon: '',
            routerLink: 'demande-branchement-non-geree'
          }
        ]
      },
      {
        label: this.resource.layout.intervention,
        icon: 'icon waterpIcons-Intervention',
        items: [
          {
            label: this.resource.layout.rechercheIntervention,
            icon: '',
            routerLink: 'recherche-intervention'
          },
          {
            label: this.resource.layout.rechercheLocalisation,
            icon: '',
            routerLink: 'intervention/recherche-localisation'
          }
        ]
      }
    ];

    if (haveAuthorisation('RESS_AUTH_MODIF', this.webFunctions)) {
      this.menuItems.push({
        label: this.resource.layout.organisation,
        icon: 'icon waterpIcons-Organisation',
        items: [
          {
            label: this.resource.layout.gestionAutorisation,
            icon: '',
            routerLink: 'organisation/autorisation-ressource'
          },
          {
            label: this.resource.layout.gestionRessources,
            icon: '',
            routerLink: 'organisation/gestion-ressources/search'
          },
          {
            label: this.resource.layout.gestionProfils,
            icon: '',
            routerLink: 'organisation/gestion-profils/list'
          }
        ]
      });
    }
  }

  setTitle(newTitle: string) {
    this.modifyTitle.emit(newTitle);
  }

  haveAutorisations(code: string, webfonctions: WebFonctions) {
    return haveAuthorisation(code, webfonctions);
  }

  redirectMenu(url: string) {
    this.router.navigate([url]);
  }
}
