import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EtatState } from './etat.state';

export const selectEtat = createFeatureSelector<EtatState>('etat');

export const selectIsMiseEnService = createSelector(
  selectEtat,
  (state: EtatState) => state.isMiseEnService
);

export const selectIsResiliable = createSelector(
  selectEtat,
  (state: EtatState) => state.isResiliable
);

export const selectIsEchangeable = createSelector(
  selectEtat,
  (state: EtatState) => state.isEchangeable
);

export const selectIsAnnulable = createSelector(
  selectEtat,
  (state: EtatState) => state.isAnnulable
);
