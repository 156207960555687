import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Authorisations } from 'src/app/api/models/class/authorisation/authorisations';
import { Profil } from 'src/app/api/models/class/profil';
import { IAuthorisations } from 'src/app/api/models/interface/authorisations/authorisation-profil.interface';
import { IRight } from 'src/app/api/models/interface/authorisations/right.interface';
import { ITemplateHabilitation } from 'src/app/api/models/interface/authorisations/template-habilitation.interface';

@Injectable({
  providedIn: 'root'
})
export class ProfilManagementService {
  habilitations: IAuthorisations = {
    AuthorisationWeb: {
      FonctionsWeb: new Array<IRight>(),
      InformationsSensiblesWeb: new Array<IRight>()
    },
    AuthorisationClientLourd: {
      TemplateHabilitations: new Array<ITemplateHabilitation>()
    }
  };
  profilForm: FormGroup = new FormGroup({});

  constructor() {}

  public initForm(
    profil: Profil = new Profil(),
    authorisations: Authorisations = new Authorisations()
  ): void {
    Object.entries(profil).map((field: [string, any]) =>
      this.profilForm.addControl(field[0], new FormControl(field[1] === '' ? null : field[1]))
    );
    this.profilForm.addControl('Authorisations', new FormControl(authorisations));
  }

  public submitProfil(): void {}
}
