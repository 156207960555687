import { Injectable } from '@angular/core';
import {

  ActivatedRouteSnapshot,
  Resolve,
  Router
} from '@angular/router';
import { Observable, map, tap } from 'rxjs';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { ProfilService } from 'src/app/api/services/profil.service';
import { Profil } from 'src/app/api/models/class/profil';

@Injectable({
  providedIn: 'root'
})
export class GetProfilsResolver implements Resolve<Profil[]> {
  constructor(private profilService: ProfilService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Profil[]> {
    return this.profilService.getProfils(route.params['codeProfil']).pipe(
      tap((response: GeneriqueResponse) =>
        [112, 121].includes(response.Code) ? this.router.navigateByUrl('authentification') : null
      ),
      map((response: GeneriqueResponse) => response.Result)
    );
  }
}
