import { Action, createReducer, on } from '@ngrx/store';
import {
  init,
  patchDossierCompte,
  updateIsMensualisable,
  updateIsFPA,
  updateIsRemboursable,
  updateIsFactureManuelle,
  updateIsAutreFactureManuelle,
  updateHasEcheancierFPA,
  updateHasEcheancierFPACB
} from './dossier-compte.actions';
import { initialState, DossierCompteState } from './dossier-compte.state';

const dossierCompteReducer = createReducer(
  initialState,
  on(patchDossierCompte, (state, action: ReturnType<typeof patchDossierCompte>) => ({
    ...state,
    ...action.payload
  })),
  on(
    updateIsMensualisable,
    (state, { isMensualisable }: ReturnType<typeof updateIsMensualisable>) => ({
      ...state,
      isMensualisable
    })
  ),
  on(updateIsFPA, (state, { isFPA }: ReturnType<typeof updateIsFPA>) => ({
    ...state,
    isFPA
  })),
  on(
    updateIsRemboursable,
    (state, { isRemboursable }: ReturnType<typeof updateIsRemboursable>) => ({
      ...state,
      isRemboursable
    })
  ),
  on(
    updateIsFactureManuelle,
    (state, { isFactureManuelle }: ReturnType<typeof updateIsFactureManuelle>) => ({
      ...state,
      isFactureManuelle
    })
  ),
  on(
    updateIsAutreFactureManuelle,
    (state, { isAutreFactureManuelle }: ReturnType<typeof updateIsAutreFactureManuelle>) => ({
      ...state,
      isAutreFactureManuelle
    })
  ),
  on(
    updateHasEcheancierFPA,
    (state, { hasEcheancierFPA }: ReturnType<typeof updateHasEcheancierFPA>) => ({
      ...state,
      hasEcheancierFPA
    })
  ),
  on(
    updateHasEcheancierFPACB,
    (state, { hasEcheancierFPACB }: ReturnType<typeof updateHasEcheancierFPACB>) => ({
      ...state,
      hasEcheancierFPACB
    })
  ),
  on(init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: DossierCompteState | undefined, action: Action) {
  return dossierCompteReducer(state, action);
}
