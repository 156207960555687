import { InfosPdf } from 'src/app/core/models/infos-pdf.model';

export type InfosPdfState = InfosPdf;

export const initialState: InfosPdfState = {
  showPdf: false,
  title: '',
  documentContent: [],
  xmlData: ''
};
