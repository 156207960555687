import { AbonnementDataService } from 'src/app/shared/services/abonnement-data.service';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AbonnementParTypeServiceService } from 'src/app/api/services/abonnementParTypeService.service';
import { TypesServicesService } from 'src/app/api/services/types-services.service';
import { Resource } from 'src/app/resources/resource';
import { AppResource } from 'src/app/app.resource';
import { DividerModule } from 'primeng/divider';
import { DragDropModule } from 'primeng/dragdrop';
import { NgVarDirective } from 'src/app/shared/directives/ng-var.directive';
import { ServiceComponent } from '../service/service.component';
import { TypeService } from 'src/app/api/models/class/type-service';
import { switchMap, take, tap } from 'rxjs';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/state/core.state';
import * as fromAbonnement from 'src/app/core/state/abonnement';
import { LibelleEtatAbonnement } from 'src/app/api/models/enum/EtatAbonnement.enum';
import { MessageService } from 'primeng/api';
import { MessageServiceUtils } from 'src/app/shared/utils/messageServiceUtils';
import { ServicesService } from '../services/services.service';

@Component({
  selector: 'waterp-services-manager',
  templateUrl: './services-manager.component.html',
  styleUrls: ['./services-manager.component.scss'],
  imports: [CommonModule, ServiceComponent, DividerModule, DragDropModule, NgVarDirective],
  standalone: true
})
export class ServicesManagerComponent {
  resource: Resource;
  messageServiceUtils: MessageServiceUtils;
  readonly: boolean = false;

  constructor(
    private store: Store<State>,
    private messageService: MessageService,
    private resources: AppResource,
    public servicesService: ServicesService,
    public subscribedServices: AbonnementParTypeServiceService,
    public availableServices: TypesServicesService
  ) {
    this.resource = this.resources['resource'];
    this.messageServiceUtils = new MessageServiceUtils(this.messageService, this.resources);
    this.store
      .select(fromAbonnement.selectEtatAbonnement)
      .pipe(take(1))
      .subscribe(
        (state: string | null) => (this.readonly = state === LibelleEtatAbonnement.Annule)
      );
  }
}
