import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { AppResource } from 'src/app/app.resource';
import { State } from 'src/app/core/state/core.state';
import * as fromWizard from 'src/app/core/state/wizard';
import { BaseComponent } from 'src/app/shared/components/base/base.component';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, CheckboxModule, FontAwesomeModule, InputTextModule],
  standalone: true
})
export class DocumentComponent extends BaseComponent implements OnInit {
  branchementRB!: string;

  @Output() validForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() previousForm: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() submitEvent = new EventEmitter<boolean>();
  @Input() lastStep: boolean = false;
  @Input() submitIsLoading!: boolean;

  faSpinner = faSpinner;
  faCheck = faCheck;

  //#region FormGroup
  documentForm!: FormGroup;
  //#endregion

  //#region GetFormControls
  get branchementEauPotable() {
    return this.documentForm.get('branchementEauPotable');
  }

  get branchementEauxUsees() {
    return this.documentForm.get('branchementEauxUsees');
  }

  get numeroDemandeTravO() {
    return this.documentForm.get('numeroDemandeTravO');
  }
  //#endregion

  constructor(
    store: Store<State>,
    resources: AppResource,
    messageService: MessageService
  ) {
    super(store, resources, messageService);

    //Initialisation des formulaires
    this.initForm();
  }

  override async ngOnInit() {
    this.documentForm.valueChanges.subscribe(() => {
      this.automaticSave();
    });
  }

  initForm() {
    this.documentForm = new FormGroup(
      {
        branchementEauPotable: new FormControl<boolean | null>(null),
        branchementEauxUsees: new FormControl<boolean | null>(null),
        numeroDemandeTravO: new FormControl<string | null>(null, Validators.maxLength(20))
      },
      {
        updateOn: 'change'
      }
    );
  }

  returnPreviousForm() {
    this.previousForm.emit(true);
  }

  submitForm() {
    this.submitIsLoading = true;
    if (this.branchementEauPotable?.value == true || this.branchementEauxUsees?.value == true) {
      if (this.lastStep) this.submitEvent.emit(true);
      else this.validForm.emit(true);

      setTimeout(() => {
        this.submitIsLoading = false;
      }, 2000);
    } else {
      this.submitIsLoading = false;
      this.messageServiceUtils.warning(this.resource.toast.infoBranchementDocument);
    }
  }

  cancelForm() {
    this.initForm();
  }

  automaticSave() {
    this.store.dispatch(
      fromWizard.updateDocument({
        payload: {
          branchementEauPotable: this.branchementEauPotable?.value ? true : false,
          branchementEauUsee: this.branchementEauxUsees?.value ? true : false,
          envoiCourrier: false,
          numeroDemandeTravO: this.numeroDemandeTravO?.value
        }
      })
    );
  }
}
