import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChangerMotDePasseRequest } from 'src/app/api/models/request/authentification/changerMotDePasseRequest';
import { EnvoyerEmailReinitialiserMotDePasseRequest } from 'src/app/api/models/request/authentification/envoyerEmailReinitialiserMotDePasseRequest';
import { ReinitialiserMotDePasseAvecTokenRequest } from 'src/app/api/models/request/authentification/reinitialiserMotDePasseAvecTokenRequest';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { AppConfig } from 'src/app/app.config';
import { WaterpHttpService } from 'src/app/shared/services/waterp-http.service';
import { BranchementNonGereeForm } from '../models/interface/BranchementNonGeree';
import { MettreAjourClientAdministratifRequest } from '../models/request/synthese-client/mettreAjourClientAdministratifRequest';
import { MettreAjoursClientAdresseRequest } from '../models/request/synthese-client/mettreAjourClientAdresseRequest';
import { MettreAjourClientInfoPesRequest } from '../models/request/synthese-client/mettreAjourClientInfoPesRequest';
import { MettreAjoursClientCommunicationRequest } from '../models/request/synthese-client/mettreAjoursClientCommunicationRequest';
import { MettreAjourClientFactureRequest } from '../models/request/synthese-client/mettreAjoursClientFactureRequest';
import { ParametersRequest } from '../models/shared/parametersRequest';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends AbstractService {
  parametersRequest!: ParametersRequest;
  constructor(config: AppConfig, private waterpHttpService: WaterpHttpService) {
    super(config);
  }

  public getIrisPath(): string {
    return this.irisPath;
  }

  public getPds360Path(): string {
    return this.pds360Path;
  }

  //#region /Client/Exist: Vérification de l'existence d'un client par son numéro
  /**
   * @param identifiant du client
   */
  public exist(identifiant: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Client/Exist',
      parameters: [{ name: 'identifiant', value: identifiant }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Client/Get: Récupération d'un client par son numéro
  /**
   * @param identifiant du client
   */
  public get(identifiant: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Client/Get/' + identifiant,
      parameters: []
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Client/GetInformationsAdministrative: Récupération des informations Administratives  d'un client par son numéro
  /**
   * @param numeroClient identifiant du client
   */
  public getInformationsAdministrative(numeroClient: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Client/GetInformationsAdministrative',
      parameters: [{ name: 'numeroClient', value: numeroClient }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Client/GetInformationsCommunication: Récupération des informations de communication d'un client par son numéro
  /**
   * @param identifiant du client
   */
  public getInformationsCommunication(identifiant: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Client/GetInformationsCommunication',
      parameters: [{ name: 'identifiant', value: identifiant }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Client/GetInformationsAdresse
  /**
   * @description Récupérer les informations d'adresse d'un client
   * @param numeroClient identifiant du client
   */
  public getInformationsAdresse(numeroClient: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Client/GetInformationsAdresse',
      parameters: [{ name: 'numeroClient', value: numeroClient }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Client/GetInformationsFacturation: Récupération des informations facture  d'un client par son numéro
  /**
   * @param numeroClient identifiant du client
   */
  public getInformationsFacturation(numeroClient: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Client/GetInformationsFacturation',
      parameters: [{ name: 'numeroClient', value: numeroClient }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Client/MettreAJourInformationFacturation : Modifier les informations de facture d'un client
  /**
   * @param body
   */
  public mettreAJourInformationFacturation(
    body?: MettreAjourClientFactureRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Client/MettreAJourInformationFacturation`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Client/MettreAJourInformationsCommunication : Modifier les informations de communication d'un client
  /**
   * @param body
   */
  public mettreAJourInformationsCommunication(
    body?: MettreAjoursClientCommunicationRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Client/MettreAJourInformationsCommunication`;
    return this.waterpHttpService.put(path, body);
  }
  //#endregion

  //#region /Client/MettreAJourInformationAdresse : Modifier les informations d'adresse d'un client
  /**
   * @param body
   */
  public mettreAJoursClientAdresse(
    body?: MettreAjoursClientAdresseRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Client/MettreAJourInformationAdresse`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Client/MettreAJourInformationAdministrative : Modifier les informations administratives d'un client
  /**
   * @param body
   */
  public mettreAJourInformationAdministrative(
    body?: MettreAjourClientAdministratifRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Client/MettreAJourInformationAdministrative`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion



  //#region /Client/DemandeBranchementContratNonGere: Effectue une demande de branchement pour un contrat non géré
  /**
   * @param body
   */
  public demandeBranchementContratNonGere(
    body?: BranchementNonGereeForm
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Client/DemandeBranchementContratNonGere`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Client/HasValideEmail: Vérifie si le client possède une adresse mail valide
  /**
   * @param numeroClient
   */
  public hasValideEmail(numeroClient: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Client/HasValideEmail',
      parameters: [{ name: 'numeroClient', value: numeroClient }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion
}
