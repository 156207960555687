import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { AppResource } from 'src/app/app.resource';
import { State } from 'src/app/core/state/core.state';
import { BaseComponent } from 'src/app/shared/components/base/base.component';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true
})
export class LoaderComponent extends BaseComponent {
  constructor(
    store: Store<State>,
    resources: AppResource,
    messageService: MessageService
  ) {
    super(store, resources, messageService);
  }
}
