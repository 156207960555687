import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from 'src/app/core/state/core.state';
import * as fromHeader from 'src/app/core/state/header';
import { Observable, take } from 'rxjs';
import { InterventionService } from 'src/app/api/services/intervention.service';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';

@Injectable({
  providedIn: 'root'
})
export class GetInterventionResolver implements Resolve<GeneriqueResponse> {
  constructor(
    private store: Store<fromRoot.State>,
    private interventionService: InterventionService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<GeneriqueResponse> {
    this.store.dispatch(
      fromHeader.updateTitle({
        title: 'Synthèse Intervention n°' + route.params['numIntervention']
      })
    );

    return this.interventionService.getInterventionByIdentifiant(route.params['numIntervention']);
  }
}
