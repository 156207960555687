import { Centre } from 'src/app/api/models/interface/Centre';
import { Commune } from 'src/app/api/models/interface/Commune';
import { Societe } from 'src/app/api/models/interface/Societe';
import { TypeVoie } from 'src/app/api/models/interface/TypeVoie';
import { RueResponse } from 'src/app/api/models/response/rue/rueResponse';
import { Option } from 'src/app/shared/models/option';

export class Rue implements RueResponse {
  CodeCommune: number;
  Numero: number;
  NumeroReseau: string;
  Nom: string;
  CodePostal: number | null;
  FormatedCP: string | null;
  Observations: string;
  IndicateurFeederGaz: string;
  IndicateurAssainissement: string;
  IndicateurEauBrute: string;
  IndicateurvoiePrivee: string;
  Longueur: number | null;
  MotCle1: string;
  motCle2: string;
  NumeroRueGirisDRSE: number | null;
  CoordonneesGPSx: number | null;
  CoordonneesGPSy: number | null;
  IdentifiantCanope: string;
  Centre: Centre;
  Societe: Societe;
  Commune: Commune;
  TypeVoie: TypeVoie;

  constructor(rue: RueResponse) {
    this.CodeCommune = rue?.CodeCommune ?? '';
    this.Numero = rue?.Numero ?? '';
    this.NumeroReseau = rue?.NumeroReseau ?? '';
    this.Nom = rue?.Nom ?? '';
    this.CodePostal = rue?.CodePostal;
    this.FormatedCP = rue?.FormatedCP;
    this.Observations = rue?.Observations ?? '';
    this.IndicateurFeederGaz = rue?.IndicateurFeederGaz ?? '';
    this.IndicateurAssainissement = rue?.IndicateurAssainissement ?? '';
    this.IndicateurEauBrute = rue?.IndicateurEauBrute ?? '';
    this.IndicateurvoiePrivee = rue?.IndicateurvoiePrivee ?? '';
    this.Longueur = rue?.Longueur;
    this.MotCle1 = rue?.MotCle1 ?? '';
    this.motCle2 = rue?.motCle2 ?? '';
    this.NumeroRueGirisDRSE = rue?.NumeroRueGirisDRSE ?? 0;
    this.CoordonneesGPSx = rue?.CoordonneesGPSx;
    this.CoordonneesGPSy = rue?.CoordonneesGPSy;
    this.IdentifiantCanope = rue?.IdentifiantCanope ?? '';
    this.Centre = rue?.Centre ?? {};
    this.Societe = rue?.Societe ?? {};
    this.Commune = rue?.Commune ?? {};
    this.TypeVoie = rue?.TypeVoie ?? {};
  }

  public toOption(): Option<number, string> {
    return new Option(this.Numero, (this.TypeVoie.Code ? this.TypeVoie.Code + ' ' : '') + this.Nom);
  }
}
