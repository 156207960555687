import { Flag } from '../enum/flag.enum';
import { Application } from '../interface/Application';
import { NiveauHabilitation } from '../interface/NiveauHabilitation';

export class Procedure {
  private Code: string;
  private CodeApplication: string;
  private Libelle: string;
  private IsActive: string;
  private Commentaire: string;
  private IndicateurEcriture: string;
  private Application!: Application;
  private NiveauHabilitation!: NiveauHabilitation;

  constructor(distantProcedure?: Procedure) {
    this.Code = distantProcedure?.Code ?? '';
    this.CodeApplication = distantProcedure?.CodeApplication ?? '';
    this.Libelle = distantProcedure?.Libelle ?? '';
    this.IsActive = distantProcedure?.IsActive ?? '';
    this.Commentaire = distantProcedure?.Commentaire ?? '';
    this.IndicateurEcriture = distantProcedure?.IndicateurEcriture ?? '';
    this.Application = distantProcedure?.Application ?? {
      Code: '',
      Commentaire: '',
      Libelle: ''
    };
    this.NiveauHabilitation = distantProcedure?.NiveauHabilitation ?? {
      Code: 0,
      Libelle: '',
      IsActive: Flag.INACTIVE
    };
  }

  public getCode(): string {
    return this.Code;
  }

  public setCode(Code: string): void {
    this.Code = Code;
  }

  public getCodeApplication(): string {
    return this.CodeApplication;
  }

  public setCodeApplication(CodeApplication: string): void {
    this.CodeApplication = CodeApplication;
  }

  public getLibelle(): string {
    return this.Libelle;
  }

  public setLibelle(Libelle: string): void {
    this.Libelle = Libelle;
  }

  public getIsActive(): string {
    return this.IsActive;
  }

  public setIsActive(IsActive: string): void {
    this.IsActive = IsActive;
  }

  public getCommentaire(): string {
    return this.Commentaire;
  }

  public setCommentaire(Commentaire: string): void {
    this.Commentaire = Commentaire;
  }

  public getIndicateurEcriture(): string {
    return this.IndicateurEcriture;
  }

  public setIndicateurEcriture(IndicateurEcriture: string): void {
    this.IndicateurEcriture = IndicateurEcriture;
  }

  public getApplication(): Application {
    return this.Application;
  }

  public setApplication(Application: Application): void {
    this.Application = Application;
  }

  public getNiveauHabilitation(): NiveauHabilitation {
    return this.NiveauHabilitation;
  }

  public setNiveauHabilitation(NiveauHabilitation: NiveauHabilitation): void {
    this.NiveauHabilitation = NiveauHabilitation;
  }
}
