export enum CodeEtatAbonnement {
  EnAttentePremiereUtilisation = '1',
  EnService = '2',
  Annule = '4',
  Suspendu = '8',
  Resilie = '9'
}

export enum LibelleEtatAbonnement {
  EnAttentePremiereUtilisation = 'EN ATTENTE DE 1ERE UTILISATION',
  EnService = 'EN SERVICE',
  Annule = 'ANNULE',
  Suspendu = 'SUSPENDU LE',
  Resilie = 'RESILIE',
  ResilieLe = 'RESILIE LE'
}
export enum TextEtatAbonnement {
  EnAttentePremiereUtilisation = 'En Attente',
  EnService = 'En Service',
  Annule = 'Annulé',
  Suspendu = 'Suspendu',
  Resilie = 'Résilié',
  Inconnu = 'Etat Abonnement non connu'
}
