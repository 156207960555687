import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { lastValueFrom, take } from 'rxjs';
import { CodeActiviteAbonnement } from 'src/app/api/models/enum/CodeActiviteAbonnement.enum';
import { CodeCategorieAbonnement } from 'src/app/api/models/enum/CodeCategorieAbonnement.enum';
import { CodeParticulariteAbonnement } from 'src/app/api/models/enum/CodeParticulariteAbonnement.enum';
import { CodeSensibiliteAbonnement } from 'src/app/api/models/enum/CodeSensibiliteAbonnement.enum';
import { CodeTypeHabitation } from 'src/app/api/models/enum/CodeTypeHabitation';
import { CodeTypeRisque } from 'src/app/api/models/enum/CodeTypeRisque';
import { TypeUtilisateur } from 'src/app/api/models/enum/TypeUtilisateur.enum';
import { DropDownList } from 'src/app/api/models/interface/KeyValue';
import { ListCategorieAbonnementResponse } from 'src/app/api/models/response/generique/listCategorieAbonnementResponse';
import { AbonnementService } from 'src/app/api/services/abonnement.service';
import { ParametrageGeneriqueService } from 'src/app/api/services/parametrageGenerique.service';
import { AppResource } from 'src/app/app.resource';
import { InfoSegmentation } from 'src/app/core/models/wizard.model';
import { State } from 'src/app/core/state/core.state';
import * as fromWizard from 'src/app/core/state/wizard';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { ApiResponseBodyUtils } from 'src/app/shared/utils/apiResponseBodyUtils';
import { getDataFromLocaleStorage } from 'src/app/shared/utils/localStorageUtils';
import { InformationsSegmentationResponse } from '../../../../../api/models/response/abonnement/InformationsSegmentationResponse';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputNumberModule } from 'primeng/inputnumber';
@Component({
  selector: 'app-information-segmentation',
  templateUrl: './information-segmentation.component.html',
  styleUrls: ['./information-segmentation.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    KeyFilterModule,
    InputNumberModule
  ],
  standalone: true
})
export class InformationSegmentationComponent extends BaseComponent implements OnInit {
  activites: DropDownList[] = [];
  typesAbonne: DropDownList[] = [];
  typesUtilisateur: DropDownList[] = [];
  particularitesContrat: DropDownList[] = [];
  typesHabitation: DropDownList[] = [];
  sensibilites: DropDownList[] = [];
  typesClientRisque: DropDownList[] = [];

  infoSegmentationState!: InfoSegmentation;

  @Input() numAbonnement!: string | null;
  @Output() validForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() previousForm: EventEmitter<boolean> = new EventEmitter<boolean>();

  //#region FormGroup
  infoSegmentationForm!: FormGroup;
  //#endregion

  //#region GetFormControls
  get nombreOccupant() {
    return this.infoSegmentationForm.get('nombreOccupant');
  }

  get observation() {
    return this.infoSegmentationForm.get('observation');
  }

  get commentaire() {
    return this.infoSegmentationForm.get('commentaire');
  }

  get activite() {
    return this.infoSegmentationForm.get('activite');
  }

  get typeAbonne() {
    return this.infoSegmentationForm.get('typeAbonne');
  }

  get typeUtilisateur() {
    return this.infoSegmentationForm.get('typeUtilisateur');
  }

  get particulariteContrat() {
    return this.infoSegmentationForm.get('particulariteContrat');
  }

  get typeHabitation() {
    return this.infoSegmentationForm.get('typeHabitation');
  }

  get sensibilite() {
    return this.infoSegmentationForm.get('sensibilite');
  }

  get typeClientRisque() {
    return this.infoSegmentationForm.get('typeClientRisque');
  }
  //#endregion

  constructor(
    store: Store<State>,
    resources: AppResource,
    messageService: MessageService,
    private parametrageGeneriqueService: ParametrageGeneriqueService,
    private abonnementService: AbonnementService
  ) {
    super(store, resources, messageService);

    //Initialisation des formulaires
    this.initForm();
  }

  override async ngOnInit() {
    super.ngOnInit();

    //Initialisation du refDataManager
    await this.initDataManager();

    this.activite?.valueChanges.subscribe(async res => {
      this.typesAbonne = [];
      if (res) {
        this.typeAbonne?.enable();
        ApiResponseBodyUtils.ExtractResponseBody<ListCategorieAbonnementResponse>(
          await lastValueFrom(
            this.abonnementService.rechercherCategorieAbonnementByActivite(res.Key)
          )
        ).forEach(res => {
          this.typesAbonne.push({ Key: res.Code, Value: res.Libelle });
        });

        let typeAbonne = this.infoSegmentationState.typeAbonne
          ? this.infoSegmentationState.typeAbonne
          : CodeCategorieAbonnement.Proprietaire;
        this.typeAbonne?.setValue(
          this.typesAbonne.find((obj: DropDownList) => obj.Key === typeAbonne)
        );
      } else {
        this.typeAbonne?.disable();
        this.typeUtilisateur?.disable();
      }
    });

    this.typeAbonne?.valueChanges.subscribe(async res => {
      this.typesUtilisateur = [];
      if (res) {
        this.typeUtilisateur?.enable();
        ApiResponseBodyUtils.ExtractResponseBody<ListCategorieAbonnementResponse>(
          await lastValueFrom(
            this.abonnementService.rechercherSousCategorieAbonnement(
              this.activite?.value.Key,
              res.Key
            )
          )
        ).forEach(res => {
          this.typesUtilisateur.push({ Key: res.Code, Value: res.Libelle });
        });
        let typeUtilisateur = this.infoSegmentationState.typeUtilisateur
          ? this.infoSegmentationState.typeUtilisateur
          : TypeUtilisateur.HabitationIndividuelle;
        this.typeUtilisateur?.setValue(
          this.typesUtilisateur.find((obj: DropDownList) => obj.Key === typeUtilisateur)
        );
      } else this.typeUtilisateur?.disable();
    });

    await this.initData();

    this.infoSegmentationForm.valueChanges.subscribe(() => {
      this.automaticSave();
    });
  }

  //#region Initialisation DataManager
  async initDataManager() {
    this.activites = await getDataFromLocaleStorage(
      this.resource.refDataManager.activite,
      this.parametrageGeneriqueService
    );

    this.typesHabitation = await getDataFromLocaleStorage(
      this.resource.refDataManager.typeHabitation,
      this.parametrageGeneriqueService
    );

    this.particularitesContrat = await getDataFromLocaleStorage(
      this.resource.refDataManager.particulariteAbonnement,
      this.parametrageGeneriqueService
    );

    this.sensibilites = await getDataFromLocaleStorage(
      this.resource.refDataManager.sensibiliteAbonnement,
      this.parametrageGeneriqueService
    );

    this.typesClientRisque = await getDataFromLocaleStorage(
      this.resource.refDataManager.typeAbonnementARisque,
      this.parametrageGeneriqueService
    );
  }
  //#endregion

  initForm() {
    this.infoSegmentationForm = new FormGroup(
      {
        nombreOccupant: new FormControl<number | null>(null, Validators.maxLength(3)),
        observation: new FormControl<string | null>(null, Validators.maxLength(35)),
        commentaire: new FormControl<string | null>(null, Validators.maxLength(70)),
        activite: new FormControl<DropDownList | null>(null, Validators.required),
        typeAbonne: new FormControl<DropDownList | null>(null, Validators.required),
        typeUtilisateur: new FormControl<DropDownList | null>(null, Validators.required),
        particulariteContrat: new FormControl<DropDownList | null>(null, Validators.required),
        typeHabitation: new FormControl<DropDownList | null>(null, Validators.required),
        sensibilite: new FormControl<DropDownList | null>(null, Validators.required),
        typeClientRisque: new FormControl<DropDownList | null>(null, Validators.required)
      },
      {
        updateOn: 'change'
      }
    );
    this.typeAbonne?.disable();
    this.typeUtilisateur?.disable();
  }

  async initData() {
    // Si numéro abonnement existe alors on récupère les infos seg de l'abonnement
    if (this.numAbonnement) {
      let segmentation = ApiResponseBodyUtils.ExtractResponseBody<InformationsSegmentationResponse>(
        await lastValueFrom(this.abonnementService.getInformationsSegmentation(this.numAbonnement))
      );

      this.store.dispatch(
        fromWizard.updateInfoSegmentation({
          payload: {
            activite: segmentation.CodeActivite,
            typeAbonne: segmentation.CodeCategorieAbonnement,
            typeUtilisateur: segmentation.CodeSousCategorieAbonnement,
            particulariteContrat: null,
            typeHabitation: null,
            sensibilite: null,
            typeClientRisque: null
          }
        })
      );
    }
    this.infoSegmentationState = await lastValueFrom(
      this.store.select(fromWizard.selectInfoSegmentationData).pipe(take(1))
    );

    this.nombreOccupant?.setValue(
      this.infoSegmentationState.nombreOccupant ? this.infoSegmentationState.nombreOccupant : ''
    );
    this.observation?.setValue(
      this.infoSegmentationState.observation ? this.infoSegmentationState.observation : ''
    );
    this.commentaire?.setValue(
      this.infoSegmentationState.commentaire ? this.infoSegmentationState.commentaire : ''
    );

    let activite = this.infoSegmentationState.activite
      ? this.infoSegmentationState.activite
      : CodeActiviteAbonnement.Particulier;

    this.activite?.setValue(this.activites.find((obj: DropDownList) => obj.Key === activite));

    this.particulariteContrat?.setValue(
      this.particularitesContrat.find(
        (obj: DropDownList) =>
          obj.Key ===
          (this.infoSegmentationState.particulariteContrat ?? CodeParticulariteAbonnement.Ras)
      )
    );

    this.sensibilite?.setValue(
      this.sensibilites.find(
        (obj: DropDownList) =>
          obj.Key === (this.infoSegmentationState.sensibilite ?? CodeSensibiliteAbonnement.Ras)
      )
    );
    this.typeClientRisque?.setValue(
      this.typesClientRisque.find(
        (obj: DropDownList) =>
          obj.Key === (this.infoSegmentationState.typeClientRisque ?? CodeTypeRisque.Default)
      )
    );

    this.typeHabitation?.setValue(
      this.typesHabitation.find(
        (obj: DropDownList) =>
          obj.Key ===
          (this.infoSegmentationState.typeHabitation ?? Number(CodeTypeHabitation.Principal))
      )
    );
  }

  returnPreviousForm() {
    this.previousForm.emit(true);
  }

  submitForm() {
    if (this.infoSegmentationForm.valid) this.validForm.emit(true);
    else this.infoSegmentationForm.markAllAsTouched();
  }

  async cancelForm() {
    await this.initData();
  }

  automaticSave() {
    this.store.dispatch(
      fromWizard.updateInfoSegmentation({
        payload: {
          nombreOccupant: this.nombreOccupant?.value != '' ? this.nombreOccupant?.value : null,
          observation: this.observation?.value,
          commentaire: this.commentaire?.value,
          activite: this.activite?.value?.Key,
          typeAbonne: this.typeAbonne?.value?.Key,
          typeUtilisateur: this.typeUtilisateur?.value?.Key,
          particulariteContrat: this.particulariteContrat?.value?.Key,
          typeHabitation: this.typeHabitation?.value?.Key,
          sensibilite: this.sensibilite?.value?.Key,
          typeClientRisque: this.typeClientRisque?.value?.Key
        }
      })
    );
  }
}
