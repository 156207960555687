import { createAction, props } from '@ngrx/store';
import { InfosPdf } from 'src/app/core/models/infos-pdf.model';

export const patchInfosPdf = createAction('[PDF] Patch infosPdf', props<{ payload: InfosPdf }>());

export const updateShowPdf = createAction('[PDF] Update showPdf', props<{ showPdf: boolean }>());

export const updateTitle = createAction('[PDF] Update title', props<{ title: string }>());
export const updateXmlData = createAction('[PDF] Update xmlData', props<{ xmlData: string }>());
export const updateDocumentContent = createAction(
  '[PDF] Update documentContent',
  props<{ documentContent: number[] }>()
);

export const init = createAction('[Init]');
