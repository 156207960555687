import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { TiersResponse } from 'src/app/api/models/response/tiers/tiersResponse';
import { TiersService } from 'src/app/api/services/tiers.service';
import { AppResource } from 'src/app/app.resource';
import { State } from 'src/app/core/state/core.state';
import * as fromHeader from 'src/app/core/state/header';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { FrameComponent } from 'src/app/shared/components/frame/frame.component';
import { WizardComponent } from 'src/app/shared/components/wizard/wizard/wizard.component';
import { WizardStep } from 'src/app/shared/models/wizardStep';
import { ApiResponseBodyUtils } from 'src/app/shared/utils/apiResponseBodyUtils';

@Component({
  selector: 'app-nouvel-abonnement',
  templateUrl: './nouvel-abonnement.component.html',
  styleUrls: ['./nouvel-abonnement.component.scss'],
  imports: [CommonModule, FrameComponent, WizardComponent],
  standalone: true
})
export class NouvelAbonnementComponent extends BaseComponent implements OnInit {
  wizardSteps: WizardStep[] = [];

  idTiers!: string | null;
  tiers!: TiersResponse;

  codeINSEE!: string;

  constructor(
    store: Store<State>,
    resources: AppResource,
    messageService: MessageService,
    private tierService: TiersService,
    private activatedRoute: ActivatedRoute
  ) {
    super(store, resources, messageService);

    this.idTiers = this.activatedRoute.snapshot.paramMap.get('idTiersAbo');

    let codeINSEELocal = this.activatedRoute.snapshot.paramMap.get('codeInsee');
    if (codeINSEELocal) this.codeINSEE = codeINSEELocal;

    this.wizardSteps = [
      {
        step: 1,
        libelle: this.resource.wizard.localisation,
        srcIcon: this.resource.svgPath.location_g,
        srcIconShow: this.resource.svgPath.location_w,
        srcIconValid: this.resource.svgPath.location_b,
        srcIconTip: '',
        ignoreStep: false,
        tipIsPossible: false,
        show: true,
        valid: false
      },
      {
        step: 2,
        libelle: this.resource.wizard.informationsClient,
        srcIcon: this.resource.svgPath.client_g,
        srcIconShow: this.resource.svgPath.client_w,
        srcIconValid: this.resource.svgPath.client_b,
        srcIconTip: '',
        ignoreStep: false,
        tipIsPossible: false,
        show: false,
        valid: false
      },
      {
        step: 3,
        libelle: this.resource.wizard.informationsSegmentation,
        srcIcon: this.resource.svgPath.abonnement_g,
        srcIconShow: this.resource.svgPath.abonnement_w,
        srcIconValid: this.resource.svgPath.abonnement_b,
        srcIconTip: '',
        ignoreStep: false,
        tipIsPossible: false,
        show: false,
        valid: false
      },
      {
        step: 4,
        libelle: this.resource.wizard.referencesBancaire,
        srcIcon: this.resource.svgPath.payment_g,
        srcIconShow: this.resource.svgPath.payment_w,
        srcIconValid: this.resource.svgPath.payment_b,
        srcIconTip: this.resource.svgPath.payment_s,
        ignoreStep: false,
        tipIsPossible: true,
        show: false,
        valid: false
      },
      {
        step: 5,
        libelle: this.resource.wizard.informationsInstallation,
        srcIcon: this.resource.svgPath.index_g,
        srcIconShow: this.resource.svgPath.index_w,
        srcIconValid: this.resource.svgPath.index_b,
        srcIconTip: '',
        ignoreStep: false,
        tipIsPossible: false,
        show: false,
        valid: false
      },
      {
        step: 6,
        libelle: this.resource.wizard.ProprietaireGerant,
        srcIcon: this.resource.svgPath.vpnKey_g,
        srcIconShow: this.resource.svgPath.vpnKey_w,
        srcIconValid: this.resource.svgPath.vpnKey_b,
        srcIconTip: '',
        ignoreStep: false,
        tipIsPossible: false,
        show: false,
        valid: false
      },
      {
        step: 7,
        libelle: this.resource.wizard.factureEntrant,
        srcIcon: this.resource.svgPath.factureEntrant_g,
        srcIconShow: this.resource.svgPath.factureEntrant_w,
        srcIconValid: '',
        srcIconTip: '',
        ignoreStep: false,
        tipIsPossible: false,
        show: false,
        valid: false
      }
    ];
  }

  override async ngOnInit() {
    let title = 'Nouvel Abonnement';
    this.store.dispatch(fromHeader.updateTitle({ title }));

    if (this.idTiers) {
      this.tiers = ApiResponseBodyUtils.ExtractResponseBody<TiersResponse>(
        await lastValueFrom(this.tierService.get(this.idTiers))
      );
    }
  }
}
