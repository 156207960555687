import { KitCourrier } from '../interface/Courrier';

export class TypeService {
  private Code: string;
  private Libelle: string;
  private IndicateurDecoupageCommune: string;
  private ReferencePlaquettePub: string;
  private ImageEnteteMail: string;
  private LibelleArgumentaireBulle: string;
  private NomBitmap: string;
  private IndicateurFacturationAnnee: string;
  private IndicateurFacturationAuPorata: string;
  private PositionFicheSynthese?: number;
  private IndicateurServiceCreeAvecAbonnement: string;
  private NombreJoursDecalageResiliation?: number;
  private IndicateurValeurEditeur: string;
  private IndicateurEquipementObligatoire?: boolean;
  private ModeleMailPub: string;
  private TraceCourrier: string;
  private KitCourrierPub?: KitCourrier;

  constructor(distantTypeService?: TypeService) {
    this.Code = distantTypeService?.Code ?? '';
    this.Libelle = distantTypeService?.Libelle ?? '';
    this.IndicateurDecoupageCommune = distantTypeService?.IndicateurDecoupageCommune ?? '';
    this.ReferencePlaquettePub = distantTypeService?.ReferencePlaquettePub ?? '';
    this.ImageEnteteMail = distantTypeService?.ImageEnteteMail ?? '';
    this.LibelleArgumentaireBulle = distantTypeService?.LibelleArgumentaireBulle ?? '';
    this.NomBitmap = distantTypeService?.NomBitmap ?? '';
    this.IndicateurFacturationAnnee = distantTypeService?.IndicateurFacturationAnnee ?? '';
    this.IndicateurFacturationAuPorata = distantTypeService?.IndicateurFacturationAuPorata ?? '';
    this.PositionFicheSynthese = distantTypeService?.PositionFicheSynthese;
    this.IndicateurServiceCreeAvecAbonnement =
      distantTypeService?.IndicateurServiceCreeAvecAbonnement ?? '';
    this.NombreJoursDecalageResiliation = distantTypeService?.NombreJoursDecalageResiliation;
    this.IndicateurValeurEditeur = distantTypeService?.IndicateurValeurEditeur ?? '';
    this.IndicateurEquipementObligatoire = distantTypeService?.IndicateurEquipementObligatoire;
    this.ModeleMailPub = distantTypeService?.ModeleMailPub ?? '';
    this.TraceCourrier = distantTypeService?.TraceCourrier ?? '';
    if (distantTypeService?.KitCourrierPub)
      this.KitCourrierPub = distantTypeService?.KitCourrierPub;
  }

  //#region Accesseurs
  public getCode(): string {
    return this.Code;
  }

  public setCode(Code: string): void {
    this.Code = Code;
  }

  public getLibelle(): string {
    return this.Libelle;
  }

  public setLibelle(libelle: string): void {
    this.Libelle = libelle;
  }

  public getIndicateurDecoupageCommune(): string {
    return this.IndicateurDecoupageCommune;
  }

  public setIndicateurDecoupageCommune(indicateurDecoupageCommune: string): void {
    this.IndicateurDecoupageCommune = indicateurDecoupageCommune;
  }

  public getReferencePlaquettePub(): string {
    return this.ReferencePlaquettePub;
  }

  public setReferencePlaquettePub(referencePlaquettePub: string): void {
    this.ReferencePlaquettePub = referencePlaquettePub;
  }

  public getImageEnteteMail(): string {
    return this.ImageEnteteMail;
  }

  public setImageEnteteMail(imageEnteteMail: string): void {
    this.ImageEnteteMail = imageEnteteMail;
  }

  public getLibelleArgumentaireBulle(): string {
    return this.LibelleArgumentaireBulle;
  }

  public setLibelleArgumentaireBulle(libelleArgumentaireBulle: string): void {
    this.LibelleArgumentaireBulle = libelleArgumentaireBulle;
  }

  public getNomBitmap(): string {
    return this.NomBitmap;
  }

  public setNomBitmap(nomBitmap: string): void {
    this.NomBitmap = nomBitmap;
  }

  public getIndicateurFacturationAnnee(): string {
    return this.IndicateurFacturationAnnee;
  }

  public setIndicateurFacturationAnnee(indicateurFacturationAnnee: string): void {
    this.IndicateurFacturationAnnee = indicateurFacturationAnnee;
  }

  public getIndicateurFacturationAuPorata(): string {
    return this.IndicateurFacturationAuPorata;
  }

  public setIndicateurFacturationAuPorata(indicateurFacturationAuPorata: string): void {
    this.IndicateurFacturationAuPorata = indicateurFacturationAuPorata;
  }

  public getPositionFicheSynthese(): number | undefined {
    return this.PositionFicheSynthese;
  }

  public setPositionFicheSynthese(positionFicheSynthese: number): void {
    this.PositionFicheSynthese = positionFicheSynthese;
  }

  public getIndicateurServiceCreeAvecAbonnement(): string {
    return this.IndicateurServiceCreeAvecAbonnement;
  }

  public setIndicateurServiceCreeAvecAbonnement(indicateurServiceCreeAvecAbonnement: string): void {
    this.IndicateurServiceCreeAvecAbonnement = indicateurServiceCreeAvecAbonnement;
  }

  public getNombreJoursDecalageResiliation(): number | undefined {
    return this.NombreJoursDecalageResiliation;
  }

  public setNombreJoursDecalageResiliation(nombreJoursDecalageResiliation: number): void {
    this.NombreJoursDecalageResiliation = nombreJoursDecalageResiliation;
  }

  public getIndicateurValeurEditeur(): string {
    return this.IndicateurValeurEditeur;
  }

  public setIndicateurValeurEditeur(indicateurValeurEditeur: string): void {
    this.IndicateurValeurEditeur = indicateurValeurEditeur;
  }

  public isIndicateurEquipementObligatoire(): boolean | undefined {
    return this.IndicateurEquipementObligatoire;
  }

  public setIndicateurEquipementObligatoire(indicateurEquipementObligatoire: boolean): void {
    this.IndicateurEquipementObligatoire = indicateurEquipementObligatoire;
  }

  public getModeleMailPub(): string {
    return this.ModeleMailPub;
  }

  public setModeleMailPub(modeleMailPub: string): void {
    this.ModeleMailPub = modeleMailPub;
  }

  public getTraceCourrier(): string {
    return this.TraceCourrier;
  }

  public setTraceCourrier(traceCourrier: string): void {
    this.TraceCourrier = traceCourrier;
  }

  public getKitCourrierPub(): KitCourrier | undefined {
    return this.KitCourrierPub;
  }

  public setKitCourrierPub(kitCourrierPub: KitCourrier): void {
    this.KitCourrierPub = kitCourrierPub;
  }
  //#endregion
}
