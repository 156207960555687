import { createAction, props } from '@ngrx/store';
import { Card, Historique } from '../../models/historique.model';

export const updateHistoriqueCard = createAction(
  '[Header] Update Historique card',
  props<{ cards: Card[] }>()
);
export const updateHistoriqueIndex = createAction(
  '[Header] Update Historique index',
  props<{ index: number }>()
);
export const init = createAction('[Init]');
