import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { AppResource } from 'src/app/app.resource';
import * as fromContext from 'src/app/core/state/context';
import * as fromRoot from 'src/app/core/state/core.state';
import { Resource } from 'src/app/resources/resource';
import { removeItemLocalStorage } from '../../utils/localStorageUtils';
import { MessageServiceUtils } from '../../utils/messageServiceUtils';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
  public resource: Resource;
  public messageServiceUtils: MessageServiceUtils;

  constructor(
    protected store: Store<fromRoot.State>,
    protected resources: AppResource,
    protected messageService: MessageService
  ) {
    this.resource = resources['resource'];
    this.messageServiceUtils = new MessageServiceUtils(this.messageService, this.resources);
  }

  ngOnInit() {}

  static redirectToAuthentification(store: Store<fromRoot.State>, router: Router, url?: string) {
    store.dispatch({ type: '[Init]' });

    if (url) {
      store.dispatch(fromContext.updateUrlRedirect({ urlRedirect: url.substring(1) }));
    }

    removeItemLocalStorage('token');
    removeItemLocalStorage('ressource');
    removeItemLocalStorage('webfunction');

    router.navigate(['authentification']);
  }
}
