<app-frame class="photos" title="Photos interventions" [bounceIn]="true">
  <i class="pi pi-times content-header" (click)="closeDiv()"></i>
  <div class="content-frame">
    <div class="no-photo" *ngIf="!currentPhoto">
      <fa-icon [icon]="faCamera"></fa-icon>
    </div>
    <div class="photo-container">
      <div *ngIf="currentPhoto" class="header leading-8 h-8 bg-grey flex flex-row-reverse">
        <fa-icon [icon]="faRotate" pTooltip="Pivoter la photo" (click)="rotatePhoto()"></fa-icon>
        <fa-icon [icon]="faPrint" pTooltip="Imprimer la photo" (click)="printPhoto()"></fa-icon>
        <fa-icon
          [icon]="faExternalLink"
          pTooltip="Ouvrir dans un nouvel onglet"
          (click)="openNewTab()"></fa-icon>
        <fa-icon
          [icon]="faDownload"
          pTooltip="Télécharger la photo"
          (click)="downloadPhoto()"></fa-icon>
      </div>
      <div class="photo" [ngClass]="getClassRotation()" *ngIf="currentPhoto">
        <img [src]="getCurrentPhoto()" alt="Photo intervention" />
      </div>
    </div>
    <div class="thumbnails space-x-5" *ngIf="tabPhotos && tabPhotos.length > 0 && currentPhoto">
      <img
        class="thumbnail"
        *ngFor="let photo of tabPhotos"
        [ngClass]="{ selected: photo.id === currentPhoto.id }"
        [src]="getUrlPhoto(photo.data)"
        alt="Photo intervention"
        (click)="setCurrentPhoto(photo)" />
    </div>
  </div>
</app-frame>
