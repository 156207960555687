import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { Configuration } from '../configuration';
import { Observable } from 'rxjs';
import { GeneriqueResponse } from '../models/shared/generiqueResponse';

@Injectable({
  providedIn: 'root'
})
export class AbstractService {
  protected basePath = 'apiUrl';
  protected irisPath = 'URL_BASE_IRIS_Configuration';
  protected pds360Path = 'PDS360_URL_Configuration';

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(private config: AppConfig) {
    this.basePath = config.getConfig(this.basePath);
    this.irisPath = config.getConfig(this.irisPath);
    this.pds360Path = config.getConfig(this.pds360Path);
  }
}
