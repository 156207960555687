<div class="flex">
  <app-frame [title]="resource.nouvelAbonnement.titre" class="flex-1 w-full mx-5 space-x-5 my-4"
    ><div class="content-frame">
      <app-wizard
        [wizardSteps]="wizardSteps"
        [codeINSEE]="codeINSEE"
        [tiers]="tiers"></app-wizard>
    </div>
  </app-frame>
</div>

