import { MessageServiceUtils } from './../../../../../shared/utils/messageServiceUtils';
import { TypeService } from 'src/app/api/models/class/type-service';
import { TypesServicesService } from './../../../../../api/services/types-services.service';
import { AbonnementDataService } from './../../../../../shared/services/abonnement-data.service';
import { Injectable } from '@angular/core';
import { Subscription, switchMap, tap } from 'rxjs';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { AbonnementParTypeServiceService } from 'src/app/api/services/abonnementParTypeService.service';
import { Resource } from 'src/app/resources/resource';
import { MessageService } from 'primeng/api';
import { AppResource } from 'src/app/app.resource';
import { AbonnementParTypeService } from 'src/app/api/models/class/abonnement-par-type-service';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  resource: Resource;
  messageServiceUtils: MessageServiceUtils;
  draggedService?: TypeService;

  constructor(
    private messageService: MessageService,
    private resources: AppResource,
    private abonnementDataService: AbonnementDataService,
    private subscribedServices: AbonnementParTypeServiceService,
    private availableServices: TypesServicesService
  ) {
    this.resource = this.resources['resource'];
    this.messageServiceUtils = new MessageServiceUtils(this.messageService, this.resources);
  }

  /**
   * Permet d'activer un service pour l'abonnement concerné
   * @param service Service auquel l'abonnement va souscrire
   */
  public activate(service: TypeService): void {
    this.subscribedServices.isLoading = true;
    this.availableServices.isLoading = true;
    let subscription: Subscription = this.abonnementDataService.numAbonnement$
      .pipe(
        switchMap((value: string) =>
          this.availableServices
            .activateService({
              numeroAbonnement: value,
              codeTypeService: service.getCode() ?? ''
            })
            .pipe(
              tap((response: GeneriqueResponse) => {
                if (response.Code === 100) {
                  this.subscribedServices.getAllByAbonnement(value).subscribe();
                  this.availableServices.getAvailableServices(value).subscribe();
                } else {
                  this.subscribedServices.isLoading = false;
                  this.availableServices.isLoading = false;
                }
              })
            )
        )
      )
      .subscribe({
        next: (response: GeneriqueResponse) => {
          response.Code === 100
            ? this.messageServiceUtils.success()
            : this.messageServiceUtils.warning(
                response.Description ?? this.resource.toast.errorDescription
              );
          this.draggedService = undefined;
          subscription.unsubscribe();
        },
        error: () => {
          this.draggedService = undefined;
          subscription.unsubscribe();
        }
      });
  }

  /**
   * Permet de changer l'état du service en fonction de son état suivant s'il existe
   * @param service Service ciblé par le changement d'état
   */
  public changeState(service: AbonnementParTypeService): void {
    this.subscribedServices.isLoading = true;
    let subscription: Subscription = this.abonnementDataService.numAbonnement$
      .pipe(
        switchMap((value: string) =>
          this.availableServices
            .manageEtatService({
              NumeroAbonnement: value,
              CodeTypeService: service.getTypeService()?.getCode(),
              CodeEtatServiceSuivant: service.getNextCode()
            })
            .pipe(
              tap((response: GeneriqueResponse) => {
                if (response.Code === 100)
                  this.subscribedServices.getAllByAbonnement(value).subscribe();
                else this.subscribedServices.isLoading = false;
              })
            )
        )
      )
      .subscribe({
        next: (response: GeneriqueResponse) => {
          response.Code === 100
            ? this.messageServiceUtils.success()
            : this.messageServiceUtils.warning(
                response.Description ?? this.resource.toast.errorDescription
              );
          this.draggedService = undefined;
          subscription.unsubscribe();
        },
        error: () => {
          this.draggedService = undefined;
          subscription.unsubscribe();
        }
      });
  }
}
