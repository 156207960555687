import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss'],
  imports: [CommonModule],
  standalone: true
})
export class FrameComponent {
  // Titre
  @Input() title: string = '';
  // Div avec animation (exemple devis sur contrat)
  @Input() bounceIn: boolean = false;
}
