import { format, addDays, getWeek, startOfWeek } from 'date-fns';
// format une date unix to number
export function formatDateUnixToNumber(dateUnix: Date | string): number {
  return Number(dateUnix?.toString().substring(6, dateUnix?.toString().length - 7));
}
// format une date en format d'affichage dd/MM/yyyy
export function formatDate(date: Date | string) {
  if (typeof date === 'string' && date.includes('Date')) {
    return format(formatDateUnixToNumber(date), 'dd/MM/yyyy');
  } else {
    return '';
  }
}

// format une date en format d'affichage MM/dd/yyyy
export function formatENDate(date: string | null) {
  if (typeof date === 'string' && date.includes('Date')) {
    return format(formatDateUnixToNumber(date), 'MM/dd/yyyy');
  } else {
    return '';
  }
}

// format une date en format d'affichage M/d/yyyy
export function formatDateSpe(date: Date | string) {
  if (typeof date === 'string' && date.includes('Date')) {
    return format(formatDateUnixToNumber(date), 'M/d/yyyy');
  } else {
    return '';
  }
}
// format une date en format d'affichage yyyy/MM/dd
export function formatDateCalendrier(date: string) {
  if (typeof date === 'string' && date.includes('Date')) {
    return format(formatDateUnixToNumber(date), 'yyyy-MM-dd');
  } else {
    return '';
  }
}
// format une date en format d'affichage dd/MM/yyyy HH:mm
export function formatDateWithHours(date: Date | string) {
  if (typeof date === 'string' && date.includes('Date')) {
    return format(formatDateUnixToNumber(date), 'dd/MM/yyyy HH:mm');
  } else {
    return '';
  }
}
// Convertit une durée en heure minute et formatter format affichage en HHhMM
export function convertirDuree(duree: number): string | null {
  duree = duree != null ? duree : 0;
  let dureeHeure = Math.floor(duree / 60);
  let dureeMinute = Math.abs(duree - dureeHeure * 60);
  if (dureeHeure === 0 && dureeMinute === 0) {
    return null;
  } else {
    return dureeHeure + 'h' + dureeMinute;
  }
}
// Renvoie date + n days en format dd-MM-yyyy
export function addDaysToDate(n: number, date: Date) {
  const newDate = addDays(date, n);
  return format(newDate, 'dd/MM/yyyy');
}
// convert dd/MM/yyyy to Dte object
export function convertToDate(dateString: string) {
  let d = dateString.split('/');
  let dat = new Date(d[2] + '/' + d[1] + '/' + d[0]);
  return dat;
}

//Get the date from datetime with the formate dd/mm/yyyy HH:MM
export function convertStringToDate(dateString: string, withHour: boolean = false) {
  let d = dateString.split('/');
  var timeHourMinute: string = '00:00';
  var year: string = d[2];
  if (withHour) {
    let h = d[2].split(' ');
    // split to 2024 11:30 -> [2024,11:30]
    timeHourMinute = h[1];
    year = h[0];
  }
  let date = new Date(year + '/' + d[1] + '/' + d[0] + ' ' + timeHourMinute);
  return date;
}

// Renvoie numéro de semaine en cours par rapport a une date
export function getNumberOfWeek(date: Date) {
  return getWeek(date);
}
// Renvoie Date du 1er jour de la semaine
export function getFirstDayOfWeek(date: Date) {
  const d = startOfWeek(date, { weekStartsOn: 1 });
  return format(d, 'dd/MM');
}
// Renvoie Date du 1er jour de la semaine
export function getFirstDayFullDateOfWeek(date: Date) {
  const d = startOfWeek(date, { weekStartsOn: 1 });
  return format(d, 'dd/MM/yyyy');
}
// Renvoie Date du 1er jour de la semaine
export function getFirstDayDateOfWeek(date: Date) {
  const d = startOfWeek(date, { weekStartsOn: 1 });
  return d;
}
// Renvoie une date formatée agenda
export function getDayAgenda(date: Date, n: number) {
  const newDate = addDays(date, n);
  return format(newDate, 'dd/MM');
}
// Renvoie une date formatée pour date rendez vous
export function getFullDateDayAgenda(date: Date, n: number) {
  const newDate = addDays(date, n);
  return format(newDate, 'dd/MM/yyyy');
}

// Renvoie date premier jour de la semaine prochaine
export function getNextWeekFirstDate(date: Date) {
  // Ajouter une semaine a la date
  const newDate = addDays(date, 7);
  // Récupérer la date du 1er jour de cette semaine
  return startOfWeek(newDate, { weekStartsOn: 1 });
}
// Renvoie date premier jour de la semaine précédente
export function getPreviousWeekFirstDate(date: Date) {
  // soustraire une semaine a la date
  const newDate = addDays(date, -7);
  // Récupérer la date du 1er jour de cette semaine
  return startOfWeek(newDate, { weekStartsOn: 1 });
}
// renvoie date formatée yyyy-MM-dd
export function formatDateAgenda(date: Date) {
  return format(date, 'yyyy-MM-dd');
}
// Renvoir dd
export function getDay(date: Date) {
  return format(date, 'd');
}
