<div
  class="flex flex-col align-start justify-start"
  [ngClass]="isExpanded ? 'w-[220px] ' : 'w-[66px]'">
  <a [routerLink]="'accueil-csc'" class="droplet-item">
    <span
      (click)="setTitle('Accueil CSC')"
      pTooltip="Retour page de recherche Abonnement"
      tooltipPosition="bottom">
      <fa-icon [icon]="faTint" size="2x" class="app-icon"></fa-icon>
    </span>
  </a>
  <!-- ! attention : les modifications doivent être répercutées dans les deux menus (expanded et !expanded) -->
  <!-- Note : le contenu du menu collapsed est géré dans le .ts -->
  <p-menubar *ngIf="!isExpanded" [model]="menuItems"></p-menubar>

  <!-- ! attention : les modifications doivent être répercutées dans les deux menus (expanded et !expanded) -->
  <p-accordion *ngIf="isExpanded" [multiple]="true">
    <!-- <p-accordionTab *ngFor="let menu of menuItems" [selected]="false">
      <ng-template pTemplate="header">
        <span class="flex flex-row align-center">
          <i [class]="menu.icon"></i>
          <span>
            {{ menu.label }}
          </span>
        </span>
      </ng-template>
      <ng-template pTemplate="content" ngFor let-subMenu [ngForOf]="menu.items">
        <span class="menu-child">
          <a [routerLink]="[subMenu.routerLink]">
            {{ subMenu.label }}
          </a>
        </span>
      </ng-template>
    </p-accordionTab> -->

    <!-- ABONNEMENT SECTION -->
    <p-accordionTab [selected]="false">
      <ng-template pTemplate="header">
        <span class="flex flex-row align-center">
          <i class="icon waterpIcons-Abonnement"></i>
          <span>
            {{ resource.layout.abonnement }}
          </span>
        </span>
      </ng-template>
      <ng-template pTemplate="content">
        <span class="menu-child">
          <a [routerLink]="'recherche-abonnement'">
            {{ resource.layout.rechercheAbonnement }}
          </a>
        </span>
        <span class="menu-child">
          <a [routerLink]="'recherche-client'">
            {{ resource.layout.rechercheClient }}
          </a>
        </span>
        <span class="menu-child">
          <a [routerLink]="'demande-branchement-non-geree'">
            {{ resource.layout.branchementEPNonGere }}
          </a>
        </span>
      </ng-template>
    </p-accordionTab>

    <!-- INTERVENTION SECTION -->
    <p-accordionTab [selected]="false">
      <ng-template pTemplate="header">
        <span class="flex flex-row align-center">
          <i class="icon waterpIcons-Intervention"></i>
          <span>
            {{ resource.layout.intervention }}
          </span>
        </span>
      </ng-template>
      <ng-template pTemplate="content">
        <span class="menu-child">
          <a [routerLink]="'recherche-intervention'">
            {{ resource.layout.rechercheIntervention }}
          </a>
        </span>
        <span class="menu-child">
          <a [routerLink]="'intervention/recherche-localisation'">
            {{ resource.layout.rechercheLocalisation }}
          </a>
        </span>
      </ng-template>
    </p-accordionTab>

    <!-- ORGANISATION SECTION -->
    <p-accordionTab [selected]="false" *ngIf="haveAutorisations('RESS_AUTH_MODIF', webFunctions)">
      <ng-template pTemplate="header">
        <span class="flex flex-row align-center">
          <i class="icon waterpIcons-Organisation"></i>
          <span>
            {{ resource.layout.organisation }}
          </span>
        </span>
      </ng-template>
      <ng-template pTemplate="content">
        <span class="menu-child">
          <a [routerLink]="'organisation/autorisation-ressource'">
            {{ resource.layout.gestionAutorisation }}
          </a>
        </span>
        <span class="menu-child">
          <a routerLink="organisation/gestion-ressources/search">
            {{ resource.layout.gestionRessources }}
          </a>
        </span>
        <span class="menu-child">
          <a routerLink="organisation/gestion-profils/list">
            {{ resource.layout.gestionProfils }}
          </a>
        </span>
      </ng-template>
    </p-accordionTab>
  </p-accordion>

  <!-- RELEASE INFO -->
  <span
    class="absolute mb-[19px] bottom-0 text-[0.8rem] text-center flex items-center content-center"
    [ngClass]="isExpanded ? 'flex-row ml-[50px]' : 'flex-col'">
    <p>
      {{ resource.layout.watERP }} (<strong>{{ currentDate }}</strong
      >)
    </p>
    <a [routerLink]="'Home/About'">
      <fa-icon
        [icon]="faInfoCircle"
        class="text-base text-primary-800"
        [ngClass]="isExpanded ? 'ml-2' : ''"
        [pTooltip]="tooltip"
        tooltipPosition="top"></fa-icon>
    </a>
  </span>
</div>
