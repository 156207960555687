<div class="lg:mx-28 md:mx-20" [formGroup]="indexForm">
  <div class="grid grid-cols-2 gap-5 max-w-[500px] mx-auto">
    <div *ngIf="!unknow" class="col-span-2 w-1/3">
      <label for="dateMutation" class="required">Date mutation</label>
      <p-calendar
        formControlName="dateMutation"
        class="w-full block"
        [maxDate]="maxDate"></p-calendar>
      <small
        class="p-error"
        *ngIf="
          dateMutation?.touched && dateMutation?.invalid && (dateMutation?.errors)!['required']
        "
        >{{ resource.message.errorMandatory }}</small
      >
    </div>
    <div class="flex flex-1 space-x-3 mx-auto">
      <div class="py-5">
        <div
          class="logo-eau"
          [ngClass]="branchementOuvert ? 'open' : 'close'"
          [pTooltip]="branchementOuvert ? 'Ouvert' : 'Fermé'"
          tooltipPosition="top">
          <img
            class="w-6 mx-2 py-2"
            [src]="
              branchementOuvert ? resource.svgPath.branchement_o : resource.svgPath.branchement_c
            "
            alt="Branchement Ouvert" />
        </div>
      </div>
      <div class="flex flex-col space-y-2" *ngIf="!indicateurConsentementComplexe">
        <label for="typeIndex" class="required">Type Index</label>
        <p-radioButton
          [value]="resource.Constants.Releve.CodeConditionReleve.Reel"
          [label]="resource.Constants.Releve.LibelleConditionReleve.Reel"
          formControlName="typeIndex">
        </p-radioButton>
        <p-radioButton
          [value]="resource.Constants.Releve.CodeConditionReleve.Estime"
          [label]="resource.Constants.Releve.LibelleConditionReleve.Estime"
          formControlName="typeIndex">
        </p-radioButton>
        <p-radioButton
          [value]="resource.Constants.Releve.CodeConditionReleve.Inconnu"
          [label]="resource.Constants.Releve.LibelleConditionReleve.Inconnu"
          formControlName="typeIndex">
        </p-radioButton>
        <div *ngIf="deposeCompteur" class="deposeCompteurError">
          <fa-icon [icon]="faCircleInfo" size="lg"></fa-icon>
          Compteur déposé
        </div>
        <small
          class="p-error"
          *ngIf="typeIndex?.touched && typeIndex?.invalid && (typeIndex?.errors)!['required']"
          >{{ resource.message.errorMandatory }}</small
        >
      </div>
      <div class="flex flex-col space-y-2" *ngIf="indicateurConsentementComplexe">
        <label for="typeIndex">Type Index</label>
        <p-radioButton
          [value]="resource.Constants.Releve.CodeConditionReleve.Consentement"
          [label]="resource.Constants.Releve.LibelleConditionReleve.Consentement"
          formControlName="typeIndex">
        </p-radioButton>
        <div *ngIf="deposeCompteur"></div>
      </div>
    </div>
    <div *ngIf="!unknow" class="w-1/3">
      <label for="index">Index</label>
      <input
        type="text"
        pInputText
        name="index"
        formControlName="index"
        [maxlength]="8"
        pKeyFilter="int" />
    </div>
  </div>
</div>
<div class="flex flex-1 items-center justify-end space-x-3 mx-6 px-6 mt-10">
  <button class="button-cancel flex mr-0">
    <img class="py-0.5 pr-1" [src]="resource.svgPath.close" alt="closeSVG" />{{
      resource.form.cancel
    }}
  </button>
  <button class="button-submit flex" (click)="submitForm()">
    {{ resource.form.next }}<img class="py-0.5 pl-1" [src]="resource.svgPath.east" alt="eastSvg" />
  </button>
</div>
