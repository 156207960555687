import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//#region Pipe
import { DateUnixToDatePipe } from 'src/app/shared/pipe/dateUnixToDate.pipe';
import { DateUnixToHoursPipe } from 'src/app/shared/pipe/dateUnixToHours.pipe';
//#endregion

@NgModule({
  imports: [CommonModule],
  declarations: [DateUnixToDatePipe, DateUnixToHoursPipe],
  exports: [DateUnixToDatePipe, DateUnixToHoursPipe]
})
export class PipeModule {}
