import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RedirectDemandeBranchementGuard implements CanActivate {
  constructor(private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.router.navigate([
      'demande-branchement/' +
        route.queryParams['codeInsee'] +
        '/' +
        route.queryParams['idTiersAbo'] +
        '/' +
        route.queryParams['idTiersDest']
    ]);
    return true;
  }
}
