<div class="my-14 flex route-container lg:mx-10 md:mx-5">
  <div class="flex-1 w-full place-content-center route-content">
    <div class="grid" [ngClass]="updateColumnWizard()">
      <div
        *ngFor="let wizardStep of wizardSteps; let i = index"
        class="mx-auto step flex justify-center"
        [ngClass]="updateClass(wizardStep)"
        [pTooltip]="wizardStep.libelle"
        tooltipPosition="bottom"
        (click)="selectStep(wizardStep)">
        <div class="grid grid-cols-1 content-center">
          <img
            class="route-icon center"
            [alt]="wizardStep.libelle"
            [src]="updateIcon(wizardStep)" />
        </div>
      </div>
    </div>
  </div>
  <div class="flex-1 w-full place-self-center route"></div>
</div>
<div *ngIf="etatIsVisible" class="flex justify-end lg:mx-10 md:mx-5 -mt-7">
  <div class="">
    <p-chip
      *ngIf="infosEtatAbonnement.class !== 'hidden'"
      [styleClass]="infosEtatAbonnement.class"
      [label]="infosEtatAbonnement.text"
      [icon]="infosEtatAbonnement.icone"></p-chip>
    <span *ngIf="infosEtatAbonnement.class === 'hidden'">
      {{ infosEtatAbonnement.text }}
    </span>
  </div>
</div>
<div class="mb-5" *ngIf="currentStep">
  <app-localisation
    *ngIf="showStep(currentStep.libelle, resource.wizard.localisation)"
    [codeINSEE]="codeINSEE"
    [idTiersAbo]="idTiersAbo"
    [idTiersDest]="idTiersDest"
    (validForm)="nextStep($event, currentStep)"></app-localisation>
  <app-index
    *ngIf="showStep(currentStep.libelle, resource.wizard.index)"
    (validForm)="nextStep($event, currentStep)"
    (etatIndex)="getInfosEtatAbonnement($event)"
    (updateWizard)="updateWizardCondition($event)"
    (etatIsVisible)="etatIsVisible = $event"></app-index>
  <app-information-client
    *ngIf="showStep(currentStep.libelle, resource.wizard.informationsClient)"
    [edit]="editIC"
    (validForm)="nextStep($event, currentStep)"
    (previousForm)="previousStep($event, currentStep)"></app-information-client>
  <app-proprietaire
    *ngIf="showStep(currentStep.libelle, resource.wizard.ProprietaireGerant)"
    (validForm)="nextStep($event, currentStep)"
    (previousForm)="previousStep($event, currentStep)"></app-proprietaire>
  <app-reference-bancaire
    *ngIf="showStep(currentStep.libelle, resource.wizard.referencesBancaire)"
    (tipForm)="isTipFormEvent($event)"
    (validForm)="nextStep($event, currentStep)"
    (previousForm)="previousStep($event, currentStep)"></app-reference-bancaire>
  <app-information-segmentation
    *ngIf="showStep(currentStep.libelle, resource.wizard.informationsSegmentation)"
    [numAbonnement]="numAbonnement"
    (validForm)="nextStep($event, currentStep)"
    (previousForm)="previousStep($event, currentStep)"></app-information-segmentation>
  <app-document
    *ngIf="showStep(currentStep.libelle, resource.wizard.document)"
    [lastStep]="lastStep"
    [submitIsLoading]="submitIsLoading"
    (validForm)="nextStep($event, currentStep)"
    (submitEvent)="submitEvent($event)"
    (previousForm)="previousStep($event, currentStep)"></app-document>
  <app-facture-entrant
    *ngIf="showStep(currentStep.libelle, resource.wizard.factureEntrant)"
    [lastStep]="lastStep"
    [submitIsLoading]="submitIsLoading"
    [miseEnService]="miseEnService"
    (validForm)="nextStep($event, currentStep)"
    (submitEvent)="submitEvent($event)"
    (previousForm)="previousStep($event, currentStep)"></app-facture-entrant>
</div>
