<div *ngIf="informationsIdentiteVM">
  <div class="wrapper-synthese">
    <div class="wrapper-information">
      <!-- Information Identité-->
      <div class="information-identite">
        <router-outlet name="informations-identite"></router-outlet>
      </div>
      <!-- Information Financier-->
      <div class="information-financier">
        <router-outlet name="informations-financiers"></router-outlet>
      </div>
      <!-- Information Technique-->
      <div class="information-technique">
        <router-outlet name="informations-techniques"></router-outlet>
      </div>
    </div>
  </div>
  <!--- Services-->
  <waterp-services-manager></waterp-services-manager>

  <div id="bouncingFrameAnchor">
    <!-- Calcul Devis -->
    <router-outlet *ngIf="devisContratIsVisible" name="devis-contrat"></router-outlet>

    <!-- Ajout consommation -->
    <router-outlet *ngIf="ajoutConsoIsVisible" name="ajout-consommations"></router-outlet>

    <!-- Ajout Intervention -->
    <router-outlet
      *ngIf="ajoutInterventionIsVisible || ajoutInterventionResiliationIsVisible"
      name="ajout-interventions"></router-outlet>

    <!-- Consultation Intervention -->
    <router-outlet
      *ngIf="consultInterventionIsVisible"
      name="consulter-intervention"></router-outlet>

    <!-- Modification Intervention -->
    <router-outlet *ngIf="modifInterventionIsVisible" name="modifier-intervention"></router-outlet>

    <!-- Créer plan de mensualisation (tab compte)-->
    <router-outlet
      *ngIf="creerPlanMensualisationIsVisible"
      name="plan-mensualisation"></router-outlet>

    <!-- Création remboursement (tab compte)-->
    <router-outlet
      *ngIf="creerRemboursementIsVisible"
      name="creation-remboursement"></router-outlet>

    <!-- Créer échéancier paiement (tab compte)-->
    <router-outlet
      *ngIf="creerEcheancierPaiementIsVisible"
      name="echeancier-paiement"></router-outlet>

    <!-- Créer Facture Manuelle -->
    <router-outlet *ngIf="creerFactureManuelleIsVisible" name="facture-manuelle"></router-outlet>

    <!-- Créer Autre Facture Manuelle  -->
    <router-outlet
      *ngIf="creerAutreFactureManuelleIsVisible"
      name="autre-facture-manuelle"></router-outlet>

    <!-- Créer Avoir partiel -->
    <router-outlet
      *ngIf="isVisibleAvoirPartiel && haveAutorisations('ABO_AVOIR_CREATION', webFunctions)"
      name="creer-avoir-partiel"></router-outlet>

    <!-- Créer Avoir total -->
    <router-outlet
      *ngIf="isVisibleAvoirTotal && haveAutorisations('ABO_AVOIR_CREATION', webFunctions)"
      name="creer-avoir-total"></router-outlet>

    <!-- Nouveau courrier -->
    <router-outlet *ngIf="creerNouveauCourrierIsVisible" name="nouveau-courrier"></router-outlet>

    <!-- Nouveau courrier à transférer -->
    <router-outlet
      *ngIf="creerNouveauCourrierATransfererIsVisible"
      name="nouveau-courrier-a-transferer"></router-outlet>

    <!-- Résilier abonnement-->
    <router-outlet *ngIf="resilierAbonnementIsVisible" name="resilier-abonnement"></router-outlet>

    <!-- Mise en service abonnement-->
    <router-outlet
      *ngIf="miseEnServiceAbonnementIsVisible"
      name="mise-en-service-abonnement"></router-outlet>
  </div>

  <!-- Tabs -->
  <div class="tabs">
    <p-tabView [(activeIndex)]="activeIndexTab">
      <!-- ONGLET EVENEMENTS -->
      <p-tabPanel header="Évènements">
        <router-outlet name="evenement-list"></router-outlet>
      </p-tabPanel>

      <!-- ONGLET GENERALITES -->
      <p-tabPanel header="Généralités" styleClass="panel">
        <router-outlet
          *ngIf="getLoading(1) || tabLoading[1].loading"
          name="generalites"></router-outlet>
      </p-tabPanel>

      <!-- ONGLET FACTURATION-RELEVE -->
      <p-tabPanel header="Facturation-relève" styleClass="panel">
        <router-outlet
          *ngIf="getLoading(2) || tabLoading[2].loading"
          name="facturation-releve"></router-outlet>
      </p-tabPanel>

      <!-- ONGLET COMPTEUR INSTALLATION -->
      <p-tabPanel header="Compteur-installation" styleClass="panel">
        <router-outlet
          *ngIf="getLoading(3) || tabLoading[3].loading"
          name="compteur-installation"></router-outlet>
      </p-tabPanel>

      <!-- ONGLET CONSOMMATIONS -->
      <p-tabPanel header="Consommations">
        <router-outlet
          *ngIf="getLoading(4) || tabLoading[4].loading"
          name="consommations"></router-outlet>
      </p-tabPanel>

      <!-- ONGLET INTERVENTIONS -->
      <p-tabPanel header="Interventions">
        <router-outlet
          *ngIf="getLoading(5) || tabLoading[5].loading || res == 5"
          name="list-interventions"></router-outlet>
      </p-tabPanel>
      <!-- ONGLET COMPTE -->
      <p-tabPanel header="Compte">
        <router-outlet *ngIf="getLoading(6) || tabLoading[6].loading" name="compte"></router-outlet>
      </p-tabPanel>

      <!-- ONGLET COMMUNICATIONS -->
      <p-tabPanel header="Communications">
        <router-outlet
          *ngIf="getLoading(7) || tabLoading[7].loading || res == 7"
          name="communications"></router-outlet>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
