import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Services primeng
import { MessageService } from 'primeng/api';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//#endregion

//#region Import PrimeNG Module
import { ToastModule } from 'primeng/toast';
import {
  GoogleSigninButtonModule,
  SocialLoginModule,
  SocialAuthServiceConfig
} from '@abacritt/angularx-social-login';
import { GoogleConfigService } from '../services/google-config.service';
//#endregion

//#region Constantes Module Angular
const SIGNINGOOGLE = [SocialLoginModule, GoogleSigninButtonModule];
//#endregion

const AngularModule = [
  CommonModule,
  RouterModule,
  BrowserModule,
  HttpClientModule,
  BrowserAnimationsModule // required animations module
];

@NgModule({
  declarations: [],
  imports: [AngularModule, ToastModule, SIGNINGOOGLE],
  exports: [AngularModule, ToastModule, SIGNINGOOGLE],
  providers: [
    MessageService,
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: (configService: GoogleConfigService) => configService.getGoogleConfig(),
      deps: [GoogleConfigService]
    }
  ]
})
export class SharedModule {}
