import { Action, createReducer, on } from '@ngrx/store';
import {
  init,
  resetClient,
  resetDocument,
  resetFactureEntrant,
  resetIndex,
  resetInfoSegmentation,
  resetLocalisation,
  resetProprietaire,
  resetReferenceBancaire,
  updateClient,
  updateDocument,
  updateFactureEntrant,
  updateIndex,
  updateInfoSegmentation,
  updateLocalisation,
  updateProprietaire,
  updateReferenceBancaire
} from './wizard.actions';
import { initialState, WizardState } from './wizard.state';

const wizardReducer = createReducer(
  initialState,
  on(updateIndex, (state, action: ReturnType<typeof updateIndex>) => ({
    ...state,
    index: { ...state.index, ...action.payload }
  })),
  on(updateLocalisation, (state, action: ReturnType<typeof updateLocalisation>) => ({
    ...state,
    localisation: { ...state.localisation, ...action.payload }
  })),
  on(updateClient, (state, action: ReturnType<typeof updateClient>) => ({
    ...state,
    client: { ...state.client, ...action.payload }
  })),
  on(updateProprietaire, (state, action: ReturnType<typeof updateProprietaire>) => ({
    ...state,
    proprietaire: { ...state.proprietaire, ...action.payload }
  })),
  on(updateReferenceBancaire, (state, action: ReturnType<typeof updateReferenceBancaire>) => ({
    ...state,
    referenceBancaire: { ...state.referenceBancaire, ...action.payload }
  })),
  on(updateInfoSegmentation, (state, action: ReturnType<typeof updateInfoSegmentation>) => ({
    ...state,
    infoSegmentation: { ...state.infoSegmentation, ...action.payload }
  })),
  on(updateDocument, (state, action: ReturnType<typeof updateDocument>) => ({
    ...state,
    document: { ...state.document, ...action.payload }
  })),
  on(updateFactureEntrant, (state, action: ReturnType<typeof updateFactureEntrant>) => ({
    ...state,
    factureEntrant: { ...state.factureEntrant, ...action.payload }
  })),
  on(resetIndex, state => ({
    ...state,
    index: { ...state.index, ...initialState.index }
  })),
  on(resetLocalisation, state => ({
    ...state,
    localisation: { ...state.localisation, ...initialState.localisation }
  })),
  on(resetClient, state => ({
    ...state,
    client: { ...state.client, ...initialState.client }
  })),
  on(resetProprietaire, state => ({
    ...state,
    proprietaire: { ...state.proprietaire, ...initialState.proprietaire }
  })),
  on(resetReferenceBancaire, state => ({
    ...state,
    referenceBancaire: { ...state.referenceBancaire, ...initialState.referenceBancaire }
  })),
  on(resetInfoSegmentation, state => ({
    ...state,
    infoSegmentation: { ...state.infoSegmentation, ...initialState.infoSegmentation }
  })),
  on(resetDocument, state => ({
    ...state,
    document: { ...state.document, ...initialState.document }
  })),
  on(resetFactureEntrant, state => ({
    ...state,
    factureEntrant: { ...state.factureEntrant, ...initialState.factureEntrant }
  })),
  on(init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: WizardState | undefined, action: Action) {
  return wizardReducer(state, action);
}
