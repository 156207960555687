import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { AppConfig } from 'src/app/app.config';
import { WaterpHttpService } from 'src/app/shared/services/waterp-http.service';
import { ParametersRequest } from '../models/shared/parametersRequest';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class ReferenceBancaireService extends AbstractService {
  parametersRequest!: ParametersRequest;
  constructor(config: AppConfig, private waterpHttpService: WaterpHttpService) {
    super(config);
  }

  //#region /ReferenceBancaire/GetInfosBicDomiciliationRum: Calculer le BIC et domiciliation à partir de l'IBAN (EUROPE Norme SEPA)
  /**
   * @param iban
   */
  public getInfosBicDomiciliationRum(iban: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/ReferenceBancaire/GetInfosBicDomiciliationRum',
      parameters: [{ name: 'iban', value: iban }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /ReferenceBancaire/GetRum: Calculer une RUM
  /**
   */
  public getRum(): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/ReferenceBancaire/GetRum',
      parameters: []
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /ReferenceBancaire/GetStructureIbanPays: Renvoie une liste des pays avec leur longueur d'IBAN
  /**
   */
  public getStructureIbanPays(): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/ReferenceBancaire/GetStructureIbanPays',
      parameters: []
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /ReferenceBancaire/IsBicValid: Check si BIC correcte
  /**
   * @param bic
   * @param iban
   */
  public isBicValid(bic: string, iban: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/ReferenceBancaire/IsBicValid',
      parameters: [
        { name: 'bic', value: bic },
        { name: 'iban', value: iban }
      ]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /ReferenceBancaire/IsIbanValid: Check si l'IBAN correcte
  /**
   * @param iban
   */
  public isIbanValid(
    iban: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/ReferenceBancaire/IsIbanValid',
      parameters: [{ name: 'iban', value: iban }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion
}
