import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/state/core.state';
import { BaseComponent } from '../components/base/base.component';
import { RessourceService } from 'src/app/api/services/ressource.service';
import { AppResource } from 'src/app/app.resource';
import { MessageService } from 'primeng/api';
import { MessageServiceUtils } from '../utils/messageServiceUtils';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';

@Injectable({
  providedIn: 'root'
})
export class ReinitialisationPasswordGuard implements CanActivate {
  public messageServiceUtils: MessageServiceUtils;
  constructor(
    private store: Store<State>,
    private router: Router,
    private ressourceService: RessourceService,
    private resources: AppResource,
    private messageService: MessageService
  ) {
    this.messageServiceUtils = new MessageServiceUtils(this.messageService, resources['resource']);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.ressourceService.
      verifierExpirationTokenNouveauMotDePasse(route.params['tokenReinitialisationMdp']).pipe(
        map((response:GeneriqueResponse) => {
          if(!response.Result){
            this.messageServiceUtils.warning(response.Description);
            BaseComponent.redirectToAuthentification(this.store, this.router, state.url);
          }
          return response.Result;
        })
      )
  }
}
