import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { State } from './core.state';
import * as ContextReducer from './context/context.reducer';
import * as AbonnementReducer from './abonnement/abonnement.reducer';
import * as EtatReducer from './etat/etat.reducer';
import * as HeaderReducer from './header/header.reducer';
import * as FactureReducer from './creation-facture-manuelle/creation-facture-manuelle.reducer';
import * as AutreFactureReducer from './creation-autre-facture-manuelle/creation-autre-facture-manuelle.reducer';
import * as OperationReducer from './operation/operation.reducer';
import * as WizardReducer from './wizard/wizard.reducer';
import * as HistoriqueReducer from './historique/historique.reducer';
import * as DossierCompteReducer from './dossier-compte/dossier-compte.reducer';
import * as InfosFinancieresReducer from './infos-financieres/infos-financieres.reducer';
import * as InfosTechniquesReducer from './infos-techniques/infos-techniques.reducer';
import * as InfosPdf from './infos-pdf/infos-pdf.reducer';
import { hydrationMetaReducer } from './hydration/hydration.reducer';

export const reducers: ActionReducerMap<State> = {
  context: ContextReducer.reducer,
  abonnement: AbonnementReducer.reducer,
  etat: EtatReducer.reducer,
  header: HeaderReducer.reducer,
  wizard: WizardReducer.reducer,
  creationFacture: FactureReducer.reducer,
  creationAutreFacture: AutreFactureReducer.reducer,
  historique: HistoriqueReducer.reducer,
  operation: OperationReducer.reducer,
  dossierCompte: DossierCompteReducer.reducer,
  infosFinancieres: InfosFinancieresReducer.reducer,
  infosTechniques: InfosTechniquesReducer.reducer,
  infosPdf: InfosPdf.reducer
};

export const metaReducers: MetaReducer[] = [hydrationMetaReducer, clearState];

export function clearState(reducer: any) {
  return (state: any, action: any) => {
    // if (action != null && action.type === '[Init]') {
    //   return reducer(undefined, { type: INIT });
    // }
    return reducer(state, action);
  };
}
