<div class="lg:mx-28 md:mx-20" [formGroup]="infoSegmentationForm">
  <div class="grid grid-cols-2 gap-16 max-w-[700px] mx-auto">
    <div class="space-y-5">
      <div class="grid grid-cols-2">
        <div class="flex-col">
          <label for="nombreOccupant" class="label-input">Nbre occupants</label>
          <p-inputNumber
            [min]="0"
            [max]="999"
            class="app-input waterp-inputnumber"
            formControlName="nombreOccupant"></p-inputNumber>
        </div>
      </div>
      <div class="flex-col">
        <label for="observation" class="label-input">Observation</label>
        <textarea
          class="app-input w-full"
          [rows]="5"
          pInputTextarea
          formControlName="observation"></textarea>
      </div>
      <div class="flex-col">
        <label for="commentaire" class="label-input">Commentaire</label>
        <textarea
          type="text"
          class="app-input w-full"
          pInputTextarea
          [rows]="5"
          formControlName="commentaire"></textarea>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-1">
      <div class="flex-col col-span-2">
        <label for="activite" class="label-input required">Activité</label>
        <p-dropdown
          [options]="activites"
          formControlName="activite"
          class="w-full"
          optionLabel="Value"
          [placeholder]="resource.form.phDropDownList">
        </p-dropdown>
        <small
          class="p-error"
          *ngIf="activite?.touched && activite?.invalid && (activite?.errors)!['required']"
          >{{ resource.message.errorMandatory }}</small
        >
      </div>
      <div class="flex-col col-span-2">
        <label for="typeAbonne" class="label-input required">Type d'abonné</label>
        <p-dropdown
          [options]="typesAbonne"
          formControlName="typeAbonne"
          class="w-full"
          optionLabel="Value"
          [placeholder]="resource.form.phDropDownList">
        </p-dropdown>
        <small
          class="p-error"
          *ngIf="typeAbonne?.touched && typeAbonne?.invalid && (typeAbonne?.errors)!['required']"
          >{{ resource.message.errorMandatory }}</small
        >
      </div>
      <div class="flex-col col-span-2">
        <label for="typeUtilisateur" class="label-input required">Type d'utilisateur</label>
        <p-dropdown
          [options]="typesUtilisateur"
          formControlName="typeUtilisateur"
          class="w-full"
          optionLabel="Value"
          [placeholder]="resource.form.phDropDownList">
        </p-dropdown>
        <small
          class="p-error"
          *ngIf="
            typeUtilisateur?.touched &&
            typeUtilisateur?.invalid &&
            (typeUtilisateur?.errors)!['required']
          "
          >{{ resource.message.errorMandatory }}</small
        >
      </div>
      <div class="flex-col col-span-2">
        <label for="particulariteContrat" class="label-input required">Particularité contrat</label>
        <p-dropdown
          [options]="particularitesContrat"
          formControlName="particulariteContrat"
          class="w-full"
          optionLabel="Value"
          [placeholder]="resource.form.phDropDownList">
        </p-dropdown>
        <small
          class="p-error"
          *ngIf="
            particulariteContrat?.touched &&
            particulariteContrat?.invalid &&
            (particulariteContrat?.errors)!['required']
          "
          >{{ resource.message.errorMandatory }}</small
        >
      </div>
      <div class="flex-col">
        <label for="typeHabitation" class="label-input required">Type d'habitation</label>
        <p-dropdown
          [options]="typesHabitation"
          formControlName="typeHabitation"
          class="w-full"
          optionLabel="Value"
          [placeholder]="resource.form.phDropDownList">
        </p-dropdown>
        <small
          class="p-error"
          *ngIf="
            typeHabitation?.touched &&
            typeHabitation?.invalid &&
            (typeHabitation?.errors)!['required']
          "
          >{{ resource.message.errorMandatory }}</small
        >
      </div>
      <div class="flex-col">
        <label for="sensibilite" class="label-input required">Sensibilité</label>
        <p-dropdown
          [options]="sensibilites"
          formControlName="sensibilite"
          class="w-full"
          optionLabel="Value"
          [placeholder]="resource.form.phDropDownList">
        </p-dropdown>
        <small
          class="p-error"
          *ngIf="sensibilite?.touched && sensibilite?.invalid && (sensibilite?.errors)!['required']"
          >{{ resource.message.errorMandatory }}</small
        >
      </div>
      <div class="flex-col col-span-2">
        <label for="typeClientRisque" class="label-input required">Type de client à risque</label>
        <p-dropdown
          [options]="typesClientRisque"
          formControlName="typeClientRisque"
          class="w-full"
          optionLabel="Value"
          [placeholder]="resource.form.phDropDownList">
        </p-dropdown>
        <small
          class="p-error"
          *ngIf="
            typeClientRisque?.touched &&
            typeClientRisque?.invalid &&
            (typeClientRisque?.errors)!['required']
          "
          >{{ resource.message.errorMandatory }}</small
        >
      </div>
    </div>
  </div>
</div>
<div class="flex flex-1 items-center justify-end space-x-3 mx-6 px-6 mt-10">
  <button class="button-cancel flex mr-0">
    <img class="py-0.5 pr-1" [src]="resource.svgPath.close" alt="closeSVG" />Annuler
  </button>
  <div class="flex flex-col separator"></div>
  <button class="button-cancel flex" (click)="returnPreviousForm()">
    <img class="py-0.5 pr-1" [src]="resource.svgPath.west" alt="westSVG" />Précédent
  </button>
  <button class="button-submit flex" (click)="submitForm()">
    Suivant<img class="py-0.5 pl-1" [src]="resource.svgPath.east" alt="eastSvg" />
  </button>
</div>
