import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { AppConfig } from 'src/app/app.config';
import { WaterpHttpService } from 'src/app/shared/services/waterp-http.service';
import { ParametersRequest } from '../models/shared/parametersRequest';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class ParametrageGeneriqueService extends AbstractService {
  parametersRequest!: ParametersRequest;
  constructor(config: AppConfig, private waterpHttpService: WaterpHttpService) {
    super(config);
  }

  //#region /ParametrageGenerique/Get: Récupère une liste de dto de paramétrage
  /**
   * @param dtoName
   * @param id
   */
  public get(dtoName: string, id: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/ParametrageGenerique/Get',
      parameters: [
        { name: 'dtoName', value: dtoName },
        { name: 'id', value: id }
      ]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  // #endregion

  //#region /ParametrageGenerique/GetAllList : Récupère une liste de dto de paramétrage
  /**
   * @param dtoName
   */
  public getAllList(dtoName: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/ParametrageGenerique/GetAllList',
      parameters: [{ name: 'dtoName', value: dtoName }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  // #endregion

  //#region /ParametrageGenerique/GetAllListWithRelated : Récupère une liste de dto de paramétrage et ses sous-dto
  /**
   * @param dtoName
   */
  public getAllListWithRelated(dtoName: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/ParametrageGenerique/GetAllListWithRelated',
      parameters: [{ name: 'dtoName', value: dtoName }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  // #endregion

  //#region /ParametrageGenerique/GetOnlyActifs : get parametrableDTOs actif
  /**
   * @param dtoName
   */
  public getOnlyActifs(dtoName: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/ParametrageGenerique/GetOnlyActifs',
      parameters: [{ name: 'dtoName', value: dtoName }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  // #endregion
}
