import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

export class ApiError extends Error {
  httpError: HttpErrorResponse;
  requestPath: string;

  constructor(message: string, requestPath: string, httpError: HttpErrorResponse) {
    super(message);

    this.requestPath = requestPath;
    this.httpError = httpError;
  }

  public static handleApiError(path: string, error: HttpErrorResponse) {
    let message: string;
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      message = 'A client side error occurred: ' + error.error;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      message = 'A server side error occurred: ' + error.status;
    }

    return throwError(() => new ApiError(message, path, error));
  }
}
