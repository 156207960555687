import { Action, createReducer, on } from '@ngrx/store';
import { init, patchContext, updateToken, updateUrlRedirect } from './context.actions';
import { initialState, ContextState } from './context.state';

const contextReducer = createReducer(
  initialState,
  on(patchContext, (state, action: ReturnType<typeof patchContext>) => ({
    ...state,
    ...action.payload
  })),
  on(updateToken, (state, { authToken }: ReturnType<typeof updateToken>) => ({
    ...state,
    authToken
  })),
  on(updateUrlRedirect, (state, { urlRedirect }: ReturnType<typeof updateUrlRedirect>) => ({
    ...state,
    urlRedirect
  })),
  on(init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: ContextState | undefined, action: Action) {
  return contextReducer(state, action);
}
