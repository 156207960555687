<!--- Afficher PDF-->
<div *ngIf="documentUrl && isVisible">
  <app-frame [title]="title" [bounceIn]="true" class="pdf">
    <div class="content-header flex">
      <i class="pi pi-reply mr-5" (click)="ouvrirPDF(pdfUrlBlob)"></i>
      <i class="pi pi-times" (click)="initPdfViewer()"></i>
    </div>
    <div class="content-frame">
      <iframe
        *ngIf="documentUrl"
        [src]="documentUrl"
        title="pdf"
        width="100%"
        height="100%"
        frameborder="0"
        class="iframe"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen></iframe>
    </div>
  </app-frame>
</div>

