import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AbonnementDataService } from '../../services/abonnement-data.service';


@Injectable({
  providedIn: 'root'
})
export class GetAbonnementResolver implements Resolve<Observable<any>> {

  constructor(private abonnementDataService: AbonnementDataService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    this.abonnementDataService.resetPanelsVisibility();

    return of(true);
  }
}
