import { createAction, props } from '@ngrx/store';
import { Abonnement } from '../../models/abonnement.model';

export const patchAbonnement = createAction(
  '[Abonnement] Patch Abonnement info',
  props<{ payload: Abonnement }>()
);

export const updateNumeroAppareil = createAction(
  '[Abonnement] Update numéro appareil',
  props<{ numeroAppareil: string }>()
);

export const updateEtat = createAction(
  '[Abonnement] Update Etat Abonnement',
  props<{ etatAbonnement: string }>()
);

export const updateListInterventions = createAction(
  '[Abonnement] Update List interventions',
  props<{ listInterventionsUpdate: boolean }>()
);
export const updateListCourriers = createAction(
  '[Abonnement] Update List courriers',
  props<{ listCourriersUpdate: boolean }>()
);

export const init = createAction('[Init]');
