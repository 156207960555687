import { IWebFunction } from '../interface/IFonctionWeb';

export class WebFunction implements IWebFunction {
  private Code: string;
  private Libelle: string;
  private Description: string;

  constructor(distantWebFunction?: WebFunction) {
    this.Code = distantWebFunction?.Code ?? '';
    this.Libelle = distantWebFunction?.Libelle ?? '';
    this.Description = distantWebFunction?.Description ?? '';
  }

  public getDescription(): string {
    return this.Description;
  }

  public setDescription(Description: string): void {
    this.Description = Description;
  }

  public getLibelle(): string {
    return this.Libelle;
  }

  public setLibelle(Libelle: string): void {
    this.Libelle = Libelle;
  }

  public getCode(): string {
    return this.Code;
  }

  public setCode(Code: string): void {
    this.Code = Code;
  }
}
