import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { AppResource } from 'src/app/app.resource';
import { State } from 'src/app/core/state/core.state';
import * as fromInfosPdf from 'src/app/core/state/infos-pdf';
import { bytesToBase64, dataToBlob, openPDF } from '../../utils/pdfUtils';
import { BaseComponent } from '../base/base.component';
import { FrameComponent } from '../frame/frame.component';

@Component({
  selector: 'waterp-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  standalone: true,
  imports: [CommonModule, FrameComponent]
})
export class PdfViewerComponent extends BaseComponent implements OnInit {
  documentUrl!: SafeResourceUrl;
  pdfUrlBlob!: string;
  data!: number[];
  title!: string;
  isVisible: boolean = false;
  ouvrirPDF = openPDF;
  constructor(
    store: Store<State>,
    resources: AppResource,
    messageService: MessageService,
    private domSanitizer: DomSanitizer
  ) {
    super(store, resources, messageService);
  }
  override async ngOnInit() {
    // Vider les informations du pdf au cas d'un rechargement
    this.initPdfViewer();
    // Récupérer les infos pour l'affichage de PDF
    this.store.select(fromInfosPdf.selectShowPdf).subscribe(res => {
      this.isVisible = res;
    });
    this.store.select(fromInfosPdf.selectTitle).subscribe(res => {
      this.title = res;
    });
    this.store.select(fromInfosPdf.selectDocumentContent).subscribe(res => {
      this.data = res;
      if (this.data.length > 0 && this.isVisible) {
        this.afficherPDF(this.data);
      }
    });
    this.store.select(fromInfosPdf.selectXmlData).subscribe(res => {
      if (res.length > 0 && this.isVisible) {
        this.afficherXmlToPDF(res);
      }
    });
  }
  initPdfViewer() {
    this.isVisible = false;
    this.store.dispatch(
      fromInfosPdf.patchInfosPdf({
        payload: { showPdf: false, title: '', documentContent: [], xmlData: '' }
      })
    );
  }
  afficherPDF(pdfResponse: number[]) {
    const generatedRapport = bytesToBase64(pdfResponse);
    this.pdfUrlBlob = dataToBlob(generatedRapport);
    this.documentUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.pdfUrlBlob);
    this.isVisible = true;
  }
  afficherXmlToPDF(body: string) {
    let result = this.getXmlValue(body, 'Result');
    this.pdfUrlBlob = dataToBlob(result);
    this.documentUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.pdfUrlBlob);
    this.isVisible = true;
  }
  // Web service renvoie XML , fonction qui récupère valeur d'une balise XML
  getXmlValue(str: string | null, key: string) {
    if (str) {
      return str.substring(
        str.lastIndexOf('<' + key + '>') + ('<' + key + '>').length,
        str.lastIndexOf('</' + key + '>')
      );
    } else {
      return '';
    }
  }
}
