import { Component, HostListener } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PrimeNGConfig } from 'primeng/api';
import { AppResource } from './app.resource';
import { State } from './core/state/core.state';
import { Resource } from './resources/resource';
import { getItemLocalStorage, setItemLocalStorage } from 'src/app/shared/utils/localStorageUtils';
import { AppConfig } from './app.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Wat.Erp';

  resource: Resource;

  constructor(
    private config: PrimeNGConfig,
    private router: Router,
    private store: Store<State>,
    private appConfig: AppConfig,
    protected resources: AppResource
  ) {
    this.resource = resources['resource'];
    if (getItemLocalStorage('VersionBuild') == null) {
      setItemLocalStorage('VersionBuild', this.appConfig.getConfig('VersionBuild'));
    }

    this.preloadSVG();
  }

  async ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const url = event.url;
        if (url.includes('/authentification')) {
          sessionStorage.removeItem('state');
        }
      }
    });

    this.config.setTranslation({
      dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
      monthNames: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Decembre'
      ],
      monthNamesShort: [
        'Jan',
        'Fév',
        'Mar',
        'Avr',
        'Mai',
        'Juin',
        'Juil',
        'Aou',
        'Sep',
        'Oct',
        'Nov',
        'Déc'
      ],
      dateFormat: 'dd/mm/yy',
      firstDayOfWeek: 1,
      today: "Aujourd'hui",
      weekHeader: 'Wk'
    });
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {}

  preloadSVG() {
    let svgs = [
      this.resource.svgPath.index_b,
      this.resource.svgPath.index_g,
      this.resource.svgPath.index_w,
      this.resource.svgPath.abonnement_b,
      this.resource.svgPath.abonnement_g,
      this.resource.svgPath.abonnement_w,
      this.resource.svgPath.location_b,
      this.resource.svgPath.location_g,
      this.resource.svgPath.location_w,
      this.resource.svgPath.client_b,
      this.resource.svgPath.client_g,
      this.resource.svgPath.client_w,
      this.resource.svgPath.vpnKey_b,
      this.resource.svgPath.vpnKey_g,
      this.resource.svgPath.vpnKey_w,
      this.resource.svgPath.list_b,
      this.resource.svgPath.list_g,
      this.resource.svgPath.list_w,
      this.resource.svgPath.fileText_b,
      this.resource.svgPath.fileText_g,
      this.resource.svgPath.fileText_w,
      this.resource.svgPath.payment_b,
      this.resource.svgPath.payment_g,
      this.resource.svgPath.payment_w,
      this.resource.svgPath.payment_s,
      this.resource.svgPath.factureEntrant_b,
      this.resource.svgPath.factureEntrant_g,
      this.resource.svgPath.factureEntrant_w,
      this.resource.svgPath.east,
      this.resource.svgPath.west,
      this.resource.svgPath.close,
      this.resource.svgPath.end
    ];
    let frag = new Array<Node>();

    svgs.forEach(item => {
      let res = document.createElement('link');
      res.rel = 'preload';
      res.type = 'image/svg+xml';
      res.as = 'image';
      res.href = item;
      frag.push(res);
    });

    document.getElementsByTagName('head')[0].append(...frag);
  }
}
