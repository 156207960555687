import { GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { Injectable } from '@angular/core';
import { AbstractService } from 'src/app/api/services/abstract.service';
import { AppConfig } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class GoogleConfigService extends AbstractService {
  private googleKey!: string;
  constructor(config: AppConfig) {
    super(config);
    this.googleKey = config.getConfig('googleKey');
  }

  getGoogleConfig(): SocialAuthServiceConfig {
    return {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(this.googleKey)
        }
      ]
    };
  }
}

