import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { AppConfig } from 'src/app/app.config';
import { WaterpHttpService } from 'src/app/shared/services/waterp-http.service';
import { ParametersRequest } from '../models/shared/parametersRequest';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class RueService extends AbstractService {
  parametersRequest!: ParametersRequest;
  constructor(config: AppConfig, private waterpHttpService: WaterpHttpService) {
    super(config);
  }

  //#region /Rue/GetRuesByCodeCommune: Rechercher les rues en fonction du code de la commune
  /**
   * @param commune
   */
  public getRuesByCodeCommune(commune: number): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Rue/GetRuesByCodeCommune',
      parameters: [{ name: 'commune', value: commune }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Rue/GetRues: Récupérer les rues en fonction de certaines propriétés
  /**
   * @param properties
   */
  public getByCodeCommuneAndNumeroRue(
    codeCommune: number,
    numeroRue: number
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Rue/GetByCodeCommuneAndNumeroRue',
      parameters: [
        { name: 'codeCommune', value: codeCommune },
        { name: 'numeroRue', value: numeroRue }
      ]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion
}
