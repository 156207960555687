import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HistoriqueState } from './historique.state';

export const selectHistoriqueCards = createFeatureSelector<HistoriqueState>('historique');

export const selectCards = createSelector(
  selectHistoriqueCards,

  (state: HistoriqueState) => state.cardArray
);

export const selectIndex = createSelector(
  selectHistoriqueCards,

  (state: HistoriqueState) => state.indexTable
);
