import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private _token$ = new BehaviorSubject<string>('');
  token$ = this._token$.asObservable();

  constructor() {}

  set(token: string) {
    this._token$.next(token);
  }
}
