import { Card } from 'src/app/core/models/historique.model';

export function cardDuplication(type: string, cards: Card[], card: Card) {
  let cardsTrans = [...cards]; // create a new array instead of assigning the reference

  if (cardsTrans.length == 0) {
    return [card];
  }

  if (type == 'AB') {
    cardsTrans = cardsTrans.filter(
      e => !(e.numAbonne == card.numAbonne && e.numClient == card.numClient && e.type == 'AB')
    );
  } else if (type === 'CL') {
    cardsTrans = cardsTrans.filter(e => !(e.numClient == card.numClient && e.type == 'CL'));
  } else if (type == 'IN') {
    cardsTrans = cardsTrans.filter(e => !(e.numInter == card.numInter && e.type == 'IN'));
  } else if (type == 'CR') {
    cardsTrans = cardsTrans.filter(e => !(e.courrierNum == card.courrierNum && e.type == 'CR'));
  }

  cardsTrans.push(card);

  if (cardsTrans.length > 10) {
    cardsTrans.splice(0, cardsTrans.length - 10);
  }

  return cardsTrans;
}
