import { RessourceService } from 'src/app/api/services/ressource.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, map, tap } from 'rxjs';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { Profil } from 'src/app/api/models/class/profil';
import { Ressource } from 'src/app/api/models/class/Ressource';

@Injectable({
  providedIn: 'root'
})
export class GetResourceResolver implements Resolve<Ressource> {
  constructor(private ressourceService: RessourceService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Ressource> {
    return this.ressourceService
      .GetInformationsRessourceByMatricule(route.params['matricule'])
      .pipe(map((response: Ressource) => response));
  }
}
