<div class="lg:mx-56 md:mx-40 space-y-5" [formGroup]="documentForm">
  <div class="grid grid-cols-2 gap-5 space-x-5 max-w-[500px] mx-auto">
    <div class="text-right space-x-3">
      <div class="checkbox">
        <p-checkbox [binary]="true" formControlName="branchementEauPotable"></p-checkbox>
        <label for="branchementEauPotable">{{
          resource.demandeBranchement.branchementEauPotable
        }}</label>
      </div>
    </div>
    <div class="space-x-3">
      <div class="checkbox">
        <p-checkbox [binary]="true" formControlName="branchementEauxUsees"></p-checkbox>
        <label for="branchementEauxUsees">{{
          resource.demandeBranchement.branchementEauxUsees
        }}</label>
      </div>
    </div>
  </div>
  <div class="mx-auto w-32">
    <label for="numeroDemandeTravO" class="label-input">{{
      resource.demandeBranchement.nDemandeTravO
    }}</label>
    <input
      type="text"
      class="app-input w-full"
      pInputText
      formControlName="numeroDemandeTravO"
      [maxlength]="20" />
  </div>
</div>
<div class="flex flex-1 items-center justify-end space-x-3 mx-6 px-6 mt-10">
  <button class="button-cancel flex mr-0">
    <img class="py-0.5 pr-1" [src]="resource.svgPath.close" alt="closeSVG" />{{
      resource.form.cancel
    }}
  </button>
  <div class="flex flex-col separator"></div>
  <button class="button-cancel flex" (click)="returnPreviousForm()">
    <img class="py-0.5 pr-1" [src]="resource.svgPath.west" alt="westSVG" />{{
      resource.form.previous
    }}
  </button>
  <button
    class="button-submit flex"
    (click)="submitForm()"
    [disabled]="!documentForm.valid || submitIsLoading">
    <fa-icon ng *ngIf="submitIsLoading" [icon]="faSpinner" [spin]="true" size="xs"></fa-icon>
    <fa-icon ng class="ml-1" [icon]="faCheck" size="xs"></fa-icon>{{ resource.form.validate }}
  </button>
</div>
