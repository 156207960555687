import { Action, createReducer, on } from '@ngrx/store';
import { init, updateHistoriqueCard, updateHistoriqueIndex } from './historique.actions';
import { initialState, HistoriqueState } from './historique.state';

const historiqueReducer = createReducer(
  initialState,
  on(updateHistoriqueCard, (state, { cards }: ReturnType<typeof updateHistoriqueCard>) => ({
    ...state,
    cardArray: cards
  })),
  on(updateHistoriqueIndex, (state, { index }: ReturnType<typeof updateHistoriqueIndex>) => ({
    ...state,
    indexTable: index
  })),
  on(init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: HistoriqueState | undefined, action: Action) {
  return historiqueReducer(state, action);
}
