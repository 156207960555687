import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericSearch, SearchData } from '../models/interface/GenericSearch';

@Injectable({
  providedIn: 'root'
})
export class DataSearchService {
  values!: SearchData[];
  value!: GenericSearch[];

  constructor(private http: HttpClient) {}

  getValues() {
    const promise = new Promise((resolve, reject) => {
      const url = '/data/searchDto.json';
      this.http
        .get<SearchData[]>(url, { responseType: 'json' })
        .subscribe((responseData: SearchData[]) => {
          this.values = responseData;
          resolve(true);
        });
    });
    return promise;
  }

  getValuesByKey(key: string) {
    let value = this.values.find(value => value.Key === key);
    if (value) {
      return value.Value;
    } else {
      return [];
    }
  }
}
