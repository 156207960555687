import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InformationsIdentiteResponse } from 'src/app/api/models/response/abonnement/InformationsIdentiteResponse';

@Injectable({
  providedIn: 'root'
})
export class AbonnementDataService {
  //#region Properties Input
  private _numAbonnement$ = new BehaviorSubject<string>('');
  numAbonnement$ = this._numAbonnement$.asObservable();

  private _numClient$ = new BehaviorSubject<string>('');
  numClient$ = this._numClient$.asObservable();

  private _numPointDesserte$ = new BehaviorSubject<string>('');
  numPointDesserte$ = this._numPointDesserte$.asObservable();

  private _numAppareil$ = new BehaviorSubject<string>('');
  numAppareil$ = this._numAppareil$.asObservable();

  private _informationsIdentiteVM = new BehaviorSubject<InformationsIdentiteResponse | null>(null);
  informationsIdentiteVM$ = this._informationsIdentiteVM.asObservable();

  private _isTeleReleve$ = new BehaviorSubject<boolean>(false);
  isTeleReleve$ = this._isTeleReleve$.asObservable();

  private _isRadioReleve$ = new BehaviorSubject<boolean>(false);
  isRadioReleve$ = this._isRadioReleve$.asObservable();

  private _refreshConsommations$ = new BehaviorSubject<boolean>(false);
  refreshConsommations$ = this._refreshConsommations$.asObservable();

  private _numIntervention$ = new BehaviorSubject<string>('');
  numIntervention$ = this._numIntervention$.asObservable();

  private _numModifIntervention$ = new BehaviorSubject<string>('');
  numModifIntervention$ = this._numModifIntervention$.asObservable();

  private _interMutation$ = new BehaviorSubject<boolean>(false);
  interMutation$ = this._interMutation$.asObservable();

  private _idTiersAbo$ = new BehaviorSubject<string>('');
  idTiersAbo$ = this._idTiersAbo$.asObservable();

  private _idTiersDest$ = new BehaviorSubject<string>('');
  idTiersDest$ = this._idTiersDest$.asObservable();

  private _interResiliation$ = new BehaviorSubject<boolean>(false);
  interResiliation$ = this._interResiliation$.asObservable();

  private _isShortcut$ = new BehaviorSubject<boolean>(false);
  isShortcut$ = this._isShortcut$.asObservable();

  private _clientRaisonSociale$ = new BehaviorSubject<string>('');
  clientRaisonSociale$ = this._clientRaisonSociale$.asObservable();

  private _identifiantTiers$ = new BehaviorSubject<string | null>(null);
  identifiantTiers$ = this._identifiantTiers$.asObservable();

  private _refreshMensualites$ = new BehaviorSubject<boolean>(false);
  refreshMensualites$ = this._refreshMensualites$.asObservable();

  private _refreshFPA$ = new BehaviorSubject<boolean>(false);
  refreshFPA$ = this._refreshFPA$.asObservable();

  private _refreshRemboursement$ = new BehaviorSubject<boolean>(false);
  refreshRemboursement$ = this._refreshRemboursement$.asObservable();

  private _refreshCommunications$ = new BehaviorSubject<boolean>(false);
  refreshCommunications$ = this._refreshCommunications$.asObservable();

  private _etatAbonnement$ = new BehaviorSubject<string>('');
  etatAbonnement$ = this._etatAbonnement$.asObservable();
  //#endregion

  //#region Properties Frame
  private _devisContratIsVisible$ = new BehaviorSubject<boolean>(false);
  devisContratIsVisible$ = this._devisContratIsVisible$.asObservable();

  private _devisCommuneIsVisible$ = new BehaviorSubject<boolean>(false);
  devisCommuneIsVisible$ = this._devisCommuneIsVisible$.asObservable();

  private _ajoutConsoIsVisible$ = new BehaviorSubject<boolean>(false);
  ajoutConsoIsVisible$ = this._ajoutConsoIsVisible$.asObservable();

  private _ajoutInterventionIsVisible$ = new BehaviorSubject<boolean>(false);
  ajoutInterventionIsVisible$ = this._ajoutInterventionIsVisible$.asObservable();

  private _ajoutInterventionResiliationIsVisible$ = new BehaviorSubject<boolean>(false);
  ajoutInterventionResiliationIsVisible$ =
    this._ajoutInterventionResiliationIsVisible$.asObservable();

  private _modifInterventionIsVisible$ = new BehaviorSubject<boolean>(false);
  modifInterventionIsVisible$ = this._modifInterventionIsVisible$.asObservable();

  private _consultInterventionIsVisible$ = new BehaviorSubject<boolean>(false);
  consultInterventionIsVisible$ = this._consultInterventionIsVisible$.asObservable();

  private _creerPlanMensualisationIsVisible$ = new BehaviorSubject<boolean>(false);
  creerPlanMensualisationIsVisible$ = this._creerPlanMensualisationIsVisible$.asObservable();

  private _creerRemboursementIsVisible$ = new BehaviorSubject<boolean>(false);
  creerRemboursementIsVisible$ = this._creerRemboursementIsVisible$.asObservable();

  private _creerEcheancierPaiementIsVisible$ = new BehaviorSubject<boolean>(false);
  creerEcheancierPaiementIsVisible$ = this._creerEcheancierPaiementIsVisible$.asObservable();

  private _creerFactureManuelleIsVisible$ = new BehaviorSubject<boolean>(false);
  creerFactureManuelleIsVisible$ = this._creerFactureManuelleIsVisible$.asObservable();

  private _creerAutreFactureManuelleIsVisible$ = new BehaviorSubject<boolean>(false);
  creerAutreFactureManuelleIsVisible$ = this._creerAutreFactureManuelleIsVisible$.asObservable();

  private _creerNouveauCourrierIsVisible$ = new BehaviorSubject<boolean>(false);
  creerNouveauCourrierIsVisible$ = this._creerNouveauCourrierIsVisible$.asObservable();

  private _creerNouveauCourrierATransfererIsVisible$ = new BehaviorSubject<boolean>(false);
  creerNouveauCourrierATransfererIsVisible$ =
    this._creerNouveauCourrierATransfererIsVisible$.asObservable();

  private _mutationIsVisible$ = new BehaviorSubject<boolean>(false);
  mutationIsVisible$ = this._mutationIsVisible$.asObservable();

  private _resilierAbonnementIsVisible$ = new BehaviorSubject<boolean>(false);
  resilierAbonnementIsVisible$ = this._resilierAbonnementIsVisible$.asObservable();

  private _miseEnServiceAbonnementIsVisible$ = new BehaviorSubject<boolean>(false);
  miseEnServiceAbonnementIsVisible$ = this._miseEnServiceAbonnementIsVisible$.asObservable();

  private _isVisibleAvoirPartiel$ = new BehaviorSubject<boolean>(false);
  isVisibleAvoirPartiel$ = this._isVisibleAvoirPartiel$.asObservable();

  private _isVisibleAvoirTotal$ = new BehaviorSubject<boolean>(false);
  isVisibleAvoirTotal$ = this._isVisibleAvoirTotal$.asObservable();

  private _tabIndex$ = new BehaviorSubject<number>(0);
  tabIndex$ = this._tabIndex$.asObservable();
  //#endregion

  constructor() {}

  setNumAbonnement(state: string) {
    this._numAbonnement$.next(state);
  }

  setNumClient(state: string) {
    this._numClient$.next(state);
  }

  setNumPointDesserte(state: string) {
    this._numPointDesserte$.next(state);
  }

  setNumAppareil(state: string) {
    this._numAppareil$.next(state);
  }

  setInformationsIdentiteVM(state: InformationsIdentiteResponse | null) {
    this._informationsIdentiteVM.next(state);
  }

  setIsTeleReleve(state: boolean) {
    this._isTeleReleve$.next(state);
  }

  setIsRadioReleve(state: boolean) {
    this._isRadioReleve$.next(state);
  }

  setRefreshConsommations(state: boolean) {
    this._refreshConsommations$.next(state);
  }

  setNumIntervention(state: string) {
    this._numIntervention$.next(state);
  }

  setNumModifIntervention(state: string) {
    this._numModifIntervention$.next(state);
  }

  setInterMutation(state: boolean) {
    this._interMutation$.next(state);
  }

  setIdTiersAbo(state: string) {
    this._idTiersAbo$.next(state);
  }

  setIdTiersDest(state: string) {
    this._idTiersDest$.next(state);
  }

  setInterResiliation(state: boolean) {
    this._interResiliation$.next(state);
  }

  setIsShortcut(state: boolean) {
    this._isShortcut$.next(state);
  }

  setClientRaisonSociale(state: string) {
    this._clientRaisonSociale$.next(state);
  }

  setIdentifiantTiers(state: string | null) {
    this._identifiantTiers$.next(state);
  }

  setRefreshMensualites(state: boolean) {
    this._refreshMensualites$.next(state);
  }

  setRefreshFPA(state: boolean) {
    this._refreshFPA$.next(state);
  }

  setRefreshRemboursement(state: boolean) {
    this._refreshRemboursement$.next(state);
  }

  setRefreshCommunications(state: boolean) {
    this._refreshCommunications$.next(state);
  }

  resetPanelsVisibility() {
    this._devisContratIsVisible$.next(false);
    this._devisCommuneIsVisible$.next(false);
    this._ajoutConsoIsVisible$.next(false);
    this._ajoutInterventionIsVisible$.next(false);
    this._ajoutInterventionResiliationIsVisible$.next(false);
    this._modifInterventionIsVisible$.next(false);
    this._consultInterventionIsVisible$.next(false);
    this._creerPlanMensualisationIsVisible$.next(false);
    this._creerRemboursementIsVisible$.next(false);
    this._creerEcheancierPaiementIsVisible$.next(false);
    this._creerFactureManuelleIsVisible$.next(false);
    this._creerAutreFactureManuelleIsVisible$.next(false);
    this._creerNouveauCourrierIsVisible$.next(false);
    this._creerNouveauCourrierATransfererIsVisible$.next(false);
    this._mutationIsVisible$.next(false);
    this._resilierAbonnementIsVisible$.next(false);
    this._miseEnServiceAbonnementIsVisible$.next(false);
    this._isVisibleAvoirPartiel$.next(false);
    this._isVisibleAvoirTotal$.next(false);
  }

  setShowBouncingFrame(
    frame:
      | 'devisContrat'
      | 'devisCommune'
      | 'ajoutConso'
      | 'ajoutIntervention'
      | 'ajoutInterventionResiliation'
      | 'modifIntervention'
      | 'consultIntervention'
      | 'creerPlanMensualisation'
      | 'creerRemboursement'
      | 'creerEcheancierPaiement'
      | 'creerFactureManuelle'
      | 'creerAutreFactureManuelle'
      | 'creerNouveauCourrier'
      | 'creerNouveauCourrierATransferer'
      | 'mutation'
      | 'resilierAbonnement'
      | 'miseEnServiceAbonnement'
      | 'AvoirPartiel'
      | 'AvoirTotal',
    state: boolean
  ) {
    switch (frame) {
      case 'devisContrat':
        this._devisContratIsVisible$.next(state);
        break;
      case 'devisCommune':
        this._devisCommuneIsVisible$.next(state);
        break;
      case 'ajoutConso':
        this._ajoutConsoIsVisible$.next(state);
        break;
      case 'ajoutIntervention':
        this._ajoutInterventionIsVisible$.next(state);
        break;
      case 'ajoutInterventionResiliation':
        this._ajoutInterventionResiliationIsVisible$.next(state);
        break;
      case 'modifIntervention':
        this._modifInterventionIsVisible$.next(state);
        break;
      case 'consultIntervention':
        this._consultInterventionIsVisible$.next(state);
        break;
      case 'creerPlanMensualisation':
        this._creerPlanMensualisationIsVisible$.next(state);
        break;
      case 'creerRemboursement':
        this._creerRemboursementIsVisible$.next(state);
        break;
      case 'creerEcheancierPaiement':
        this._creerEcheancierPaiementIsVisible$.next(state);
        break;
      case 'creerFactureManuelle':
        this._creerFactureManuelleIsVisible$.next(state);
        break;
      case 'creerAutreFactureManuelle':
        this._creerAutreFactureManuelleIsVisible$.next(state);
        break;
      case 'creerNouveauCourrier':
        this._creerNouveauCourrierIsVisible$.next(state);
        break;
      case 'creerNouveauCourrierATransferer':
        this._creerNouveauCourrierATransfererIsVisible$.next(state);
        break;
      case 'mutation':
        this._mutationIsVisible$.next(state);
        break;
      case 'resilierAbonnement':
        this._resilierAbonnementIsVisible$.next(state);
        break;
      case 'miseEnServiceAbonnement':
        this._miseEnServiceAbonnementIsVisible$.next(state);
        break;
      case 'AvoirPartiel':
        this._isVisibleAvoirPartiel$.next(state);
        break;
      case 'AvoirTotal':
        this._isVisibleAvoirTotal$.next(state);
        break;
      default:
        break;
    }

    document.getElementById('bouncingFrameAnchor')?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start'
    });
  }

  setTabIndex(state: number) {
    this._tabIndex$.next(state);
  }
}
