import { Abonnement } from 'src/app/core/models/abonnement.model';

export type AbonnementState = Abonnement;

export const initialState: AbonnementState = {
  numeroAbonnement: null,
  numeroClient: null,
  numeroPointDesserte: null,
  etatAbonnement: null,
  numeroAppareil: '',
  listInterventionsUpdate: false,
  listCourriersUpdate: false
};
