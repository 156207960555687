
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { AppConfig } from 'src/app/app.config';
import { WaterpHttpService } from 'src/app/shared/services/waterp-http.service';
import { ParametersRequest } from '../models/shared/parametersRequest';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class TiersService extends AbstractService {
  parametersRequest!: ParametersRequest;
  constructor(config: AppConfig, private waterpHttpService: WaterpHttpService) {
    super(config);
  }

  //#region /Tiers/Get: Retourne la liste des alertes supervision
  /**
   * @param idTiers
   */
  public get(idTiers: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Tiers/Get',
      parameters: [{ name: 'idTiers', value: idTiers }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion
}
