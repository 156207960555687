import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { WaterpHttpService } from 'src/app/shared/services/waterp-http.service';
import { Authorisations } from '../models/class/authorisation/authorisations';
import { Profil } from '../models/class/profil';
import { GeneriqueResponse } from '../models/shared/generiqueResponse';
import { ParametersRequest } from '../models/shared/parametersRequest';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class ProfilService extends AbstractService {
  parametersRequest!: ParametersRequest;
  constructor(config: AppConfig, private waterpHttpService: WaterpHttpService) {
    super(config);
  }

  //#region /Profil/GetProfils: Cette méthode permet de récupérer les profils
  /**
   * @param code Permet de spécifier la récupération sur un profil
   */
  public getProfils(code?: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Profil/GetProfils',
      parameters: []
    };

    const path = this.basePath + this.parametersRequest.url;
    if (code) {
      this.parametersRequest.parameters.push({ name: 'codeProfil', value: code });
    }

    return this.waterpHttpService.get(path, this.parametersRequest).pipe(
      map((response: GeneriqueResponse) => {
        if (response.Result) {
          response.Result = code
            ? new Profil(response.Result[0])
            : response.Result.map((profil: Profil) => new Profil(profil)).sort(
                (a: Profil, b: Profil) => a.getLibelle().localeCompare(b.getLibelle())
              );
        } else {
          response.Result = code ? new Profil() : new Array<Profil>();
        }

        return response;
      })
    );
  }
  //#endregion

  //#region /Profil/GetAuthorisations: Cette méthode permet de récupérer les authorisations liées aux profils
  /**
   * @param code Permet de récupérer les autorisations d'un profil spécifique
   * @returns
   */
  public getAuthorisations(code?: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Profil/GetAutorisationsProfil',
      parameters: []
    };

    const path = this.basePath + this.parametersRequest.url;
    if (code) {
      this.parametersRequest.parameters.push({ name: 'codeProfil', value: code });
    }

    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion
}
